import React, {Component} from 'react';
import {Col, Card, CardBody, CardHeader, CardTitle, Collapse} from 'reactstrap';
import UnfoldLessIcon from 'mdi-react/UnfoldLessHorizontalIcon';
import UnfoldMoreIcon from 'mdi-react/UnfoldMoreHorizontalIcon';
import OpengraphDataTable from './OpengraphDataTable';
import HybridDataTable from './HybridDataTable';
import HtmlInferredDataTable from './HtmlInferredDataTable';

export default class DebugResults extends Component {

  render(){
    console.log(this.props.results)
    return (
      <div>
        <OpengraphDataTable data={this.props.results.openGraph}/>
        <HybridDataTable data={this.props.results.hybridGraph}/>
        <HtmlInferredDataTable data={this.props.results.htmlInferred}/>
      </div>
    )
  }
}