import {
    CLEAR_DETAIL_PLAN,
    CLEAR_PLAN_ALERT,
    CREATE_PLAN,
    CREATE_PLAN_SUCCESS,
    CREATE_PLAN_FAIL,
    GET_PLAN_BY_API_AND_ORGANIZATION_FAIL,
    GET_PLAN_BY_API_AND_ORGANIZATION_SUCCESS, GET_PLANS_FOR_ORGANIZATION_FAIL,
    GET_PLANS_FOR_ORGANIZATION_SUCCESS, UPDATE_PLAN_FAIL,
    UPDATE_PLAN,
    UPDATE_PLAN_SUCCESS,
    UPDATE_STRIPE_SOURCE_FOR_PLAN_SUCCESS,
    UPDATE_STRIPE_SOURCE_FOR_PLAN_FAIL,
    CLEAR_STRIPE_ERROR, SET_STRIPE_ERROR,
} from '../actionTypes';


const initialState = {
    plans: [],
    detailPlan: {},
    alert: null,
    error: null,
    userPlans: [],
    userPlansLoading: true,
    stripeError: null,
    loading: false
};

const plans = (state = initialState, action) => {
    switch (action.type) {
        case GET_PLAN_BY_API_AND_ORGANIZATION_SUCCESS:
            return {...state, detailPlan: action.payload, error: null};
        case CREATE_PLAN:
            return {
                ...state,
                loading: true,
            }
        case CREATE_PLAN_SUCCESS:
            return {
                ...state,
                detailPlan: action.payload,
                alert: {message: 'Success! Create an API key to get started', color: 'success'},
                error: null,
                loading: false,
            };
        case CREATE_PLAN_FAIL:
            return {
                ...state,
                detailPlan: action.payload,
                alert: {message: 'Success! Create an API key to get started', color: 'success'},
                error: payload.error,
                loading: false
            };
        case GET_PLAN_BY_API_AND_ORGANIZATION_FAIL:
            return {...state, detailPlan: {}, alert: null, error: action.payload};
        case CLEAR_PLAN_ALERT:
            return {...state, alert: null};
        case CLEAR_DETAIL_PLAN:
            return {...state, detailPlan: {}, error: null};

        case UPDATE_STRIPE_SOURCE_FOR_PLAN_SUCCESS:
            return {
                ...state,
                detailPlan: action.payload,
                alert: {message: 'Plan Successfully updated', color: 'success'},
                error: null
            };
        case UPDATE_STRIPE_SOURCE_FOR_PLAN_FAIL:
            return {
                ...state,
                stripeError: action.payload
            }
        case SET_STRIPE_ERROR:
            return {
                ...state,
                stripeError: action.payload
            }
        case GET_PLANS_FOR_ORGANIZATION_SUCCESS:
            return {...state, userPlans: action.payload, userPlansLoading: false};
        case GET_PLANS_FOR_ORGANIZATION_FAIL:
            return {...state, userPlans: null, userPlansLoading: false};
        case UPDATE_PLAN:
            return {
                ...state,
                loading: true
            };
        case UPDATE_PLAN_SUCCESS:
            return {
                ...state,
                detailPlan: action.payload,
                stripeError: null,
                alert: {message: 'Plan Successfully updated', color: 'success'},
                error: null,
                loading: false,
            };
        case UPDATE_PLAN_FAIL:
            return {...state, stripeError: action.payload, loading: false}
        case CLEAR_STRIPE_ERROR:
            return {...state, stripeError: null}
        default:
            return state;

    }
};


export default plans;
