import {SEND_SUPPORT_EMAIL, SEND_SUPPORT_EMAIL_FAIL, SEND_SUPPORT_EMAIL_SUCCESS, START_INTERCOM_CONVERSATION_SUCCESS} from '../actionTypes';
import { apiClient } from '../../constants/clients';
import {createSegmentEvent} from '../segment';


export function sendSupportEmail (apiId, payload){
  return (dispatch, getState) => {
      dispatch({type: SEND_SUPPORT_EMAIL});
      const body = {
        data: payload,
        emailBody: getState().form.support_email_form.values.body
      };
      const user = getState().users.user
      let meta = {
          analytics: createSegmentEvent('track',
              {
                  event: 'Support Ticket Opened',
                  properties: {
                      email: user.email,
                      message: body.emailBody
                  },
                  options: { integrations: {Slack : true}}
              })
      }
      const intercomPayload = {
          user,
          message: body.emailBody
      }

      dispatch(startIntercomConversation(intercomPayload))

    apiClient.sendSupportEmail(apiId, getState().users.user.id, body)
          .exec((err, result) => {
          if(err){
            dispatch({type: SEND_SUPPORT_EMAIL_FAIL, payload: err})
          } else {
              dispatch({type: SEND_SUPPORT_EMAIL_SUCCESS, meta})
          }
        })
  }
}

export function startIntercomConversation (payload) {
  return (dispatch) => {
    apiClient.startIntercomConversation(payload)
      .exec((err, result) => {
        if(err){
          dispatch({type: START_INTERCOM_CONVERSATION_SUCCESS, payload: err})
        } else {
          dispatch({type: START_INTERCOM_CONVERSATION_SUCCESS, result})
        }
      })
  }
}