import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss';
import Router from '../routes';
import store from './store';
import ScrollToTop from './ScrollToTop';
import { TrackJS } from 'trackjs';
import InitializeGoogleAnalytics, {TrackGoogleAnalyticsEvent} from '../../shared/utils/google-analytics';
import { GrowthBook, GrowthBookProvider, FeaturesReady } from '@growthbook/growthbook-react';
import ReactGA4 from 'react-ga4';


const App = () => {
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(true);
  const [growthbook, setGrowthbook] = useState(null);

  useEffect(() => {

    if (process.env.NODE_ENV === 'production') {
      TrackJS.install({ token: '39388f3cb65d4d339b829a11a5e82d8e' });
    }

    // initialize Google Analytics
    InitializeGoogleAnalytics();

    window.addEventListener('load', () => {
      setLoading(false);
      setTimeout(() => setLoaded(true), 500);
    });

    return () => {
      window.removeEventListener('load', () => {
        setLoading(false);
        setTimeout(() => setLoaded(true), 500);
      });
    };
  }, []);



  useEffect(() => {
      const gb = new GrowthBook({
        apiHost: 'https://cdn.growthbook.io',
        // clientKey: GROWTHBOOK_KEY,
        clientKey: 'sdk-VN2huuKit4PKiq3',
        enableDevMode: true,
        subscribeToChanges: true,
        trackingCallback: (experiment, result) => {
          console.log({experiment, result});
          let additionalParams = {
            experiment_id: experiment.key,
            variation_id: result.key,
            event_category: 'GrowthBook',
          }

          ReactGA4.event('experiment_viewed', additionalParams);

          // TODO: Use your real analytics tracking system
          console.log('Viewed Experiment', {
            experimentId: experiment.key,
            variationId: result.key
          });
        }
      });

      setGrowthbook(gb)
      gb.loadFeatures();
    }, []);


  const makeCookieObj = () => {
    const cookies = document.cookie.split(';');
    const cookieObj = {};
    cookies.forEach((cookie) => {
      const [key, value] = cookie.split('=');
      cookieObj[key.trim()] = value;
    });
    return cookieObj;
  }

  const makeGrowthBookAttributes = () => {
    const cookieObj = makeCookieObj();
    const intercomIdKey = Object.keys(cookieObj).find((k)=> k.startsWith('intercom-id'));
    const intercomId = intercomIdKey ? cookieObj[intercomIdKey] : 'unknown';
    const intercomDeviceIdKey = Object.keys(cookieObj).find((k)=> k.startsWith('intercom-device-id'));
    const intercomDeviceId = intercomDeviceIdKey ? cookieObj[intercomDeviceIdKey] : 'unknown';


    return {
      id: intercomId,
      deviceId: intercomDeviceId
    }
  }

  useEffect(() => {
    if(growthbook){
      growthbook.loadFeatures();
      const attrs = makeGrowthBookAttributes();
      console.log({attrs});
      growthbook.setAttributes(attrs)
    }
  }, [growthbook]);

  if(!growthbook) return null;

  return (
      <Provider store={store}>
        <GrowthBookProvider growthbook={growthbook}>
          <FeaturesReady>
            <BrowserRouter>
              <ScrollToTop>
                <div>
                  {!loaded && (
                    <div className={`load${loading ? '' : ' loaded'}`}>
                      <div className="load__icon-wrap">
                        <svg className="load__icon">
                          <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                        </svg>
                      </div>
                    </div>
                  )}
                  <div>
                    <Router />
                  </div>
                </div>
              </ScrollToTop>
            </BrowserRouter>
          </FeaturesReady>
        </GrowthBookProvider>
      </Provider>
  );
};

export default hot(module)(App);
