import {
  CLEAR_WHITE_LABEL,
  GET_WHITE_LABEL_API_SUCCESS
} from '../actionTypes';

const initialState = {whiteLabelMode: false, whiteLabelApi: {}, loading: true};

const whiteLabel = (state = initialState, action) => {
  switch (action.type) {
    case GET_WHITE_LABEL_API_SUCCESS:
      return {...state, whiteLabelMode: true, whiteLabelApi: action.payload, loading: false};
    case CLEAR_WHITE_LABEL:
      return {...state, whiteLabelMode: false, whiteLabelApi: {}, loading: false};
    default:
      return state;
  }
};


export default whiteLabel;
