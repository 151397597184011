import React from 'react';
import {Tooltip} from 'reactstrap';
import {TfiTrash} from 'react-icons/tfi';
import {LiaUserEditSolid} from 'react-icons/lia';
import {AiOutlineCheck} from 'react-icons/ai';
import {HiMiniXMark} from 'react-icons/hi2';

const OrganizationUserActions = (props) => {

  const { index, onClick, showSelectMenu, handleEditClick,
    toggleEdit, toggleRemove, editTooltipOpenIndex, removeTooltipOpenIndex,
    saveEditTooltipOpenIndex, cancelEditTooltipOpenIndex, toggleSaveEdit, toggleCancelEdit,
    handleRemoveUserFromOrganization, handleUpdateUserRoleInOrganization } = props;

  const iconStyle = {
    margin: '5px',
  }

  return (

    <td>
      <span>
        {!showSelectMenu && (
          <>
            <Tooltip
              autohide={true}
              target={`edit-pencil-${index}`}
              isOpen={editTooltipOpenIndex === index}
              toggle={() => toggleEdit(index)}
            >
              Edit User Role
            </Tooltip>
            <LiaUserEditSolid style={iconStyle} size={20} onClick={handleEditClick} id={`edit-pencil-${index}`} />
          </>
          ) }
        { showSelectMenu &&
          <>
            <Tooltip
              autohide={true}
              target={`save-edit-${index}`}
              isOpen={saveEditTooltipOpenIndex === index}
              toggle={() => toggleSaveEdit(index)}
            >
              Save
            </Tooltip>
            <AiOutlineCheck onClick={handleUpdateUserRoleInOrganization} style={iconStyle} size={20} id={`save-edit-${index}`} />
            <Tooltip
              autohide={true}
              target={`cancel-edit-${index}`}
              isOpen={cancelEditTooltipOpenIndex === index}
              toggle={() => toggleCancelEdit(index)}
            >
              Cancel
            </Tooltip>
            <HiMiniXMark style={iconStyle} size={20} onClick={handleEditClick} id={`cancel-edit-${index}`} />
          </>
        }
        <Tooltip
          autohide={true}
          target={`trash-can-${index}`}
          isOpen={removeTooltipOpenIndex === index}
          toggle={() => toggleRemove(index)}
        >
          Remove User From Organization
        </Tooltip>
        <TfiTrash onClick={handleRemoveUserFromOrganization} style={iconStyle} size={20} id={`trash-can-${index}`}/>
      </span>
    </td>
  )

}

export default OrganizationUserActions