import React, { useState, useEffect } from 'react'
import { Alert } from 'reactstrap';

const TimeAlert = ({ endTime, message, toggleClose }) => {

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  });

  const calculateTimeLeft = () => {
    const difference = +new Date(endTime) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());


  const renderTimeSection = (time, label) => {
    return (
      <div className="flip-countdown-section">
        <div className="flip-countdown-number">
          {time < 10 ? `0${time}` : time}
        </div>
        <div className="flip-countdown-label">{label}</div>
      </div>
    );
  };

  return (
    <Alert color={'success'} className="alert--bordered" isOpen={true} toggle={toggleClose}>
      <div style={{ color: '#2CBD6B', display: 'grid', gridTemplateColumns: '50% 50%', alignItems: 'center', textAlign: 'right', columnGap: '20px'}}>
        <p style={{ color: '#2CBD6B',fontSize: '16px', fontWeight: 600}}>{message}</p>
        <div className="flip-countdown">
          {timeLeft.days ? renderTimeSection(timeLeft.days, 'days') : null}
          {timeLeft.hours ? renderTimeSection(timeLeft.hours, 'hours') : null}
          {timeLeft.minutes ? renderTimeSection(timeLeft.minutes, 'min') : null}
          {timeLeft.seconds ? renderTimeSection(timeLeft.seconds, 'sec') : null}
        </div>
      </div>
    </Alert>
  )
}

export default TimeAlert
