const validateLogin = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Email field shouldn’t be empty';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (!values.password) {
    errors.password = 'Password field shouldn’t be empty';
  }
  if (values.password !== values.passwordConfirm){
    errors.password = 'Passwords don\'t match'
  }

  return errors;
};

export default validateLogin;
