import React, {useEffect} from 'react'
import Select from 'react-select';
import { Button, Col, Form, FormGroup, Input, Label, Tooltip } from 'reactstrap';
import {
  calculateApiCreditCost,
  setLanguageValue,
  handleUrlChange,
  makeDebuggerApiCall,
  toggleAutoProxy,
  toggleForceCacheUpdate,
  toggleFullRender,
  toggleProxy, toggleHasAutoProxy
} from '../../../../redux/actions/debuggerForm';
import {connect} from 'react-redux';

const BaseApiCallForm = (props ) => {

  const {
    buttonTitle,
    defaultOptions,
    disabled,
    featuresAllowed,
    handleUrlChange,
    hideAutoProxy,
    hideCacheUpdate,
    hideFullRender,
    hideProxy,
    makeDebuggerApiCall,
    setLanguageValue,
    showUpgradeTooltip,
    tab,
    toggleAutoProxy,
    toggleForceCacheUpdate,
    toggleFullRender,
    toggleHasAutoProxy,
    toggleProxy
  } = props;

  const [ apiCreditCount, setApiCreditCount ] = React.useState(1);
  const [ buttonDisabled, setButtonDisabled ] = React.useState(true);
  const [ hasAutoProxy, setHasAutoProxy ] = React.useState(false);

  const [ premiumTooltipTab, setPremiumTooltipTab ] = React.useState(null);
  const [ superiorTooltipTab, setSuperiorTooltipTab ] = React.useState(null);
  const [ autoProxyTooltipTab, setAutoProxyTooltipTab ] = React.useState(null);

  const setPremiumTooltipOpen = (tab) => setPremiumTooltipTab(premiumTooltipTab === tab ? null : tab)
  const setSuperiorTooltipOpen = (tab) => setSuperiorTooltipTab(superiorTooltipTab === tab ? null : tab)
  const setAutoProxyTooltipOpen = (tab) => setAutoProxyTooltipTab(autoProxyTooltipTab === tab ? null : tab)

  useEffect(() => {
    if(tab === 'screenshot') {
      setApiCreditCount(20)
    } else {
      const cost = props.calculateApiCreditCost()
      setApiCreditCount(cost)
    }
  }, [tab, defaultOptions]);

  useEffect(() => {
    if(Array.isArray(featuresAllowed) && featuresAllowed.includes('autoProxy')) {
      setHasAutoProxy(true)
    }

    if(Array.isArray(featuresAllowed) && !featuresAllowed.includes('increaseRequest')) {
      toggleHasAutoProxy(false)
    }
  }, [featuresAllowed]);


  const disableOption = (option) => {
    console.log('featuresAllowed', featuresAllowed)
    if( Array.isArray(featuresAllowed) && !featuresAllowed.includes('increaseRequest') && (option === 'use_superior' || option === 'use_premium')) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    if(disabled || !defaultOptions.url) {
      setButtonDisabled(true)
    } else {
      setButtonDisabled(false)
    }
  }, [defaultOptions.url]);

  const renderAutoProxyOption = () => {
    if(hideAutoProxy) return null
    if(Array.isArray(featuresAllowed) && featuresAllowed.includes('increaseRequest') && !featuresAllowed.includes('autoProxy')) {
      return (
        <div className="form__form-group-field checkbox--label_container">
          <input
            id={`auto_proxy_${tab}`}
            className={'checkbox-btn checkbox-btn__checkbox-custom'}
            type="checkbox"
            onChange={toggleAutoProxy}
            checked={defaultOptions.autoProxy}
          />
          <p className={'checkbox-btn__label'}>Auto Proxy - Turn on automatic proxy handling for manual configuration.</p>
        </div>
      )
    } else if (hasAutoProxy) {
    return (
      <div className="form__form-group-field checkbox--label_container">
        <input
          id={`auto_proxy_${tab}`}
          disabled={Array.isArray(featuresAllowed) && !featuresAllowed.includes('increaseRequest')}
          className={'checkbox-btn checkbox-btn__checkbox-custom'}
          type="checkbox"
          onChange={toggleAutoProxy}
          checked={!defaultOptions.autoProxy}
        />
        <p className={'checkbox-btn__label'}> Disable Auto Proxy - Turn off automatic proxy handling for manual configuration. </p>
      </div>
    )
  } else {
    return (
      <div className="form__form-group-field checkbox--label_container">
        <input
          id={`auto_proxy_${tab}`}
          className={'checkbox-btn checkbox-btn__checkbox-custom'}
          disabled={true}
          type="checkbox"
          onChange={toggleAutoProxy}
          checked={defaultOptions.autoProxy}
        />
        <p className={'checkbox-btn__label'}>Auto Proxy - Turn on automatic proxy handling for manual configuration.</p>
      </div>
    )
    }
  }



  return (
    <>
      <Form onSubmit={(e) => makeDebuggerApiCall(e)}>
      <FormGroup className="form__form-group" >
        <div className="form__debugger-group">
          <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
            <input disabled={disabled}  value={defaultOptions.url} onChange={(e) => handleUrlChange(e.target.value)} placeholder={'http://somesite.com'} type={'text'}  className={`ogDebugUriInput ${disabled ? 'lightGreyBackground' : ''}`}/>
          </div>
          <Button disabled={buttonDisabled} type="submit" className={`btn btn-success form__debugger-group-btn ${!buttonDisabled ? 'whiteText' : 'darkGreyText'}`} onClick={(e) => makeDebuggerApiCall(e)}>{buttonTitle} |  <span style={{ color: !buttonDisabled ? '#D1F1DF' : '#1b1e21'}}>{apiCreditCount} Request</span></Button>
        </div>
        <h3 className="debugger-h3 ogTextColor largeFont">Options</h3>
      </FormGroup>
      </Form>
      <div className="form__form-group-input-wrap width25">
        <span className="form__form-group-label">Language</span>
        <Select
          name="language"
          value={defaultOptions.language}
          onChange={setLanguageValue}
          options={[
            { value: 'auto', label: 'Auto - use my browser setting' },
            { value: 'en', label: 'en' },
            { value: 'fr', label: 'fr' },
            { value: 'de', label: 'de' },
          ]}
          clearable={false}
          className="form__form-group-select"
        />
    </div>
      <br/>
      {hideFullRender ? null : <div className="form__form-group-field checkbox--label_container">
        <input className={'checkbox-btn checkbox-btn__checkbox-custom'} type="checkbox" checked={defaultOptions.fullRender.active} onChange={toggleFullRender}/>
        <p className={'checkbox-btn__label'}> Full Render - Execute JS on page before reading tags (Slower: ~5-10s). <span className={'debugger-credit--amount'}>10 Requests</span> </p>
      </div>}
      {hideCacheUpdate ? null : <div className="form__form-group-field checkbox--label_container">
        <input className={'checkbox-btn checkbox-btn__checkbox-custom'} type="checkbox"
               onChange={toggleForceCacheUpdate} checked={defaultOptions.forceCacheUpdate}/>
        <p className={'checkbox-btn__label'}> Force Cache Update - Always pull fresh content from target. </p>
      </div>}
      { renderAutoProxyOption() }
      {Array.isArray(featuresAllowed) && !featuresAllowed.includes('increaseRequest') && !hideAutoProxy ? <Tooltip
        target={`auto_proxy_${tab}`}
        isOpen={autoProxyTooltipTab === tab}
        toggle={() => setAutoProxyTooltipOpen(tab)}>
        The Auto Proxy is only available in newer plans. Upgrade now to unlock!
      </Tooltip> : null }
      <br/>
      { hideProxy ? null :
        <>
          <p>Proxy Settings</p>
          <FormGroup
            row
            tag="fieldset"
            onChange={(e) => toggleProxy(e.target.value)}
            defaultValue={defaultOptions.proxyChoice}
          >
          <Col>
            {defaultOptions.proxyOptions.map((choice, i) => {
              return (
                <FormGroup check key={i + 'choice.value'}>
                  <Input
                    name="proxy"
                    id={choice.value + '_' + tab}
                    type="radio"
                    value={choice.value}
                    checked={defaultOptions.proxyChoice === choice.value}
                    disabled={() => disableOption(choice.value)}
                  />
                  {' '}
                  <Label check>
                    <p>{choice.name} <span className={'debugger-credit--amount'}>{choice.cost} Requests</span></p>
                  </Label>
                </FormGroup>
              )
            })}
            {showUpgradeTooltip ? <Tooltip
              target={`use_premium_${tab}`}
              isOpen={premiumTooltipTab === tab}
              toggle={() => setPremiumTooltipOpen(tab)}>
              The Superior Proxy is only available in newer plans. Upgrade now to unlock!
            </Tooltip> : null }
            {showUpgradeTooltip ? <Tooltip
              target={`use_superior_${tab}`}
              isOpen={superiorTooltipTab === tab}
              toggle={() => setSuperiorTooltipOpen(tab)}>
              The Superior Proxy is only available in newer plans. Upgrade now to unlock!
            </Tooltip> : null}
            </Col>
          </FormGroup>
        </>
      }
    </>
  )
}

const mapStateToProps = (state) => ({
  defaultOptions: state.debuggerForm.defaultOptions
});

const mapDispatchToProps = (dispatch) => ({
  setLanguageValue: (payload) => dispatch(setLanguageValue(payload)),
  toggleProxy: (payload) => dispatch(toggleProxy(payload)),
  toggleFullRender: (payload) => dispatch(toggleFullRender(payload)),
  toggleForceCacheUpdate: () => dispatch(toggleForceCacheUpdate()),
  toggleAutoProxy: () => dispatch(toggleAutoProxy()),
  toggleHasAutoProxy: (payload) => dispatch(toggleHasAutoProxy(payload)),
  handleUrlChange: (payload) => dispatch(handleUrlChange(payload)),
  makeDebuggerApiCall: (payload) => dispatch(makeDebuggerApiCall(payload)),
  calculateApiCreditCost: () => dispatch(calculateApiCreditCost()),

})

export default connect(mapStateToProps, mapDispatchToProps)(BaseApiCallForm)
