
import {
    SHOW_STRIPE_PAYMENT_MODAL,
    REMOVE_STRIPE_PAYMENT_MODAL,
    TOGGLE_STRIPE_PAYMENT_MODAL,
    SHOW_STRIPE_FREE_PAYMENT_MODAL
} from '../actionTypes';
import {clearStripeError} from './plans';

export function showStripeModal(payload){
    return (dispatch) => {
        dispatch({type:  SHOW_STRIPE_PAYMENT_MODAL, payload})
    }
}

export function showStripeFreeModal(payload){
    return (dispatch) => {
        dispatch({type:  SHOW_STRIPE_FREE_PAYMENT_MODAL, payload})
    }
}

export function removeStripeModal() {
    return (dispatch) => {
        dispatch({type: REMOVE_STRIPE_PAYMENT_MODAL})
    }
}

export function toggleStripeModal(payload) {
    return(dispatch) => {
        dispatch({type: TOGGLE_STRIPE_PAYMENT_MODAL, payload})
        dispatch(clearStripeError())
    }
}