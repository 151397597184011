import React, { Component, useEffect } from 'react';
import {connect} from 'react-redux'
import { Col, Card, CardBody, Table, Button} from 'reactstrap';
import {getApiKeysForPlan, createApiKeyForPlan, resetApiKeyState} from '../../../redux/actions/apiKeys';
import TablePagination from '../../../shared/TablePagination';
import ApiKeyTableRow from './ApiKeyTableRow';


const ApiKeysTable = ({ apiKeys, plan, createApiKeyForPlan, getApiKeysForPlan, loading }) => {

  const [ currentPage, setCurrentPage ] = React.useState(0);
  const [ apiKeysCount, setApiKeysCount ] = React.useState(0);

  useEffect(() => {
    if(apiKeys.apiKeys.length === 0 && plan.id){
      getApiKeysForPlan({planId: plan.id, page: 0})
    }
  }, [plan.id]);

  useEffect(() => {
    if(apiKeys.apiKeys.length !== 0){
      setApiKeysCount(apiKeys.count);
    }
  }, [apiKeys.count]);


    const changePage = (page) => {
      setCurrentPage(page)
      getApiKeysForPlan({planId: plan.id, page})
    };

  const renderRows = (apiKeys, plan) => {
    if(loading) {
      return (
        <tr>
          <td colSpan={100}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <div className="spinner-border text-success centerText" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </td>
        </tr>
      )
    }
    if(!plan.id){
      return (
        <tr>
          <td>Please select a tier to continue...</td>
          <td/>
          <td/>
          <td/>
        </tr>
      )
    }
    if(apiKeys.apiKeys.length === 0){
      return (
        <tr style={ { height: '225px' }}>
          <td>Create an API key to get started!</td>
          <td/>
        </tr>
      )
    }
    return apiKeys.apiKeys.map((apiKey) => {
      return (
        <ApiKeyTableRow apiKey={apiKey} key={apiKey.id}/>
      )
    })
  };

  return (
    <CardBody style={{ borderRadius: '20px' }}>
      <Col md={12} lg={12} >
          <div className="card__title flexSpaceBetween">
            <h5 className="subhead"> Api Keys </h5>
            <span onClick={()=> createApiKeyForPlan({planId: plan.id, page: currentPage})}><Button disabled={!plan.id} color={'primary'} size={'sm'}>Create Api Key</Button></span>
          </div>
          <Table className="table--bordered" responsive striped>
            <thead>
            <tr>
              <th>Key</th>
              <th>Description</th>
              <th>Total Requests Made</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody style={ { height: '225px' }}>
              {renderRows(apiKeys, plan)}
            </tbody>
          </Table>
        { apiKeysCount === 0 ? null : <TablePagination changePage={changePage} count={apiKeysCount} itemsPerPage={5} currentPage={currentPage}/>}
      </Col>
    </CardBody>

  )
}

const mapStateToProps = (state) => ({
  apiKeys: state.apiKeys,
  plan: state.plans.detailPlan,
  loading: state.apiKeys.loading,
  error: state.apiKeys.error,
  count: state.apiKeys.count,
});

const mapDispatchToProps = (dispatch) => ({
  getApiKeysForPlan: (payload) => dispatch(getApiKeysForPlan(payload)),
  createApiKeyForPlan: (payload) => dispatch(createApiKeyForPlan(payload)),
  resetApiKeyState: () => dispatch(resetApiKeyState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiKeysTable)
