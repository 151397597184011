import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Col, Card, CardBody, Table, Button, Row, Container, Input} from 'reactstrap'
import {getApi, createApi, updateApi, deleteApi} from '../../../redux/actions/apis';


class Details extends Component {

	state = {
		api: this.props.adminDetailApi || {
			name: '',
			adminEmails: '',
			descriptions: '',
			subtitle: '',
			imageUrl: ''
		},
		editMode: false,
		apiLoaded: false
	};

	componentDidMount() {

		console.log('API KEY ID URI: ', this.props.match.params.apiId);
		this.props.getApi(this.props.match.params.apiId);
	}

	componentWillReceiveProps(nextProps, nextContext) {

		let api = nextProps.adminDetailApi || {};
		if(this.state.apiLoaded == false && api.id){
			this.setState({ apiLoaded: true, api: api });
		}
	}

	submitApi = () => {
		if(this.props.adminDetailApi.id) {
			this.props.updateApi(this.state.api);
		}
		else {
			this.props.createApi(this.state.api)
		}

		this.props.history.push('/admin/apis');
	};

	deleteApi = () => {
		if(this.props.adminDetailApi.id) {
			this.props.deleteApi(this.state.api);
		}
		else {
			// Do Nothing, It does not exist yet!
		}

		this.props.history.push('/admin/apis');
	};

	renderCard = (api) => {
		return (
			<Col md={12} xl={6} sm={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="subhead"> API Details &mdash; {api.name} </h5>
            </div>
            <div className="form form--horizontal">
              <div className="form__form-group">
                <span className="form__form-group-label">Name</span>
                <div className="form__form-group-field">
                  <input type="text" value={this.state.api.name} onChange={(e)=> this.setState({api: {...this.state.api, name: e.target.value }})}/>
                </div>
              </div>
	            <div className="form__form-group">
		            <span className="form__form-group-label">Admin EMails</span>
		            <div className="form__form-group-field">
			            <input type="text" value={this.state.api.adminEmails} onChange={(e)=> this.setState({api: {...this.state.api, adminEmails: e.target.value }})} placeholder="Use a comma (,) to add more than one."/>
		            </div>
	            </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Description</span>
                <div className="form__form-group-field">
                  <input type="text" value={this.state.api.description} onChange={(e)=> this.setState({api: {...this.state.api, description: e.target.value }})}/>
                </div>
              </div>
	            <div className="form__form-group">
		            <span className="form__form-group-label">Subtitle</span>
		            <div className="form__form-group-field">
			            <input type="text" value={this.state.api.subtitle} onChange={(e)=> this.setState({api: {...this.state.api, subtitle: e.target.value }})}/>
		            </div>
	            </div>
	            <div className="form__form-group">
		            <span className="form__form-group-label">Image URL</span>
		            <div className="form__form-group-field">
			            <input type="text" value={this.state.api.imageUrl} onChange={(e)=> this.setState({api: {...this.state.api, imageUrl: e.target.value }})}/>
		            </div>
	            </div>
	            <span onClick={()=> this.submitApi()}><Button color={'primary'} size={'sm'}>Submit</Button></span>
	            &nbsp;&nbsp;&nbsp;
	            <span onClick={()=> this.deleteApi()}><Button color={'danger'} disabled={(!this.props.adminDetailApi.id)} size={'sm'}>Delete</Button></span>
            </div>
          </CardBody>
        </Card>
      </Col>
		)
	};

	renderImage = (api) => {
		return (
			<Col md={12} xl={6} sm={12}>
				<Card>
					<CardBody>
						<div style={{height: '256px', width: '256px', overflow: 'hidden', margin: '0px auto'}}>
							<img style={{minWidth: '100%'}} src={api.imageUrl} alt={api.name}/>
						</div>
					</CardBody>
				</Card>
			</Col>
		)
	};
	
	render(){
		const api = this.props.adminDetailApi || {};

		if(typeof api.id === 'undefined'){
			this.state.editMode = true;
		}

		return (
			<Container className="dashboard">
				<div>
					<Row>
						{this.renderCard(api)}
						{this.renderImage(api)}
					</Row>
				</div>
			</Container>
		)
	}

}

const mapStateToProps = (state) => ({
	adminDetailApi: state.apis.adminDetailApi
});

const mapDispatchToProps = (dispatch) => ({
	createApi: (payload) => dispatch(createApi(payload)),
	updateApi: (payload) => dispatch(updateApi(payload)),
	deleteApi: (payload) => dispatch(deleteApi(payload)),
	getApi: (payload) => dispatch(getApi(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Details)