import React, {Component} from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';

//takes props
// visible
// toggle
// title
// message
// action
// buttonText
// buttonColor

export default class FreeTierModal extends Component {

  render(){
    return (
      <Modal isOpen={this.props.visible} toggle={this.props.toggle} className={'modal-dialog--primary paymentModal '}>
        <div className={'modal__header centerText bottomBorder'}>
          <button className="lnr lnr-cross modal__close-btn" onClick={this.props.toggle} />
          <h4 className={'bold-text modal__title'}>
            {this.props.title}
          </h4>
          <br />
        </div>
        <ModalBody>
          <div className={'modal__body leftText borderRadius5'}>
            {this.props.message}
            <br />
            <br />
            <Button block color={this.props.buttonColor} onClick={this.props.action} >{this.props.buttonText}</Button>
          </div>
        </ModalBody>
      </Modal>
    )
  }
}