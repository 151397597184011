import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { adminEmails } from '../../constants/config';
import { withRouter } from 'react-router-dom';

const AdminWrapper = ({ children, user, userLoading, history }) => {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (!userLoading) {
      if (user && adminEmails.includes(user.email)) {
        setIsAdmin(true);
      } else {
        // If user data is loaded and they're not an admin, redirect
        const redirectPath = !user ? '/login' : '/';
        history.push(redirectPath);
      }
    }
  }, [user, userLoading, history]);

  // Conditionally render children or null based on isAdmin flag
  if (userLoading || !isAdmin) {
    return null; // Or render a loading spinner, etc.
  }

  return (
    <div>
      <div className="wrapper">
        {children}
      </div>
    </div>
  );
};

AdminWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  user: PropTypes.object,
  userLoading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  user: state.users.user,
  userLoading: state.users.loading,
});

export default withRouter(connect(mapStateToProps)(AdminWrapper));
