import React, { useEffect } from 'react';
import { Button, CardBody, Col, Input, InputGroup, InputGroupText, Row } from 'reactstrap';

import './YourApiKeyStyle.css';
import { PiCopySimple } from 'react-icons/pi';


const YourApiKey = ({ planApiKey, toggleTabChange }) => {

  const [ copied, setCopied ] = React.useState(false);

  const onCopyApiKeyClick = async (key) => {
    try {
      setCopied(true)
      await navigator.clipboard.writeText(key);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 2000);
    }
  }, [copied]);

  return (
      <CardBody style={{ borderRadius: '20px' }}>
        <Col>
          <h3 style={{ fontWeight: 700 }} >Your API Key</h3>
        </Col>
        <Col>
          <div className={'your-api-key_key-display'}>
            <p>{planApiKey}</p>
            <span className={'your-api-key_key-display_copy'}>
              <div className={'copy-text'}>
                <p>{copied ? '👍' : 'Copy'}</p>
              </div>
              <PiCopySimple className={'your-api-key_key-display_copy-icon'} onClick={() => onCopyApiKeyClick(planApiKey)} size={24}/>
            </span>
          </div>
        </Col>
        <Col>
          <Button onClick={() => toggleTabChange('api-keys')} className="btn btn-outline-primary" >Manage Keys</Button>
        </Col>
      </CardBody>
  );
};

export default YourApiKey;
