import React, {Component} from 'react';
import {Col, Card, CardBody, CardImg} from 'reactstrap';
import {Link} from 'react-router-dom';



export default class ApiCard extends Component {


  render(){
    return (
      <Col md={4}>
        <Card>
          <CardBody>
            <div className="card__title">
              <Link to={`/apis/${this.props.api.uriFriendlyName}`}>
                <h5 className="bold-text">{this.props.api.name}</h5>
              </Link>
              <h5 className="subhead">{this.props.api.subtitle}</h5>
            </div>
            <CardImg bottom width={250} height={250} src={this.props.api.imageUrl} alt="Api image" />
            <p>{this.props.api.description}</p>
          </CardBody>
        </Card>
      </Col>
    )
  }
}

