import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import MailRuIcon from 'mdi-react/MailRuIcon';
import { Button } from 'reactstrap';
import validatePassword from './validatePassword';
import {connect} from 'react-redux';
import {loginUser, requestPasswordResetEmail } from '../../../redux/actions/users';

const renderField = ({
                       input, placeholder, type, meta: { touched, error },
                     }) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

class LogInForm extends PureComponent {

  constructor() {
    super();
    this.state = {
      showPassword: false,
    };
  }

  requestPasswordResetClicked = (e) => {
    e.preventDefault();
    if (this.props.formState.syncErrors || !this.props.formData.values){
      console.log('not sending', this.props);
    } else {
      console.log('sending')
      this.props.requestPasswordResetEmail()
    }
  };

  render() {
    const { handleSubmit } = this.props;

    return (
      <form className="form">
        <div className="form__form-group">
          <span className="form__form-group-label">Email</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <MailRuIcon />
            </div>
            <Field
              name="email"
              component={renderField}
              type="text"
              placeholder="Email"
            />
          </div>
        </div>
        <Button type={'submit'} className="btn btn-primary account__btn account__btn--small whiteText" onClick={(e) => this.requestPasswordResetClicked(e)}>Request Password Reset</Button>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  formState: state.form.log_in_form
});

const mapDispatchToProps = (dispatch) => ({
  loginUser: (payload) => dispatch(loginUser(payload)),
  requestPasswordResetEmail: ()=> dispatch(requestPasswordResetEmail())
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'log_in_form',
  validate: validatePassword
})(LogInForm));
