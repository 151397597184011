import React, {Component} from 'react';
import {Card, CardBody, Col, Table} from 'reactstrap';
import {getUserInvoices} from '../../../redux/actions/users';
import {connect} from 'react-redux';
import moment from 'moment';

class InvoicesTable extends Component {
  state = {
    limit: 5,
    offset: 5,
  };

  componentDidMount() {
    this.props.getUserInvoices()
  }

  renderRows = (invoices) => {
    invoices = invoices.sort((a,b) => a.date - b.date).slice(0,10);
    return invoices.map((invoice, index) => {
      return (
        <tr key={`invoiceRow-${invoice.id}-${index}`}>
          <td>{moment(new Date(invoice.date * 1000)).format('MMM Do YYYY')}</td>
          <td>{`$${(invoice.amount_due / 100).toFixed(2)}`}</td>
          <td>{invoice.status}</td>
          <td><a href={invoice.hosted_invoice_url} target="_blank">Invoice</a></td>
          <td><a href={invoice.invoice_pdf}>Download</a></td>
        </tr>
      )
    })
  };

  render(){
    const invoices = this.props.invoices || [];
    return (
      <Col md={12} lg={12} >
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="subhead"> Invoices </h5>
            </div>
            <Table className="table--bordered" responsive striped>
              <thead>
              <tr>
                <th>Date</th>
                <th>Amount</th>
                <th>Status</th>
                <th>View Online</th>
                <th>PDF</th>
              </tr>
              </thead>
              <tbody>
              {this.renderRows(invoices)}
              </tbody>
            </Table>
            {/*<TablePagination changePage={this.changePage} count={count} itemsPerPage={5} currentPage={this.state.currentPage}/>*/}
          </CardBody>
        </Card>
      </Col>
    )
  }
}

const mapStateToProps = (state) => ({
  invoices: state.users.invoices
});

const mapDispatchToProps = (dispatch) => ({
  getUserInvoices: () => dispatch(getUserInvoices())
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesTable)
