import {
    REMOVE_STRIPE_PAYMENT_MODAL, SHOW_STRIPE_FREE_PAYMENT_MODAL,
    SHOW_STRIPE_PAYMENT_MODAL,
    TOGGLE_STRIPE_PAYMENT_MODAL
} from '../actionTypes'


const initialState = {
    loading: false,
    visible: false,
    freeModalVisible: false,
    tier: {
        name: '',
        priceMonthly: ''
    }
};

const stripeModal = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_STRIPE_PAYMENT_MODAL:
            return {...state, visible: true, tier: action.payload}
        case SHOW_STRIPE_FREE_PAYMENT_MODAL:
            return {...state, freeModalVisible: true, tier: action.payload}
        case REMOVE_STRIPE_PAYMENT_MODAL:
            return {...state, visible: false, freeModalVisible: false}
        case TOGGLE_STRIPE_PAYMENT_MODAL:
            return {...state, visible: action.payload, freeModalVisible: action.payload}
        default:
            return state;
    }
};


export default stripeModal