import {
  CREATE_API_KEY_SUCCESS,
  DELETE_API_KEY,
  DELETE_API_KEY_FAIL,
  DELETE_API_KEY_SUCCESS,
  EDIT_API_KEY_SUCCESS,
  GET_API_KEYS_FOR_PLAN,
  GET_API_KEYS_FOR_PLAN_FAIL,
  GET_API_KEYS_FOR_PLAN_SUCCESS,
  RESET_API_KEY_STATE
} from '../actionTypes';



const initialState = {apiKeys: [], count: 0, alert: null, error: null, loading: true};

const apiKeys = (state = initialState, action) => {
  let newApiKeys;
  switch (action.type) {
    case GET_API_KEYS_FOR_PLAN:
      return {...state, loading: true, error: null};
    case GET_API_KEYS_FOR_PLAN_SUCCESS:
      return {...state, apiKeys: action.payload.apiKeys, count: action.payload.count, loading: false, error: null};
    case GET_API_KEYS_FOR_PLAN_FAIL:
      return {...state, apiKeys: [], error: {message: 'Error'}, loading: false};
    case EDIT_API_KEY_SUCCESS:
      newApiKeys = [...state.apiKeys].map((apiKey) => apiKey.id === action.payload.id ? action.payload : apiKey);
      return {...state, apiKeys: newApiKeys};
    case DELETE_API_KEY:
      return {...state, loading: true, error: null};
    case DELETE_API_KEY_SUCCESS:
      return { ...state, loading: false, error : null };
    case DELETE_API_KEY_FAIL:
      return { ...state, loading: false, error: action.payload };
    case RESET_API_KEY_STATE:
      return initialState;
    default:
      return state;
  }
};


export default apiKeys;
