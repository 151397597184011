import React, {Component} from 'react';
import { Card, CardBody, Col, Button } from 'reactstrap';
import {connect} from 'react-redux';
import {createPlan} from '../../../redux/actions/plans';
import check from '../../../shared/img/check.svg'
import mark from '../../../shared/img/mark.svg';

class TierCardCurrent extends Component {
  mapDescriptionToBullets = (tier) => {
    let description = tier.description || '';
    return description.split('|').map((bullet) => {
      if(bullet.startsWith('x')){
        const bulletPoint = bullet.replace('x', '')
        return (
            <tr>
              <td className='pricing-card__x'><img src={mark}/></td>
              <td className="pricing-card__non_feature" key={bulletPoint}>{bulletPoint}</td>
            </tr>
        )
      }
      return (
          <tr>
            <td className='pricing-card__check'><img src={check}/></td>
            <td className="pricing-card__feature" key={bullet}>{bullet}</td>
          </tr>
      )
    })
  }

  signUpForTier = (tier) => {
    let payload = {
      tierId: tier.id,
      apiId: tier.apiId,
      startDate: new Date()
    }
    this.props.createPlan(payload)
  }

  render() {
    const tier = this.props.tier || {};

    return (
      <Col md={6} xl={3} sm={12}>
        <Card>
          <CardBody className="pricing-card__body">
            <div className='pricing-card__header'>
              <p className='pricing-card--emoji' dangerouslySetInnerHTML={{__html: `${this.props.tier.emoji}`}} />
              <h3 className="current-billing--info pricing-card__plan">{tier.name}</h3>
              <p className="pricing-card__price">${tier.priceMonthly}<span> monthly</span></p>
            </div>
            <table className='pricing-card-details__table' style={{margin: 'auto'}}>
              {this.mapDescriptionToBullets(tier)}
            </table>
            <div className={'pricing-card__button_container'}>
              <Button id={tier.name} className="pricing-card__button darkGreyBackground" color="primary" disabled={true}>Current Plan</Button>
            </div>
          </CardBody>
        </Card>
      </Col>
      )
  }
}

const mapDispatchToProps = (dispatch) => ({
  createPlan: (payload) => dispatch(createPlan(payload))
});

export default connect(null, mapDispatchToProps)(TierCardCurrent)