import React, { useEffect } from 'react';
import { Alert, Col, Container, Row } from 'reactstrap';
import {connect} from 'react-redux'
import {clearDetailApi, getApiByFriendlyName} from '../../redux/actions/apis'
import {clearDetailPlan, clearPlanAlert, getPlanByApiAndOrganization} from '../../redux/actions/plans'
import { getOrganizationStripeInfo } from '../../redux/actions/organization';
import {clearUserAlert} from '../../redux/actions/users'
import FreeTierModal from './components/FreeTierModal'
import StripeSignupOrSwitchModal from './components/StripeSignupOrSwitchModal'
import TimeAlert from '../../shared/components/TimeAlert';
import Tabs from './components/Tabs/Tabs';
import ApiDetailsTabContent from './components/Tabs/ApiDetailsTabContent';

function ApiDetails(props) {
  const {currentOrg} = props;

  const [ alertTime, setAlertTime ] = React.useState(null);
  const [ activeTab, setActiveTab ] = React.useState('dashboard');
  const [ planApiKey, setPlanApiKey ] = React.useState('XXXXXXXXX');

  useEffect(() => {
    props.getOrganizationStripeInfo(currentOrg.id);
  }, [currentOrg.id]);

  useEffect(() => {
    if ( props.plan && Array.isArray(props.plan.apiKeys) && props.plan.apiKeys[0].key ) {
      setPlanApiKey(props.plan.apiKeys[0].key);
    }
  }, [props.plan]);

  useEffect(() => {

    if (!props.api || props.api.uriFriendlyName !== props.match.params.name) {
      if (!props.apiError) {
        props.getApiByFriendlyName(props.match.params.name);
      }
    }

  }, [props.api]);

  useEffect(() => {
    if (!props.user && !props.userLoading) {
      props.history.push('/login');
    }

    if (props.user && props.api.id && !props.plan.id && !props.planError) {
      props.getPlanByApiAndOrganization();
    }

  }, [props.api, props.user, props.plan.id, props.planError, props.apiError]);

  useEffect(() => {
    if (props.currentOrg.stripeSubscription.data && props.currentOrg.tier.data.isTrial) {
      const trialEndDate = new Date(props.currentOrg.stripeSubscription.data.trial_end * 1000);

      const showTrialAlert = new Date() < trialEndDate;
      showTrialAlert && setAlertTime(trialEndDate);
    }
  }, [props.currentOrg.stripeSubscription.data]);


  const handleAlertTimeClose = () => {
    setAlertTime(null);
  }

  const toggleTab = (tab) => {
    setActiveTab(tab)
  }

  const renderTimedAlert = () => {
    const now = new Date();
    if (alertTime && now < alertTime) {
      return (
        <TimeAlert message={'You are currently on a free trial. It will expire in'} endTime={alertTime}
                   toggleClose={handleAlertTimeClose}/>
      );
    } else  {
      return null;
    }
  }

  const planAlert = props.planAlert || {};
  const userAlert = props.userAlert || {};

  return (
    <>
      {renderTimedAlert()}
      <Container style={{maxWidth: '1320px'}}  className="dashboard">
        <Row>
          <Col md={12}>
            <Alert color={planAlert.color} className="alert--bordered" isOpen={!!planAlert.message} toggle={props.clearPlanAlert} >
              <div className={'padding5'}>
                <span>{planAlert.message}</span>
              </div>
            </Alert>
            <Alert color={userAlert.color} className="alert--bordered" isOpen={!!userAlert.message} toggle={props.clearUserAlert}>
              <div className={'padding5'}>
                <span>{userAlert.message}</span>
              </div>
            </Alert>
            <StripeSignupOrSwitchModal />
            <FreeTierModal />
          </Col>
        </Row>
        {props.user && props.user.firstName && (<h3 className='welcome-user'>Welcome, {props.user.firstName} &#127881;</h3>)}
        <Tabs active={activeTab} onTabChange={setActiveTab}/>
        <ApiDetailsTabContent activeTab={activeTab} toggleTabChange={toggleTab} planApiKey={planApiKey} currentOrg={props.currentOrg} plan={props.plan}/>
      </Container>
    </>
  )
}

const mapStateToProps = (state) => ({
  api: state.apis.detailApi,
  user: state.users.user,
  currentOrg: state.organization.currentOrg,
  plan: state.plans.detailPlan,
  planAlert: state.plans.alert,
  planError: state.plans.error,
  apiError: state.apis.error,
  userAlert: state.users.alert
});

const mapDispatchToProps = (dispatch) => ({
getApiByFriendlyName: (payload) => dispatch(getApiByFriendlyName(payload)),
getPlanByApiAndOrganization: (() => dispatch(getPlanByApiAndOrganization())),
clearPlanAlert: () => dispatch(clearPlanAlert()),
clearUserAlert: () => dispatch(clearUserAlert()),
clearDetailPlan: () => dispatch(clearDetailPlan()),
clearDetailApi: () => dispatch(clearDetailApi()),
getOrganizationStripeInfo: () => dispatch(getOrganizationStripeInfo())
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiDetails)
