import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import EyeIcon from 'mdi-react/EyeIcon';
import {connect} from 'react-redux';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import MailRuIcon from 'mdi-react/MailRuIcon';
import { Button } from 'reactstrap';
import validateLogin from './validateRegistration';
import {signUpUser} from '../../../redux/actions/users';
import ReactGA from 'react-ga4';
import { TrackGoogleAnalyticsEvent } from '../../../shared/utils/google-analytics';

const renderField = ({
                       input, placeholder, type, meta: { touched, error },
                     }) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

class RegistrationForm extends PureComponent {

  constructor() {
    super();
    this.state = {
      showPassword: false,
    };
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  signUpClicked = (e) => {
    e.preventDefault()
    // check for errors then sign up
    if(this.props.formState.syncErrors){

    } else {
      this.props.flipForm()
      if(this.props.invitationId){
        this.props.signUpUser(this.props.invitationId, this.props.location || '')
      } else {
        this.props.signUpUser('', this.props.location || '')
      }
    }
  };


  render() {
    const { handleSubmit } = this.props;
    return (
      <form className="form">
        <div className="form__form-group">
          <span className="form__form-group-label">Email</span>
          <div className="form__form-group-field">
            {/*<div className="form__form-group-icon">*/}
            {/*  <MailRuIcon />*/}
            {/*</div>*/}
            <Field
              name="email"
              component={renderField}
              type="text"
              placeholder="Email"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Password</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="password"
              component={renderField}
              type={this.state.showPassword ? 'text' : 'password'}
              placeholder="Password"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Confirm Password</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="passwordConfirm"
              component={renderField}
              type={this.state.showPassword ? 'text' : 'password'}
              placeholder="Password Confirmation"
            />
          </div>
        </div>
        <Button type="submit" className="btn btn-primary account__btn account__btn--small whiteText" onClick={this.signUpClicked}>Sign Up</Button>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  formState: state.form.registration_form
});

const mapDispatchToProps = (dispatch) => ({
  signUpUser: (payload, location) => dispatch(signUpUser(payload, location))
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'registration_form',
  validate: validateLogin
})(RegistrationForm));
