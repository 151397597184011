import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getAllOrganizations, setSpyOrg} from '../../../redux/actions/admin';
import TablePagination from '../../../shared/TablePagination';
import { Col, Container, Row, Card, CardBody, Table, Badge, Button} from 'reactstrap';
import OrganizationJsonModal from './OrganizationJsonModal';
import {Link} from 'react-router-dom';
import moment from 'moment';


class AllOrganizationsTable extends Component {

  state = {
    currentPage: 0,
    modalVisible: false,
    modalMessage: '',
    modalTitle: '',
    apiKey: '',
    orgName: '',
    onlyPaidUsers: false,
  };

  componentDidMount() {
    this.props.getAllOrganizations({
      page: this.state.currentPage,
      orgName: this.state.orgName,
      apiKey: this.state.apiKey,
      onlyPaidUsers: this.state.onlyPaidUsers
    })
  }

  changePage = (page) => {
    this.setState({currentPage: page}, () => {
      this.props.getAllOrganizations({
        page: this.state.currentPage,
        orgName: this.state.orgName,
        apiKey: this.state.apiKey,
        onlyPaidUsers: this.state.onlyPaidUsers
      })
    })
  };

  openModal = (modalTitle, modalMessage ) => {
    this.setState({modalTitle, modalMessage}, () => {
      this.toggleModal();
    })
  };

  toggleModal = () => {
    this.setState({modalVisible: !this.state.modalVisible})
  };

  renderRows = (orgs) => {
    return orgs.map((org) => {
      let user = org.users[0];

      if(!user){
        user = {isVerified: false}
      }
      return (
        <tr key={org.id}>
          <td>{org.name}</td>
          <td>{moment(org.createdAt).format('MMM Do YYYY')}</td>
          <td>{user.isVerified ? 'Yes' : 'No'}</td>
          <td><span className={'clickable blueText'} onClick={() =>this.openModal('Plans', org.plans)}>Plans</span></td>
          <td><Link to={`/admin/users/${org.id}/dashboard`} onClick={()=>this.props.setSpyOrg(org)}>dashboard</Link></td>
        </tr>
      )
    })
  };

  orgNameChanged = (e) => {
    this.setState({orgName: e.target.value}, () => {
      this.props.getAllOrganizations({
        page: this.state.currentPage,
        orgName: this.state.orgName,
        apiKey: this.state.apiKey,
        onlyPaidUsers: this.state.onlyPaidUsers
      })
    })
  };

  apiKeyChanged = (e) => {
    this.setState({apiKey: e.target.value}, () => {
      this.props.getAllOrganizations({
        page: this.state.currentPage,
        orgName: this.state.orgName,
        apiKey: this.state.apiKey,
        onlyPaidUsers: this.state.onlyPaidUsers
      })
    })
  };

  render(){
    const organizations = this.props.admin.organizations || [];
    return (
      <Col md={12} lg={12} >
        <Card>
          <OrganizationJsonModal message={this.state.modalMessage} visible={this.state.modalVisible} title={this.state.modalTitle} toggle={this.toggleModal}/>
          <CardBody>
            <button onClick={() => thisMethodDoesNotExist()} />
            <div className="card__title">
              <h5 className="subhead"> All Organizations (users) </h5>
            </div>
            <div className={'adminOrgTableControls'}>
              <div className="form__form-group-field flexSpaceBetween width100">
                <span>Api Key</span>
                <input value={this.state.apiKey} onChange={this.apiKeyChanged} placeholder={'Find By Api key'} type={'text'} />
              </div>
              <div className="form__form-group-field flexSpaceBetween width100">
                <span>Org Name</span>
                <input value={this.state.orgName} onChange={this.orgNameChanged} placeholder={'Find By Org Name'} type={'text'} />
              </div>
              <div className="form__form-group-field width100 flexVertCenter">
                <input type="checkbox" onChange={this.toggleForceCacheUpdate} checked={this.state.forceCacheUpdate} />
                <span className={'checkbox-btn__label'}> Only Paid Users </span>
              </div>
            </div>
            <Table className="table--bordered" responsive striped>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Created At</th>
                  <th>Verified Email?</th>
                  <th>Plans</th>
                  <th>Jumper</th>
                </tr>
              </thead>
              <tbody>
                {this.renderRows(organizations)}
              </tbody>
            </Table>
            {!!this.props.count &&
              <TablePagination changePage={this.changePage} count={this.props.count} itemsPerPage={15} currentPage={this.state.currentPage}/>
            }
          </CardBody>
        </Card>
      </Col>
    )
  }
}

const mapStateToProps = (state) => ({
  admin: state.admin,
  count: state.admin.totalOrganizations
});

const mapDispatchToProps = (dispatch) => ({
  getAllOrganizations: (payload) => dispatch(getAllOrganizations(payload)),
  setSpyOrg: (payload) => dispatch(setSpyOrg(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllOrganizationsTable)

