import React from 'react';
import './ProgressBarStyle.css';

const ProgressBar = ({ completedPercentage, value }) => {
  const containerStyles = {
    backgroundColor: '#D1F1DF',
    borderRadius: 50,
    marginBottom: '10px',
  };

  const completedPercentageWidth = () => {
    if (completedPercentage >= 100) {
      return 100
    } else if (completedPercentage <= 10) {
      return 10
    } else {
      return completedPercentage
    }
  };

  const fillerStyles = {
    height: '100%',
    width: `${completedPercentageWidth()}%`,
    backgroundColor: '#2CBD6B',
    borderRadius: 'inherit',
    textAlign: 'right',
    transition: 'width 1s ease-in-out'
  };

  const labelStyles = {
    padding: 5,
    color: 'white',
    fontWeight: 'bold'
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles} className={'filler-bar'}>
        <span style={labelStyles}>{`${value}`}</span>
      </div>
    </div>
  );
};

export default ProgressBar;
