import React from 'react';

const getRoleOptions = ({userRole}) => {
  let options = [];

  if (userRole === 'OWNER') {
    options = ['OWNER', 'DEVELOPER'];
  } else if (userRole === 'DEVELOPER') {
    options = [ 'DEVELOPER' ];
  }

  return [
    ...options.map((role, index) => <option value={role} key={index}>{role}</option>)
  ];
};

export default getRoleOptions;