import React, {Component} from 'react';
import ReactJson  from 'react-json-view';
import {Card, CardBody} from 'reactstrap';

export default class DebugApiResults extends Component {
  render(){
    let decodeHtmlString

    return (
      <div>
        <Card>
          <CardBody>
            <div className={'debugResultCardTitle flexSpaceBetween'}>
              <span className={'ogTextColor'}>Request</span>
            </div>
            <strong className={'blueText'}>GET</strong>{`  ${this.props.lastCalledUrl}`}
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <div className={'debugResultCardTitle flexSpaceBetween'}>
              <span className={'ogTextColor'}>Response</span>
            </div>
            {this.props.html ?
              (<pre>
                {this.props.results}
              </pre>) : (
              <ReactJson src={this.props.results} theme={'summerfruit:inverted'} classname={'breakWord'} style={{wordBreak: 'break-word'}}/>
                )}
          </CardBody>
        </Card>
      </div>
    )
  }
}
