import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import EyeIcon from 'mdi-react/EyeIcon';
import {connect} from 'react-redux';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import { Button } from 'reactstrap';
import validate from './validatePassword';
import {editUserPassword, getLoggedInUser} from '../../../redux/actions/users';
import {Col, Card, CardBody} from 'reactstrap';



const renderField = ({
                       input, placeholder, type, meta: { touched, error },
                     }) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

class PasswordResetForm extends PureComponent {

  constructor() {
    super();
    this.state = {
      showPassword: false,
    };
  }

  componentDidMount(){
    this.props.getLoggedInUser()
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  signUpClicked = () => {
    // check for errors then sign up
    if(this.props.formState.syncErrors){

    } else {
      // edit user
      this.props.editUserPassword();
    }
  };

  render() {
    return (
      <Col md={6} xl={6}>
        <Card>
          <CardBody>
            <form className="form">
              <div className="form__form-group">
                <span className="form__form-group-label">Password</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <KeyVariantIcon />
                  </div>
                  <Field
                    name="password"
                    component={renderField}
                    type={this.state.showPassword ? 'text' : 'password'}
                    placeholder="Password"
                  />
                  <button
                    className={`form__form-group-button${this.state.showPassword ? ' active' : ''}`}
                    onClick={e => this.showPassword(e)}
                  ><EyeIcon />
                  </button>
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Confirm Password</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <KeyVariantIcon />
                  </div>
                  <Field
                    name="passwordConfirm"
                    component={renderField}
                    type={this.state.showPassword ? 'text' : 'password'}
                    placeholder="Password Confirmation"
                  />
                  <button
                    className={`form__form-group-button${this.state.showPassword ? ' active' : ''}`}
                    onClick={e => this.showPassword(e)}
                  ><EyeIcon />
                  </button>
                </div>
              </div>
              <Button className="btn btn-primary account__btn account__btn--small whiteText" onClick={this.signUpClicked}>Update Password</Button>
            </form>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({
  formState: state.form.password_reset_form,
  user: state.users.user,
  initialValues: {password: ''}
});

const mapDispatchToProps = (dispatch) => ({
  editUserPassword: () => dispatch(editUserPassword()),
  getLoggedInUser: () => dispatch(getLoggedInUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'password_reset_form',
  validate
})(PasswordResetForm));
