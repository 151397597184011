import React, {Component} from 'react';
import {Col, Card, CardBody, CardHeader, CardTitle, Collapse, Table} from 'reactstrap';
import UnfoldLessIcon from 'mdi-react/UnfoldLessHorizontalIcon';
import UnfoldMoreIcon from 'mdi-react/UnfoldMoreHorizontalIcon';

export default class HtmlInferredDataTable extends Component {

  state = {
    collapseOpen: true,
  };

  toggleCollapse = () => {
    this.setState({collapseOpen: !this.state.collapseOpen})
  };

  renderCollapseIcon = () => {
    if(this.state.collapseOpen){
      return <UnfoldLessIcon size={35} onClick={this.toggleCollapse} className={'clickable debugTitleIcon'}/>
    } else {
      return <UnfoldMoreIcon size={35} onClick={this.toggleCollapse} className={'clickable debugTitleIcon'}/>
    }
  };

  renderCellForKey = (key, value) => {
    let imgSrcUrl = '';
    switch(key){
      case 'url':
        return (
          <a href={value} target={'_blank'}>{value}</a>
        );
      case 'image':
        value = value || {};
        imgSrcUrl = value.url || value;
        return (
          <a href={imgSrcUrl} className="irc_mi" target={'_blank'}>
            <img src={imgSrcUrl} className={'debugTableImage'}/>
          </a>
        );
      case 'image_guess':
        return (
          <a href={value} className="irc_mi" target={'_blank'}>
            <img src={value} className={'debugTableImage'}/>
          </a>
        );
      case 'images':
        return value.map((imgSrc) => {
          return (
            <a href={imgSrc} className="irc_mi" target={'_blank'} key={imgSrc}>
              <img src={imgSrc} className={'debugTableImage'}/>
            </a>
          )
        });
      case 'favicon':
        return (
          <a href={value} className="irc_mi" target={'_blank'} key={value}>
            <img src={value} className={'debugTableImage'}/>
          </a>
        );
      default:
        if(typeof(value) === 'object'){
          value = JSON.stringify(value)
        }
        return value
    }
  };

  renderRows = (data) => {
    return Object.keys(data).map((key, index) => {
      return (
        <tr key={`${key}-${index}`}>
          <td><strong>{`og:${key}`}</strong></td>
          <td>{this.renderCellForKey(key, data[key])}</td>
        </tr>
      )
    });
  };

  render(){
    const data = this.props.data || {};
    return (
      <Card>
        <CardBody>
          <div className={'debugResultCardTitle flexSpaceBetween'}>
            <span className={'ogTextColor'}>Opengraph.io HTML Inferred</span>
            <div>
              <span className={'successText'}>{`Found ${Object.keys(data).length} Results`}</span>
              <span className={'padding5'}>{this.renderCollapseIcon()}</span>
            </div>
          </div>
          <Collapse isOpen={this.state.collapseOpen}>
            <div className="panel__content">
              <Table className="table--bordered" responsive striped>
                <thead>
                <tr>
                  <th>Property</th>
                  <th>Value</th>
                </tr>
                </thead>
                <tbody>
                {this.renderRows(data)}
                </tbody>
              </Table>
            </div>
          </Collapse>
        </CardBody>
      </Card>
    )
  }
}