import React, {Component} from 'react';
import EditIcon from 'mdi-react/SquareEditOutlineIcon';
import TrashIcon from 'mdi-react/TrashCanOutlineIcon';
import CheckIcon from 'mdi-react/CheckCircleIcon';
import {connect} from 'react-redux';
import {editApiKey, deleteApiKey} from '../../../redux/actions/apiKeys';
import { Form, Input } from 'reactstrap';
import ConfirmationModal from '../../../shared/ConfirmationModal';


class ApiKeyTableRow extends Component {

  state = {
    editMode: false,
    description: this.props.apiKey.description || '',
    modalVisible: false
  };

  editConfirmClicked = (apiKey) =>{
    apiKey.description = this.state.description;
    this.props.editApiKey(apiKey);
    this.setState({editMode: false})
  };

  renderEditIcon = (apiKey) => {
    if(this.state.editMode){
      return <CheckIcon onClick={()=>this.editConfirmClicked(apiKey)} className={'marginRight15 clickable'}/>
    } else {
      return <EditIcon onClick={()=> this.setState({editMode: true})} className={'marginRight15 clickable'}/>
    }
  };

  renderDescriptionOrInput = (apiKey) => {
    if(this.state.editMode){
      return (
        <Form onSubmit={()=>this.editConfirmClicked(apiKey)}>
          <Input value={this.state.description} onChange={(e)=> this.setState({description: e.target.value })} type={'text'} />
        </Form>
      )
    } else {
      return apiKey.description
    }
  };

  toggleModal = () => {
    this.setState({modalVisible: !this.state.modalVisible})
  };

  render(){
    const apiKey = this.props.apiKey;
    return (
      <tr>
        <ConfirmationModal
          title={'Are you sure you want to delete this API Key?'}
          message={'This action is irreversible.'}
          buttonText={'Delete'}
          buttonColor={'primary'}
          visible={this.state.modalVisible}
          toggle={this.toggleModal}
          action={()=> this.props.deleteApiKey(apiKey.id)}
        />
        <td>{apiKey.key}</td>
        <td>{this.renderDescriptionOrInput(apiKey)}</td>
        <td>{apiKey.numberOfCallsInCurrentPeriod}</td>
        <td>
          {this.renderEditIcon(apiKey)}
          <TrashIcon onClick={this.toggleModal} className={'clickable'} />
        </td>
      </tr>
    )
  }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({
  editApiKey: (payload) => dispatch(editApiKey(payload)),
  deleteApiKey: (payload) => dispatch(deleteApiKey(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiKeyTableRow)
