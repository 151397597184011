import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import {getApis, clearDetailApi, } from '../../../redux/actions/apis';
import {clearDetailPlan} from '../../../redux/actions/plans';
import {adminEmails} from '../../../constants/config';
import SupportModal from './SupportModal';


class SidebarContent extends Component {
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  state = {
    supportModalOpen: false
  };

  componentDidMount(){
    this.props.getApis();
  }

  hideSidebar = () => {
    this.props.onClick();
  };

  clearApiDetailsPage = () => {
    this.props.clearDetailApi
    this.props.clearDetailPlan
  };

  renderApiLinks = () => {
    return this.props.apis.map((api) => <span key={api.id} onClick={this.props.clearDetailPlan}><SidebarLink  title={api.name} route={`/apis/${api.uriFriendlyName}`}  onClick={this.hideSidebar}/></span>)
  };

  renderApiEditLinks = () => {
    return this.props.apis.map((api) => <span key={api.id} onClick={this.props.clearDetailApi}><SidebarLink  title={api.name} route={`/admin/apis/edit/${api.uriFriendlyName}`}  onClick={this.hideSidebar}/></span>)
  };

  renderDashboardLink = () => {
    if(this.props.whiteLabelMode){
      return <SidebarLink title="Dashboard" route={`/apis/${this.props.whiteLabelApi.uriFriendlyName}`} onClick={this.hideSidebar} icon="home"/>
    } else {
      return <SidebarLink title="Dashboard" route="/dashboard" onClick={this.hideSidebar} icon="home"/>
    }
  };

  toggleSupportModal = () => {
    this.setState({supportModalOpen: !this.state.supportModalOpen})
  };

  render() {
    const user = this.props.user || {};
    return (
      <div className="sidebar__content">
        <SupportModal visible={this.state.supportModalOpen} toggle={this.toggleSupportModal}/>
        {/*<ul className="sidebar__block">*/}
          {/*<SidebarCategory title="Layout" icon="layers">*/}
            {/*<button className="sidebar__link" onClick={this.props.changeToLight}>*/}
              {/*<p className="sidebar__link-title">Light Theme</p>*/}
            {/*</button>*/}
            {/*<button className="sidebar__link" onClick={this.props.changeToDark}>*/}
              {/*<p className="sidebar__link-title">Dark Theme</p>*/}
            {/*</button>*/}
          {/*</SidebarCategory>*/}
        {/*</ul>*/}
        <ul className="sidebar__block">
          { this.props.user &&
            this.renderDashboardLink()}
          { this.props.user &&
            <SidebarLink title="Profile" route="/profile" onClick={this.hideSidebar} icon="user"/>}
          {!this.props.whiteLabelMode && this.props.user &&
          <SidebarCategory title="APIs" icon="rocket">
            {this.renderApiLinks()}
          </SidebarCategory>
          }
          {this.props.whiteLabelMode &&

            <li className="sidebar__link" onClick={this.toggleSupportModal}>
            <span className="sidebar__link-icon lnr lnr-envelope" />
              <p className="sidebar__link-title">
              Support
              </p>
            </li>
          }
          {this.props.whiteLabelMode &&
          <SidebarLink title="Debug Tool" route="/debug" onClick={this.hideSidebar} icon="code"/>
          }
          { adminEmails.includes(user.email) &&
            <SidebarCategory title="Admin stuff" icon="paw">
              <SidebarLink title="Users Dashboard" route="/admin/users" onClick={this.hideSidebar} icon="users"/>
              <SidebarCategory title="Api Edit">
                {this.renderApiEditLinks()}
              </SidebarCategory>
            </SidebarCategory>
            }
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  apis: state.apis.apis,
  whiteLabelMode: state.whiteLabel.whiteLabelMode,
  whiteLabelApi: state.whiteLabel.whiteLabelApi,
  user: state.users.user,
});

const mapDispatchToProps = (dispatch) => ({
  getApis: () => dispatch(getApis()),
  clearDetailApi: () => dispatch(clearDetailApi()),
  clearDetailPlan: () => dispatch(clearDetailPlan()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarContent);
