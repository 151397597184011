import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import {connect} from 'react-redux';
import { Button } from 'reactstrap';
import {getLoggedInUser} from '../../../redux/actions/users';
import {sendSupportEmail} from '../../../redux/actions/support';
import {Col, Card, CardBody} from 'reactstrap';



const renderField = ({
                       input, placeholder, type, meta: { touched, error }, multiline
                     }) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} multiline={multiline} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

class EditUserDetailsForm extends PureComponent {

  constructor() {
    super();
    this.state = {
      showPassword: false,
    };
  }

  componentDidMount(){
    this.props.getLoggedInUser()
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  sendSupportEmailClicked = () => {
    let apiId = this.props.apiId || this.props.whiteLabelApi.id;

    // check for errors then sign up
    if(this.props.formState.values.body.length === 0){

    } else {
      // edit user
      this.props.sendSupportEmail(apiId, this.props.payload);
      this.props.toggle();
    }
  };

  render() {
    return (
      <Col>
        <Card>
          <CardBody>
            <form className="form">
              <div className="form__form-group">
                <span className="form__form-group-label">Email</span>
                <div className="form__form-group-field">
                  <Field
                    name="email"
                    component={renderField}
                    type="text"
                    placeholder="Email"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="body"
                    component={'textarea'}
                    type="textarea"
                    placeholder="Let us know what the issue is..."
                    multiline={'true'}
                  />
                </div>
              </div>
              <Button className="btn btn-primary account__btn account__btn--small whiteText" onClick={this.sendSupportEmailClicked}>Contact Support</Button>
            </form>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({
  formState: state.form.support_email_form,
  user: state.users.user,
  initialValues: {...state.users.user, password: ''},
  whiteLabelApi: state.whiteLabel.whiteLabelApi
});

const mapDispatchToProps = (dispatch) => ({
  sendSupportEmail: (apiId, payload) => dispatch(sendSupportEmail(apiId, payload)),
  getLoggedInUser: () => dispatch(getLoggedInUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'support_email_form',
})(EditUserDetailsForm));
