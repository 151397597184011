import React from 'react'

import { Button, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

const OrganizationHeaderActions = ({ onClick, showSetDefault, setDefault, showInviteButton }) => {

  return (
    <Row>
      {showSetDefault && (
        <Button onClick={setDefault} color={'primary'} >
            Set As Default Organization
        </Button> )
      }
      {showInviteButton ? (
        <Link style={{ color: 'white', textDecoration: 'none', border: 'none'}} to={'/apis/billing/Opengraphio'}>
          <Button color={'primary'}>
              Upgrade to Invite Users
          </Button>
        </Link>
        ) : (
          <Button color={'primary'} onClick={onClick}>
            Invite User
          </Button>
        )
      }
    </Row>
  )
}

export default OrganizationHeaderActions