import { apiClient } from '../../constants/clients';
import {
  GET_PLAN_PERIODS_FOR_PLAN,
  GET_PLAN_PERIODS_FOR_PLAN_FAIL,
  GET_PLAN_PERIODS_FOR_PLAN_SUCCESS
} from '../actionTypes';

export function getPlanPeriodsForPlan(payload){
  payload = payload || {};
  const itemsPerPage = 5;
  let client = apiClient.getPlanPeriodsForPlan(payload.planId);

  //ServerSidePaging
  if (typeof(payload.page) === 'number' && itemsPerPage){
    const offset = payload.page * itemsPerPage;
    client = client.offset(offset).limit(itemsPerPage)
  }

  client = client.setSortFields(['createdAt']);
  client = client.setOrderByDesc();

  // //Search
  // const searchTerm = payload.searchTerm || '';
  // if(searchTerm.length > 0){
  //   client = client.setFilter('name=\'%' + payload.searchTerm + '%\'')
  // }

  return dispatch => {
    dispatch({type: GET_PLAN_PERIODS_FOR_PLAN});
    client.exec((err, result, fullResp)=> {
      if(err){
        dispatch({type:GET_PLAN_PERIODS_FOR_PLAN_FAIL, payload: err})
      } else {
        let payload = {
          planPeriods: result,
          count: fullResp.body.total,
        };
        dispatch({type:GET_PLAN_PERIODS_FOR_PLAN_SUCCESS, payload})
      }
    })
  }
}