import React, {useState, useEffect} from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import {
    snippetLanguages,
    buildSnippet,
} from '../../../redux/actions/debuggerForm';
import Select from 'react-select';
import {connect} from 'react-redux';


const DebugRequestSnippet = (props) => {
    const [type, setType] = useState(Object.values(snippetLanguages)[0]);
    const {codePreview, buildSnippet} = props;
    const {snippet, language} = codePreview;
    const setTypeValue = (value) => {
        setType(value);
    }

    const trimString = (str) => {
        return str ? str.replace(/^\s+|\s+$/g, '') : '';
    }

    useEffect(() => {
        console.log('Building Snippet', type)
        buildSnippet(type.value)
    }, [type]);

    console.log('snippetLangs', Object.values(snippetLanguages))

    return (
        <div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <h3>Request Snippet</h3>
                <div style={{width: 250}}>
                    <Select
                        name="language"
                        value={type}
                        onChange={setTypeValue}
                        options={Object.values(snippetLanguages)}
                        className="form__form-group-select"
                        formatOptionLabel={({value, name, image}) => <div value={value}>
                            <div style={{height:20, width: 50 , marginRight: 15, display: 'inline-block', textAlign: 'center'}}>
                                <img src={image}  style={{width: 'auto', height: '100%'}} />
                            </div>
                            {name}
                        </div>}
                    />
                </div>
            </div>
            <SyntaxHighlighter language={language} >
                {trimString(snippet)}
            </SyntaxHighlighter>
        </div>
    );
}

const mapStateToProps = (state) => ({
    codePreview: state.debuggerForm.codePreview,
});

const mapDispatchToProps = (dispatch) => ({
    buildSnippet: (language) => dispatch(buildSnippet(language)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DebugRequestSnippet)