import { apiClient } from '../../constants/clients';
import {
  CLEAR_DETAIL_API,
  GET_API_BY_FRIENDLY_NAME,
  GET_API_BY_FRIENDLY_NAME_FAIL, GET_API_BY_FRIENDLY_NAME_SUCCESS,
  GET_APIS,
  GET_APIS_FAIL,
  GET_APIS_SUCCESS,
  SET_DETAIL_ADMIN_API,
  CREATE_API,
  API_CREATE_FAIL,
  API_CREATE_SUCCESS,
  UPDATE_API,
  API_UPDATE_FAIL,
  API_UPDATE_SUCCESS,
  DELETE_API,
  API_DELETE_FAIL,
  API_DELETE_SUCCESS,
	GET_API,
	API_GET_FAIL,
	API_GET_SUCCESS
} from '../actionTypes';
import {getPlanByApiAndOrganization} from './plans';

export function getApis(){
  return (dispatch) => {
    dispatch({type: GET_APIS});
    apiClient.getApis()
      .limit(1000)
      .exec((err, result)=>{
        if(err){
          dispatch({type: GET_APIS_FAIL, payload: err})
        } else {
          dispatch({type: GET_APIS_SUCCESS, payload: result})
        }
      })
  }
}

export function getApiByFriendlyName(friendlyName){
  return (dispatch) => {
    dispatch({type: GET_API_BY_FRIENDLY_NAME});
    apiClient.getApiByFriendlyName(friendlyName)
      .exec((err, result) => {
        if(err){
          dispatch({type: GET_API_BY_FRIENDLY_NAME_FAIL, payload: err})
        } else {
          dispatch({type: GET_API_BY_FRIENDLY_NAME_SUCCESS, payload: result});
        }
      })
  }
}

export function setDetailAdminApi(api) {
  return {type: SET_DETAIL_ADMIN_API, payload: api}
}

export function createApi(apiInfo) {
  return (dispatch) => {
    dispatch({type: CREATE_API});
    apiClient.createApi(apiInfo)
      .exec((err, result) => {
        if(err){
          dispatch({type: API_CREATE_FAIL, payload: err})
        } else {
	        dispatch(getApis());
          dispatch({type: API_CREATE_SUCCESS, payload: result});
        }
      })
  }
}

export function updateApi(apiInfo) {
  return (dispatch) => {
    dispatch({type: UPDATE_API});
    apiClient.updateApi(apiInfo)
      .exec((err, result) => {
        if(err){
          dispatch({type: API_UPDATE_FAIL, payload: err})
        } else {
	        dispatch(getApis());
          dispatch({type: API_UPDATE_SUCCESS, payload: result});
        }
      })
  }
}

export function deleteApi(apiInfo) {
  return (dispatch) => {
    dispatch({type: DELETE_API});
    apiClient.deleteApi(apiInfo.id)
      .exec((err, result) => {
        if(err){
          dispatch({type: API_DELETE_FAIL, payload: err})
        } else {
          dispatch(getApis());
          dispatch({type: API_DELETE_SUCCESS, payload: result});
        }
      })
  }
}

export function getApi(apiId){
  return (dispatch) => {
	  dispatch({type: GET_API});
	  apiClient.getApi(apiId)
		  .exec((err, result) => {
			  if(err){
				  dispatch({type: API_GET_FAIL, payload: err})
			  } else {
				  dispatch(getApis());
				  dispatch({type: API_GET_SUCCESS, payload: result});
			  }
		  })
  }
}

export function clearDetailApi(){
  return {type: CLEAR_DETAIL_API};
}
