import { apiClient } from '../../constants/clients';
import {
  GET_WHITE_LABEL_API,
  GET_WHITE_LABEL_API_FAIL,
  GET_WHITE_LABEL_API_SUCCESS,
  CLEAR_WHITE_LABEL
} from '../actionTypes';

const blackLabelHosts = [
  'apifur'
];

export function setWhiteLabel(hostname){
  let blackLabelHost = blackLabelHosts.find((h) => h === hostname);
  if(!blackLabelHost){
    return getWhiteLabelApi(hostname)
  } else {
    return {type: CLEAR_WHITE_LABEL}
  }
}

export function getWhiteLabelApi(hostname){
  return (dispatch) => {
    dispatch({type: GET_WHITE_LABEL_API});
    apiClient.getApiByFriendlyName('Opengraphio')
      .exec((err, result) => {
        if(err){
          dispatch({type: GET_WHITE_LABEL_API_FAIL, payload: err})
        } else {
          dispatch({type: GET_WHITE_LABEL_API_SUCCESS, payload: result})
        }
      })
  }
}