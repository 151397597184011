import React from 'react';


const ExtractForm = ( props ) => {

  const { toggleHtmlElement, extractOptions } = props;

  return (
    <form>
      <h3 className="debugger-h3 ogTextColor largeFont">HTML ELEMENTS</h3>
      <p className="form__form-group-label">Select the HTML elements you want to extract from the page</p>
      <p className="form__form-group-label">The API can accept any HTML element, but if none are provided, the default elements that will be extracted are h1, h2, h3, h4, h5, p, and title.</p>
      <div className="form__form-group__html-element-container">
        {extractOptions.htmlElements.map((htmlElement, i) => {
          return (
            <div key={i} style={{display: 'flex', flex: '0 0 50%'}}>
              <label className="form__form-group-field checkbox--label_container">
                <input className={'checkbox-btn checkbox-btn__checkbox-custom'}
                       name={htmlElement}
                       checked={extractOptions.selectedHtmlElements.includes(htmlElement)}
                       onChange={toggleHtmlElement} type="checkbox" />
                {htmlElement}
              </label>
            </div>
          )
        })}
      </div>
    </form>
  )
}

export default ExtractForm;
