import {apiClient} from '../../constants/clients';
import {
  GET_ORGANIZATION_USERS,
  GET_ORGANIZATION_USERS_FAIL,
  GET_ORGANIZATION_USERS_SUCCESS,
  SET_CURRENT_ORG,
  INVITE_USER_TO_ORGANIZATION,
  INVITE_USER_TO_ORGANIZATION_FAIL,
  INVITE_USER_TO_ORGANIZATION_SUCCESS,
  SET_INVITE_VALIDATION_ERROR,
  CLEAR_INVITE_VALIDATION_ERROR,
  SET_NEW_USER_INVITE_EMAIL,
  SET_NEW_USER_INVITE_ROLE,
  TOGGLE_NEW_USER_INVITE_DIALOG,
  CLEAR_NEW_USER_INVITE_DIALOG,
  CLEAR_ORGANIZATION_ERROR,
  CLEAR_INVITE_USER_ERROR,
  CLEAR_ORGANIZATION_ALERT,
  GET_CURRENT_USER_ORGANIZATION_INVITES,
  GET_CURRENT_USER_ORGANIZATION_INVITES_FAIL,
  GET_CURRENT_USER_ORGANIZATION_INVITES_SUCCESS,
  UPDATE_ORGANIZATION_INVITE_FAIL,
  UPDATE_ORGANIZATION_INVITE_SUCCESS,
  UPDATE_ORGANIZATION_INVITE,
  REMOVE_USER_FROM_ORGANIZATION,
  REMOVE_USER_FROM_ORGANIZATION_FAIL,
  REMOVE_USER_FROM_ORGANIZATION_SUCCESS,
  UPDATE_USER_ROLE_IN_ORGANIZATION,
  UPDATE_USER_ROLE_IN_ORGANIZATION_FAIL,
  UPDATE_USER_ROLE_IN_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_FAIL,
  UPDATE_ORGANIZATION_SUCCESS,
  SET_DEFAULT_ORGANIZATION,
  SET_DEFAULT_ORGANIZATION_FAIL,
  SET_DEFAULT_ORGANIZATION_SUCCESS,
  GET_ORGANIZATION_CURRENT_TIER_FAIL,
  GET_ORGANIZATION_CURRENT_TIER,
  GET_ORGANIZATION_CURRENT_CYCLE_INFORMATION_SUCCESS,
  GET_ORGANIZATION_CURRENT_TIER_SUCCESS,
  GET_ORGANIZATION_STRIPE_SUBSCRIPTION,
  GET_ORGANIZATION_STRIPE_SUBSCRIPTION_FAIL, GET_ORGANIZATION_STRIPE_SUBSCRIPTION_SUCCESS,
  GET_ORGANIZATION_STRIPE_INFO, GET_ORGANIZATION_STRIPE_INFO_FAIL, GET_ORGANIZATION_STRIPE_INFO_SUCCESS,
} from '../actionTypes';
import { getPlanByApiAndOrganization } from './plans'

export function getOrganizationStripeInfo(orgId) {
    console.log('GET ORG STRIPE INFO')
    return (dispatch, getState) => {
        let organizationId = orgId || getState().organization.currentOrg.information.id;
        if(!organizationId) return console.error('No organization id in state')
        dispatch({type: GET_ORGANIZATION_STRIPE_INFO})
        apiClient.getStripeInfoForOrganization(organizationId)
            .exec((err, result) => {
                if (err) {
                    console.error('GET ORG STRIPE ERR', err);
                    dispatch({type: GET_ORGANIZATION_STRIPE_INFO_FAIL, payload: err})
                } else {
                    console.log('DONE GET ORG STRIPE INFO', result);
                    dispatch({type: GET_ORGANIZATION_STRIPE_INFO_SUCCESS, payload: result})
                }
            })
    }
}

export function getUsersForOrganization(organizationId){

  return dispatch => {
    dispatch({type: GET_ORGANIZATION_USERS});
    apiClient.getUsersForOrganization(organizationId)
      .exec((err, result, fullResp)=> {
        if(err){
          dispatch({type:GET_ORGANIZATION_USERS_FAIL, payload: err.message})
        } else {
          let payload = {
            users: result,
          };
          dispatch({type:GET_ORGANIZATION_USERS_SUCCESS, payload})
        }
    })
  }
}

export function getOrganizationsForUser(userId){
  return dispatch => {
    dispatch({type: GET_ORGANIZATION_USERS});
    apiClient.getOrganizaionsAndItsUsers(userId)
      .exec((err, result, fullResp)=> {
        if(err){
          dispatch({type:GET_ORGANIZATION_USERS_FAIL, payload: err.message})
        } else {
          dispatch({type:GET_ORGANIZATION_USERS_SUCCESS, payload: result})
        }
    })
  }
}

export function inviteUserToOrganization(payload) {
  const {organizationId, email, role} = payload;

  return dispatch => {
    dispatch({type: INVITE_USER_TO_ORGANIZATION})
    apiClient.inviteUserToOrganization(organizationId, email, role)
      .exec((err, result, fullResp)=> {
        if(err){
          dispatch({type:INVITE_USER_TO_ORGANIZATION_FAIL, payload: err.details.message})
        } else {
          dispatch({type:INVITE_USER_TO_ORGANIZATION_SUCCESS, payload: result})
        }
    })
  }
}

export function getOrganizationInvitesForCurrentUser(){

  return ( dispatch, getState ) => {
    const user = getState().users.user

    dispatch({type: GET_CURRENT_USER_ORGANIZATION_INVITES})
    apiClient.getOrganizationInvitesForUser(user.id)
      .exec((err, result, fullResp)=> {
        if(err){
          dispatch({type:GET_CURRENT_USER_ORGANIZATION_INVITES_FAIL, payload: err.details.message})
        } else {
          dispatch({type:GET_CURRENT_USER_ORGANIZATION_INVITES_SUCCESS, payload: result})
        }
      })
  }
}

export function updateOrganizationInvite(payload){

  const {invitationId, newStatus} = payload

  return (dispatch, getState) => {

    const organizationId = getState().organization.currentOrg.information.id

    dispatch({type: UPDATE_ORGANIZATION_INVITE})
    apiClient.updateOrganizationInvite(invitationId, newStatus)
      .exec((err, result, fullResp)=> {
        if(err){
          dispatch({ type: UPDATE_ORGANIZATION_INVITE_FAIL, payload: err.details.message })
        } else {
          dispatch({ type: UPDATE_ORGANIZATION_INVITE_SUCCESS, payload: result.message })
          dispatch(getUsersForOrganization(organizationId))
          dispatch(getOrganizationInvitesForCurrentUser())
        }
      })
  }
}

export function updateUserRoleInOrganization(payload){
  const {organizationId, userId, role} = payload;

  return dispatch => {
    dispatch({type: UPDATE_USER_ROLE_IN_ORGANIZATION})
    apiClient.updateUserRoleInOrganization(organizationId, userId, role)
      .exec((err, result, fullResp)=> {
        if(err){
          dispatch({type:UPDATE_USER_ROLE_IN_ORGANIZATION_FAIL, payload: err.details.message})
        } else {
          dispatch({type:UPDATE_USER_ROLE_IN_ORGANIZATION_SUCCESS, payload: result})
          dispatch(getUsersForOrganization(organizationId))
        }
    })
  }
}

export function removeUserFromOrganization(payload){
  const {organizationId, userId} = payload;

  return dispatch => {
    dispatch({type: REMOVE_USER_FROM_ORGANIZATION})
    apiClient.removeUserFromOrganization(organizationId, userId)
      .exec((err, result, fullResp)=> {
        if(err){
          dispatch({type:REMOVE_USER_FROM_ORGANIZATION_FAIL, payload: err.details.message})
        } else {
          dispatch({type:REMOVE_USER_FROM_ORGANIZATION_SUCCESS, payload: result.message})
          dispatch(getUsersForOrganization(organizationId))
        }
    })
  }
}

export function updateOrganization(payload) {

  return dispatch => {
    dispatch({type: UPDATE_ORGANIZATION})
    apiClient.updateOrganization(payload)
      .exec((err, result, fullResp)=> {
        if(err){
          dispatch({type:UPDATE_ORGANIZATION_FAIL, payload: err.details.message})
        } else {
          dispatch({type:UPDATE_ORGANIZATION_SUCCESS, payload: result})
        }
      })
  }
}

export function setDefaultOrganization() {

  return (dispatch, getState) => {
    let user = getState().users.user
    let defaultOrgId = getState().organization.currentOrg.information.id

    dispatch({type: SET_DEFAULT_ORGANIZATION});
    apiClient.updateLastUsedOrganization(defaultOrgId)
      .exec((err, result) => {
        if (err) {
          dispatch({type: SET_DEFAULT_ORGANIZATION_FAIL, payload: err})
        } else {
          dispatch({type: SET_DEFAULT_ORGANIZATION_SUCCESS, payload: { result, defaultOrgId}})
          dispatch(getPlanByApiAndOrganization(defaultOrgId))
          // dispatch(getLoggedInUser())
        }
      })
  }
}

export function getOrganizationCurrentCycleInformation(orgId) {
  return dispatch => {
    dispatch({type: GET_ORGANIZATION_CURRENT_TIER});
    apiClient.getOrganizationTier(orgId)
      .exec((err, result) => {
        if (err) {
          dispatch({type: GET_ORGANIZATION_CURRENT_TIER_FAIL, payload: err})
        } else {
          dispatch({type: GET_ORGANIZATION_CURRENT_TIER_SUCCESS, payload: result})
          dispatch({type: GET_ORGANIZATION_CURRENT_CYCLE_INFORMATION_SUCCESS, payload: result})
        }
      })
  }
}

export function setCurrentOrganization(organization){
  return dispatch => {
    dispatch({type: SET_CURRENT_ORG, payload: organization})
  }
}

export function setInviteValidationError(payload){
  return dispatch => {
    dispatch({type: SET_INVITE_VALIDATION_ERROR, payload})
  }
}

export function clearInviteValidationError(){
  return dispatch => {
    dispatch({type: CLEAR_INVITE_VALIDATION_ERROR})
  }
}

export function setNewUserInviteEmail(payload){
  return dispatch => {
    dispatch({type: SET_NEW_USER_INVITE_EMAIL, payload})
  }
}

export function setNewUserInviteRole(payload){
  return dispatch => {
    dispatch({type: SET_NEW_USER_INVITE_ROLE, payload})
  }
}

export function clearNewUserInvite(){
  return dispatch => {
    dispatch({type: CLEAR_NEW_USER_INVITE_DIALOG})
  }
}

export function toggleInviteDialog(payload){
  return dispatch => {
    dispatch({type: TOGGLE_NEW_USER_INVITE_DIALOG, payload})
    dispatch({type: CLEAR_NEW_USER_INVITE_DIALOG})

  }
}

export function clearOrganizationError() {
  return dispatch => {
    dispatch({type: CLEAR_ORGANIZATION_ERROR})
  }
}

export function clearInviteUserError() {
  return dispatch => {
    dispatch({type: CLEAR_INVITE_USER_ERROR})
  }
}

export function clearOrganizationAlert() {
  return dispatch => {
    dispatch({ type: CLEAR_ORGANIZATION_ALERT })
  }
}

export function getOrganizationStripeSubscription(subscriptionId) {
  return dispatch => {
    dispatch({ type: GET_ORGANIZATION_STRIPE_SUBSCRIPTION })
    apiClient.getStripeTrialSubscriptionDetails(subscriptionId)
      .exec((err, result) => {
        if (err) {
          dispatch({ type: GET_ORGANIZATION_STRIPE_SUBSCRIPTION_FAIL, payload: err })
        } else {
          dispatch({ type: GET_ORGANIZATION_STRIPE_SUBSCRIPTION_SUCCESS, payload: result })
        }
      })
  }
}
