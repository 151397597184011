import React, {Component} from 'react';
import {connect} from 'react-redux'
import { Col, Container, Row, Card, CardBody, Table, Badge} from 'reactstrap';
import {getPlansForOrganization} from '../../../redux/actions/plans';
import {Link} from 'react-router-dom';

class UserApisTable extends Component {

   componentWillReceiveProps(nextProps){
     if(nextProps.user && this.props.plansLoading){
        this.props.getPlansForOrganization()
     }
   }

   renderRows = (plans) => {
      return plans.map((plan) => {
        let api = plan.api;
        return(
          <tr key={plan.api.id}>
            <td><Link to={`/apis/${api.uriFriendlyName}`} > {api.name} </Link></td>
          </tr>
        )
      })
   };

  render(){
    const plans = this.props.plans || [];
    return (
      <Col md={12} lg={12} >
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="subhead"> Active Apis </h5>
            </div>
            <Table className="table--bordered" responsive>
              <thead>
              <tr>
                <th>Name</th>
              </tr>
              </thead>
              <tbody>
              {this.renderRows(plans)}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.users.user,
  plans: state.plans.userPlans,
  plansLoading: state.plans.userPlansLoading
});

const mapDispatchToProps = (dispatch) => ({
  getPlansForOrganization: (payload) => dispatch(getPlansForOrganization(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserApisTable)