import React, {Component} from 'react';
import {Col, Row, Container, Alert } from 'reactstrap';
import {connect} from 'react-redux';
import {getApiByFriendlyName, clearDetailApi} from '../../redux/actions/apis';

class ApiEdit extends Component {

  redirectIfNotLoggedIn = () => {
    if(!this.props.user && !this.props.userLoading){
      this.props.history.push('/login')
    }
  };

  getApi(){
    if(!this.props.api || this.props.api.uriFriendlyName !== this.props.match.params.name){
      if(!this.props.apiError){
        this.props.getApiByFriendlyName(this.props.match.params.name);
      }
    }
  };

  render(){
    this.redirectIfNotLoggedIn();
    this.getApi();

    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            {/*<Alert color={planAlert.color} className="alert--bordered" isOpen={!!planAlert.message} toggle={this.props.clearPlanAlert}>*/}
              {/*<div className={'padding5'}>*/}
                {/*<span>{planAlert.message}</span>*/}
              {/*</div>*/}
            {/*</Alert>*/}
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            Hey there!!!!
          </Col>
        </Row>
      </Container>
      )
  }
}

const mapStateToProps = (state) => ({
  api: state.apis.detailApi,
  user: state.users.user,
  plan: state.plans.detailPlan,
  userLoading: state.users.loading,
  apiError: state.apis.error,
});

const mapDispatchToProps = (dispatch) => ({
  getApiByFriendlyName: (payload) => dispatch(getApiByFriendlyName(payload)),
  clearDetailApi: () => dispatch(clearDetailApi()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiEdit)