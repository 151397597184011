import React, {Component} from 'react';
import { Col, Row } from 'reactstrap';
import TierCard from './TierCard';
import TierCardCurrent from './TierCardCurrent';
import TierCardFree from './TierCardFree';
import { useFeatureValue, useFeatureIsOn } from '@growthbook/growthbook-react';

export default class ApiTiers extends Component {

  // This Module Is no longer Needed
  // It has been refactored into one directory with two total files instead of 4

  renderTiers(tiers){
    return tiers.map((tier) => {
      if(!tier.isDiscontinued){
        if(this.props.currentTierId === tier.id){
          return <TierCardCurrent tier={tier} key={tier.id} />
        } else if (tier.priceMonthly === 0){
          return <TierCardFree tier={tier} key={tier.id} currentTierId={this.props.currentTierId} plan={this.props.plan}  />
        }
        return <TierCard tier={tier} key={tier.id} currentTierId={this.props.currentTierId} plan={this.props.plan}/>
      }
    })
  }

  render(){
    const tiers = this.props.tiers || [];
    const featureIsOn = useFeatureIsOn('no_trial');
    return (
      <Col>
        <Row>
          {this.renderTiers(tiers)}
        </Row>
      </Col>
    )
  }
};

