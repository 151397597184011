import React, { useEffect } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const TablePagination = (props) => {
  const { count, itemsPerPage, currentPage, changePage } = props;

  const [numberOfPages, setNumberOfPages] = React.useState(0);
  const [pagesArray, setPagesArray] = React.useState([]);

  useEffect(() => {
    const pagesCount = getNumberOfPages();
    setNumberOfPages(pagesCount);
  }, [count]);

  useEffect(() => {
    if (numberOfPages !== 0) {
      const pagesArr = makePagesArray();
      setPagesArray(pagesArr);
    }
  }, [numberOfPages, currentPage]);

  const getNumberOfPages = () => {
    return Math.ceil(count / itemsPerPage);
  };

  const makePagesArray = () => {
    const maxPagesToShow = 5;
    const pagesArr = [];
    let startPage, endPage;

    if (numberOfPages <= maxPagesToShow) {
      // Show all pages if total pages are less than or equal to maxPagesToShow
      startPage = 1;
      endPage = numberOfPages;
    } else {
      // Determine start and end pages if more than maxPagesToShow pages
      const maxPagesBeforeCurrentPage = Math.floor(maxPagesToShow / 2);
      const maxPagesAfterCurrentPage = Math.ceil(maxPagesToShow / 2) - 1;

      if (currentPage + 1 <= maxPagesBeforeCurrentPage) {
        // Near the start
        startPage = 1;
        endPage = maxPagesToShow;
      } else if (currentPage + 1 + maxPagesAfterCurrentPage >= numberOfPages) {
        // Near the end
        startPage = numberOfPages - maxPagesToShow + 1;
        endPage = numberOfPages;
      } else {
        // Somewhere in the middle
        startPage = currentPage + 1 - maxPagesBeforeCurrentPage;
        endPage = currentPage + 1 + maxPagesAfterCurrentPage;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pagesArr.push({ pageNumber: i });
    }

    return pagesArr;
  };

  const renderItems = () => {
    return pagesArray.map((page) => (
      <PaginationItem key={page.pageNumber}>
        <PaginationLink
          onClick={() => changePage(page.pageNumber - 1)}
          className={`pagination__link ${
            currentPage + 1 === page.pageNumber ? 'active' : ''
          }`}
        >
          {page.pageNumber}
        </PaginationLink>
      </PaginationItem>
    ));
  };

  return (
    <Pagination>
      <PaginationItem disabled={currentPage === 0}>
        <PaginationLink
          first
          onClick={() => changePage(0)}
          className={'pagination__link'}
        />
      </PaginationItem>
      <PaginationItem disabled={currentPage === 0}>
        <PaginationLink
          onClick={() => changePage(currentPage - 1)}
          className={'pagination__link'}
          previous
        />
      </PaginationItem>
      {renderItems()}
      <PaginationItem disabled={numberOfPages === 0 || currentPage + 1 === numberOfPages} >
        <PaginationLink
          onClick={() => changePage(currentPage + 1)}
          className={'pagination__link'}
          next
        />
      </PaginationItem>
      <PaginationItem disabled={numberOfPages === 0 || currentPage + 1 === numberOfPages} >
        <PaginationLink
          onClick={() => changePage(numberOfPages - 1)}
          className={'pagination__link'}
          last
        />
      </PaginationItem>
    </Pagination>
  );
};

export default TablePagination;
