import React, {Component} from 'react';
import { Card, CardBody, Col, Button } from 'reactstrap';
import {connect} from 'react-redux';
import {createPlan, updatePlan} from '../../../redux/actions/plans';
import FreeTierModal from './FreeTierModal';
// import ReactGA from 'react-ga';
import mark from '../../../shared/img/mark.svg'
import check from '../../../shared/img/check.svg'
import { TrackGoogleAnalyticsEvent } from '../../../shared/utils/google-analytics';

class TierCardFree extends Component {

  state  = {
    modalVisible: false,
  };

  mapDescriptionToBullets = (tier) => {
    let description = tier.description || '';
    return description.split('|').map((bullet) => {
      if(bullet.startsWith('x')){
        const bulletPoint = bullet.replace('x', '')
        return (
            <tr>
              <td className='pricing-card__x'><img src={mark}/></td>
              <td className="pricing-card__non_feature" key={bulletPoint}>{bulletPoint}</td>
            </tr>
        )
      }
      return (
          <tr>
            <td className='pricing-card__check'><img src={check}/></td>
            <td className="pricing-card__feature" key={bullet}>{bullet}</td>
          </tr>
      )
    })
  }

  signUpForTier = (tier, source) => {
    if (this.props.plan.id){
      let payload = {
        tierId: this.props.tier.id,
        id: this.props.plan.id,
        price: tier.priceMonthly,
        name: tier.name,
      };

      this.props.updatePlan(payload)
    } else {
      let payload = {
        tierId: tier.id,
        apiId: tier.apiId,
        startDate: new Date(),
        price: tier.priceMonthly,
        name: tier.name,
      };

      this.props.createPlan(payload)
    }
  };

  toggleModal = () => {
    this.setState({modalVisible: !this.state.modalVisible})
  };

  render() {
    const tier = this.props.tier || {};
    return (
      <Col md={6} xl={3} sm={12}>
        <FreeTierModal tier={tier} signUpForTier={this.signUpForTier} visible={this.state.modalVisible} toggle={this.toggleModal} currentTierId={this.props.currentTierId}/>
        <Card>
          <CardBody className="pricing-card__body">
            <div className='pricing-card__header'>
              <p className={'pricing-card--emoji'} dangerouslySetInnerHTML={{__html: `${this.props.tier.emoji}`}} />
              <h3 className="current-billing--info pricing-card__plan">{tier.name}</h3>
              <p className="pricing-card__price">Free</p>
            </div>
            <table className='pricing-card-details__table'>
              {this.mapDescriptionToBullets(tier)}
            </table>
            <div className='pricing-card__button_container'>
              <Button id={tier.name} className="pricing-card__button" color="primary"
                      onClick={() => this.toggleModal()}>{this.props.currentTierId ? 'Switch Plans' : 'Sign Up'}</Button>
            </div>
          </CardBody>
        </Card>
      </Col>
    )
  }
}


const mapStateToProps = (state) => ({
  plan: state.plans.detailPlan
});

const mapDispatchToProps = (dispatch) => ({
  createPlan: (payload) => dispatch(createPlan(payload)),
  updatePlan: (payload) => dispatch(updatePlan(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TierCardFree)
