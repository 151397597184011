import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';

import {
  clearInviteUserError,
  clearInviteValidationError,
  clearOrganizationAlert, getOrganizationInvitesForCurrentUser,
  getUsersForOrganization,
  inviteUserToOrganization, removeUserFromOrganization, setCurrentOrganization, setDefaultOrganization,
  setInviteValidationError,
  setNewUserInviteEmail,
  setNewUserInviteRole,
  toggleInviteDialog, updateOrganizationInvite, updateUserRoleInOrganization
} from '../../redux/actions/organization';

import OrganizationUserTable from './Components/OrganizationUserTable';
import OrganizationHeaderActions from './Components/OrganizationHeaderActions';
import OrganizationInviteUserModal from './Components/OrganizationInviteUserModal';
import OrganizationUserInvitationsTable from './Components/OrganizationUserInvitationsTable';

import {validateInvite} from './Constants/validateInvite';
import {Alert, Col, Container, FormGroup, Input, Row} from 'reactstrap';
import './OrganizationUsersDashboardStyle.style.scss';
import {getLoggedInUser} from '../../redux/actions/users';
import {Link} from 'react-router-dom';
import {getPlanByApiAndOrganization} from '../../redux/actions/plans';


const OrganizationUsersDashboard = (props) => {

  const { organization, user, toggleInviteDialog, setEmail, setRole, clearInviteUserError, clearOrganizationAlert  } = props
  const { email, role, error, inputError, open, loading } = organization.inviteDialog
  const { userOrganizationInvites, currentOrg } = organization

  const [ showDefault, setShowDefault ] = useState(false)
  const [ showUpgradeAlert, setShowUpgradeAlert ] = useState(false)
  const [ showInviteButton, setShowInviteButton ] = useState(false)

  const [ selectedNewRole, setSelectedNewRole ] = useState(null)

  const [ showSelectMenuIndex, setShowSelectMenuIndex ] = useState(null);

  const handleEditClick = (index) => {
    if (showSelectMenuIndex !== index) {
      setShowSelectMenuIndex(index);
    } else {
      setShowSelectMenuIndex(null);
    }
  };

  const userDefaultOrgIdRef = useRef(user ? user.defaultOrgId : null);

  const organizationIsAtCapacity = (currentOrgTier, organization) => {
    if( currentOrgTier.data.priceMonthly === 0 && currentOrgTier.data.maxUsers === 1) {
      return true
    } else if ( organization.users.users.length >= currentOrgTier.data.maxUsers) {
      return true
    } else {
      return false
    }

  }

  useEffect(() => {
    const getUsersForOrganization = async (orgId) => {
      return await props.getUsersForOrganization(orgId)
    }

    if(currentOrg && currentOrg.information && currentOrg.information.id && user) {
      getUsersForOrganization(currentOrg.information.id)
      setShowDefault(currentOrg.information.id !== user.defaultOrgId)

      const isAtOrganizationCapacity = organizationIsAtCapacity(currentOrg.tier, organization)
      setShowUpgradeAlert(isAtOrganizationCapacity)
      setShowInviteButton(isAtOrganizationCapacity)
    }

  }, [ currentOrg, userDefaultOrgIdRef.current ])

  if (user && user.defaultOrgId !== userDefaultOrgIdRef.current) {
    userDefaultOrgIdRef.current = user.defaultOrgId;
  }

  useEffect(() => {
    const getOrganizationInvitesForCurrentUser = async () => {
      return await props.getOrganizationInvitesForCurrentUser()
    }

    user && getOrganizationInvitesForCurrentUser()
  }, [])


  const sendInvite = async () => {
    const errors = validateInvite({email, role})

    if(Object.keys(errors).length > 0){
      props.setInviteValidationError(errors)
      return
    } else {
      props.clearInviteValidationError()
    }

    const invite = {
      email,
      organizationId: currentOrg.information.id,
      role,
    }
    await props.inviteUserToOrganization(invite)

  }

  const handleUpdateUserRoleInOrganization = async (userId, newRole) => {
    const payload = {
      organizationId: currentOrg.information.id,
      userId,
      role: newRole,
    }
    props.updateUserRoleInOrganization(payload)
    handleEditClick()
  }

  const handleUpdateInvite = async (invitationId, newStatus) => {
    const payload = {
      invitationId,
      newStatus
    }
    props.updateOrganizationInvite(payload)
  }

  const setDefaultOrganization = () => {
    props.setDefaultOrganization()

  }

  const handleOrganizationChange = (e) => {
    const orgId = e.target.value
    const org = organization.organizations.find(org => org.id === orgId)
    props.setCurrentOrganization(org)
  }

  const handleRemoveUserFromOrganization = (userId) => {
    const payload = {
      organizationId: currentOrg.information.id,
      userId,
    }
    props.removeUserFromOrganization(payload)
  }

  const renderUpgradeTierToAddUserAlert = () => {

    return (
      <Row>
        <Col md={12}>
          <Alert color='warning' className="alert--bordered" isOpen={showUpgradeAlert} toggle={() => setShowUpgradeAlert(!showUpgradeAlert)} >
            <div className={'padding5'}>
              <p>To add more users to your Organization Upgrade Your Tier. <Link to={'/apis/billing/Opengraphio'}>Upgrade Here</Link></p>

            </div>
          </Alert>
        </Col>
      </Row>
    )
  }


  return (
    <Container >
      {renderUpgradeTierToAddUserAlert()}
      { !user ? (
        <Row>
          <Col md={12}>
            <div style={{width: '100%', margin: 'auto' }}>
              <div className="spinner-border text-success" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </Col>
        </Row>
        ) : (
          <>
        <Row>
        <Col md={12}>
          <Alert color={organization.alert.color} className="alert--bordered" isOpen={!!organization.alert.message}
                 toggle={clearOrganizationAlert}>
            <div className={'padding5'}>
              <span>{organization.alert.message}</span>
            </div>
          </Alert>
        </Col>
      </Row>
        <h1 className='welcome-user'>Organization Management</h1>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <FormGroup>
        <h3 className="subhead">Organization Select</h3>
        <Input
        id="organization-select"
        name="select"
        type="select"
        bsSize="sm"
        defaultValue={user.defaultOrgId}
        onChange={handleOrganizationChange}
        >
      {organization.organizations.map((org, index) => {
        return (
        <option key={'org' + index} value={org.id}>{org.name}</option>
        )
      })}
        </Input>
        </FormGroup>
        <OrganizationHeaderActions
          showInviteButton={showInviteButton}
          showSetDefault={showDefault}
          setDefault={setDefaultOrganization}
          onClick={() => toggleInviteDialog(!open)}
        />
        <OrganizationInviteUserModal
        isOpen={open}
        toggle={() => toggleInviteDialog(!open)}
        sendInvite={sendInvite}
        enableInviteButton={!(email.length > 0 && email.includes('@') && role.length > 0)}
        setEmail={setEmail}
        setRole={setRole}
        userRole={user.role}
        inviteError={error}
        clearInviteError={clearInviteUserError}
        emailError={inputError.email}
        roleError={inputError.role}
        loading={loading}
        />
        </div>
      <OrganizationUserTable
        handleEditClick={handleEditClick}
        showSelectMenuIndex={showSelectMenuIndex}
        selectedNewRole={selectedNewRole}
        setSelectedNewRole={setSelectedNewRole}
        handleRemoveUserFromOrganization={handleRemoveUserFromOrganization}
        users={organization.users}
        userId={user.id}
        currentOrganizationRole={user.role}
        handleUpdateUserRoleInOrganization={handleUpdateUserRoleInOrganization}
        />
        <Row>
      {userOrganizationInvites.invites.count > 0 ?
        <Col md={12}>
        <h3>Invitations</h3>
        <OrganizationUserInvitationsTable
        invitations={userOrganizationInvites.invites.rows}
        handleUpdateInvite={handleUpdateInvite}
        />
        </Col>
        : null}
        </Row>
        </>
        )}
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.users.user,
    organization: state.organization,
    inviteDialog: state.organization.inviteDialog,
    plans: state.plans.detailPlan,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getLoggedInUser: () => dispatch(getLoggedInUser()),
    getUsersForOrganization: (organizationId) => dispatch(getUsersForOrganization(organizationId)),
    inviteUserToOrganization: (payload) => dispatch(inviteUserToOrganization(payload)),
    setInviteValidationError: (payload) => dispatch(setInviteValidationError(payload)),
    clearInviteValidationError: () => dispatch(clearInviteValidationError()),
    toggleInviteDialog: (payload) => dispatch(toggleInviteDialog(payload)),
    setEmail: (email) => dispatch(setNewUserInviteEmail(email)),
    setRole: (role) => dispatch(setNewUserInviteRole(role)),
    clearInviteUserError: () => dispatch(clearInviteUserError()),
    clearOrganizationAlert: () => dispatch(clearOrganizationAlert()),
    getOrganizationInvitesForCurrentUser: () => dispatch(getOrganizationInvitesForCurrentUser()),
    updateOrganizationInvite: (payload) => dispatch(updateOrganizationInvite(payload)),
    setCurrentOrganization: (org) => dispatch(setCurrentOrganization(org)),
    setDefaultOrganization: () => dispatch(setDefaultOrganization()),
    removeUserFromOrganization: (payload) => dispatch(removeUserFromOrganization(payload)),
    updateUserRoleInOrganization: (payload) => dispatch(updateUserRoleInOrganization(payload)),
    getPlanByApiAndOrganization: ((orgId) => dispatch(getPlanByApiAndOrganization(orgId))),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(OrganizationUsersDashboard)
