import {
  INVITE_USER_TO_ORGANIZATION,
  INVITE_USER_TO_ORGANIZATION_SUCCESS,
  INVITE_USER_TO_ORGANIZATION_FAIL,
  GET_ORGANIZATION_USERS,
  GET_ORGANIZATION_USERS_FAIL,
  GET_ORGANIZATION_USERS_SUCCESS,
  GET_USER_ORGANIZATIONS,
  GET_USER_ORGANIZATIONS_FAIL,
  GET_USER_ORGANIZATIONS_SUCCESS,
  SET_CURRENT_ORG,
  SET_INVITE_VALIDATION_ERROR,
  CLEAR_INVITE_VALIDATION_ERROR,
  TOGGLE_NEW_USER_INVITE_DIALOG,
  SET_NEW_USER_INVITE_EMAIL,
  SET_NEW_USER_INVITE_ROLE,
  CLEAR_NEW_USER_INVITE_DIALOG,
  CLEAR_ORGANIZATION_ERROR,
  CLEAR_INVITE_USER_ERROR,
  CLEAR_ORGANIZATION_ALERT,
  GET_CURRENT_USER_ORGANIZATION_INVITES,
  GET_CURRENT_USER_ORGANIZATION_INVITES_SUCCESS,
  GET_CURRENT_USER_ORGANIZATION_INVITES_FAIL,
  UPDATE_ORGANIZATION_INVITE,
  UPDATE_ORGANIZATION_INVITE_SUCCESS,
  UPDATE_ORGANIZATION_INVITE_FAIL,
  REMOVE_USER_FROM_ORGANIZATION,
  REMOVE_USER_FROM_ORGANIZATION_SUCCESS,
  REMOVE_USER_FROM_ORGANIZATION_FAIL,
  UPDATE_USER_ROLE_IN_ORGANIZATION,
  UPDATE_USER_ROLE_IN_ORGANIZATION_SUCCESS,
  UPDATE_USER_ROLE_IN_ORGANIZATION_FAIL,
  SET_DEFAULT_ORGANIZATION_SUCCESS,
  SET_DEFAULT_ORGANIZATION_FAIL,
  GET_ORGANIZATION_CURRENT_TIER,
  GET_ORGANIZATION_CURRENT_TIER_SUCCESS,
  GET_ORGANIZATION_CURRENT_TIER_FAIL,
  GET_ORGANIZATION_CURRENT_CYCLE_INFORMATION,
  GET_ORGANIZATION_CURRENT_CYCLE_INFORMATION_SUCCESS,
  GET_ORGANIZATION_STRIPE_SUBSCRIPTION,
  GET_ORGANIZATION_STRIPE_SUBSCRIPTION_FAIL, GET_ORGANIZATION_STRIPE_SUBSCRIPTION_SUCCESS,
    GET_ORGANIZATION_STRIPE_INFO, GET_ORGANIZATION_STRIPE_INFO_FAIL, GET_ORGANIZATION_STRIPE_INFO_SUCCESS,
} from '../actionTypes';


const initialState = {
  alert: {
    message: '',
    color: '',
  },
  defaultOrg: {},
  currentOrg : {
    currentBillingCycle: {
      loading: false,
      errorMessage: '',
      startDate: null,
      endDate: null,
      totalCalls: 0
    },
    information: {},
    stripeSubscription: {
      loading: false,
      error: {},
      data: {}
    },
    stripeInfo: {
      loading: false,
      error: {},
      data: {}
    },
    tier: {
      loading: false,
      error: {},
      data: {
        currentTier: {
          featuresAllowed: [],
        },
        rateLimits: null,
        pricingScheme: null,
      },
    },
  },
  error: {},
  inviteDialog: {
    email: '',
    role: '',
    open: false,
    loading: false,
    error: {
      message: ''
    },
    inputError: {},
  },
  loading: true,
  organizations: [],
  userOrganizationInvites: {
    error: {},
    invites: {
      count: 0,
      rows: [],
    },
    loading: false,
  },
  users: {
    error: {},
    users: [],
    loading: false,
  },
}

const organization = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORGANIZATION_USERS:
      return {
        ...state,
        users: {
          ...state.users,
          loading: true,
        }
      }
    case GET_ORGANIZATION_USERS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          error: {},
          users: action.payload.users,
        }
      }
    case GET_ORGANIZATION_USERS_FAIL:
      return {
        ...state,
        alert: {
          message: action.payload.message,
          color: 'danger',
        },
        users: {
          ...state.users,
          loading: false,
          error: action.payload,
        }
      }
    case GET_USER_ORGANIZATIONS:
      return {
        ...state,
        loading: true,
      }
    case GET_USER_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: {},
        organizations: action.payload,
      }
    case GET_USER_ORGANIZATIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case SET_CURRENT_ORG:
      return {
        ...state,
        currentOrg: {
          ...state.currentOrg,
          information: action.payload,
        }
      }
    case INVITE_USER_TO_ORGANIZATION:
      return {
        ...state,
        inviteDialog: {
          ...state.inviteDialog,
          loading: true,
        }
      }
    case INVITE_USER_TO_ORGANIZATION_SUCCESS:
      return {
        ...state,
        inviteDialog: {
          ...state.inviteDialog,
          loading: false,
          open: false,
        },
        alert: {
          message: action.payload.message,
          color: 'success'
        },
      }
    case INVITE_USER_TO_ORGANIZATION_FAIL:
      return {
        ...state,
        inviteDialog: {
          ...state.inviteDialog,
          loading: false,
          error: {
            message: action.payload
          },
        },
      }
    case SET_INVITE_VALIDATION_ERROR:
      return {
        ...state,
        inviteDialog: {
          ...state.inviteDialog,
          inputError: action.payload,
        }
      }
    case CLEAR_INVITE_VALIDATION_ERROR:
      return {
        ...state,
        inviteDialog: {
          ...state.inviteDialog,
          inputError: {},
        }
      }

    case TOGGLE_NEW_USER_INVITE_DIALOG:
      return {
        ...state,
        inviteDialog: {
          ...state.inviteDialog,
          open: action.payload,
        }
      }

    case SET_NEW_USER_INVITE_EMAIL:
      return {
        ...state,
        inviteDialog: {
          ...state.inviteDialog,
          email: action.payload,
        }
      }
    case SET_NEW_USER_INVITE_ROLE:
      return {
        ...state,
        inviteDialog: {
          ...state.inviteDialog,
          role: action.payload,
        }
      }

    case CLEAR_NEW_USER_INVITE_DIALOG:
      return {
        ...state,
        inviteDialog: {
          ...state.inviteDialog,
          email: '',
          role: '',
          error: {},
          loading: false,
          inputError: {},
        }
      }

    case CLEAR_ORGANIZATION_ERROR:
      return {
        ...state,
        error: {},
      }

    case CLEAR_INVITE_USER_ERROR:
      return {
        ...state,
        inviteDialog: {
          ...state.inviteDialog,
          error: {},
        }
      }
    case CLEAR_ORGANIZATION_ALERT:
      return {
        ...state,
        alert: {
          message: '',
          color: '',
        }
      }
    case GET_CURRENT_USER_ORGANIZATION_INVITES:
      return {
        ...state,
        userOrganizationInvites: {
          ...state.userOrganizationInvites,
          loading: true,
        }
      }
    case GET_CURRENT_USER_ORGANIZATION_INVITES_SUCCESS:
      return {
        ...state,
        userOrganizationInvites: {
          ...state.userOrganizationInvites,
          loading: false,
          invites: action.payload,
          error: {},
        }
      }
    case GET_CURRENT_USER_ORGANIZATION_INVITES_FAIL:
      return {
        ...state,
        userOrganizationInvites: {
          ...state.userOrganizationInvites,
          loading: false,
          error: action.payload,
        }
      }
    case UPDATE_ORGANIZATION_INVITE:
      return {
        ...state,
        loading: true,
      }
    case UPDATE_ORGANIZATION_INVITE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: {},
        alert: {
          message: action.payload,
          color: 'success',
        }
      }
    case UPDATE_ORGANIZATION_INVITE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        alert: {
          message: action.payload,
          color: 'danger',
        }
      }
    case REMOVE_USER_FROM_ORGANIZATION:
      return {
        ...state,
        users: {
          ...state.users,
          loading: true,
        }
      }
    case REMOVE_USER_FROM_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: {},
        alert: {
          message: action.payload,
          color: 'success',
        }
      }
    case REMOVE_USER_FROM_ORGANIZATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        alert: {
          message: action.payload,
          color: 'danger',
        }
      }
    case UPDATE_USER_ROLE_IN_ORGANIZATION:
      return {
        ...state,
        users: {
          ...state.users,
          loading: true,
        }
      }
    case UPDATE_USER_ROLE_IN_ORGANIZATION_SUCCESS:
      return {
        ...state,
        error: {},
        alert: {
          message: action.payload.message,
          color: 'success',
        },
        users: {
          ...state.users,
          loading: false,
        }
      }
    case UPDATE_USER_ROLE_IN_ORGANIZATION_FAIL:
      return {
        ...state,
        error: action.payload,
        alert: {
          message: action.payload,
          color: 'danger',
        },
        users: {
          ...state.users,
          loading: false,
        }
      }
    case SET_DEFAULT_ORGANIZATION_SUCCESS:
      return {
        ...state,
        defaultOrg: action.payload.result.defaultOrgId,
        error: {},
        alert: {
          message: action.payload.result.message,
          color: 'success',
        }
      }
    case SET_DEFAULT_ORGANIZATION_FAIL:
      return {
        ...state,
        error: action.payload,
        alert: {
          message: action.payload.message,
          color: 'danger',
        }
      }
    case GET_ORGANIZATION_CURRENT_TIER:
      return {
        ...state,
        currentOrg: {
          ...state.currentOrg,
          loading: true
        }
      }
    case GET_ORGANIZATION_CURRENT_TIER_SUCCESS:
      return {
        ...state,
        currentOrg: {
          ...state.currentOrg,
          tier: {
            ...state.currentOrg.tier,
            loading: false,
            data: action.payload,
          }
        }
      }
    case GET_ORGANIZATION_CURRENT_CYCLE_INFORMATION:
      return {
        ...state,
        currentOrg: {
          ...state.currentOrg,
          tier: {
            ...state.currentOrg.tier,
            loading: true,
          },
          currentBillingCycle: {
            ...state.currentOrg.currentBillingCycle,
            loading: true,
          }
        }
      }
    case GET_ORGANIZATION_CURRENT_CYCLE_INFORMATION_SUCCESS:
      return {
        ...state,
        currentOrg: {
          ...state.currentOrg,
          tier: {
            ...state.currentOrg.tier,
            loading: false,
            data: {
              ...state.currentOrg.tier.data,
              ...action.payload.currentTier
            }
          },
          currentBillingCycle: {
            ...state.currentOrg.currentBillingCycle,
            loading: false,
            totalCalls: action.payload.numberOfApiCreditsUsedInCurrentPeriod || 0,
            ...action.payload.currentPlanPeriod
          }
        }
      }
    case GET_ORGANIZATION_CURRENT_TIER_FAIL:
      return {
        ...state,
        currentOrg: {
          ...state.currentOrg,
          tier: {
            ...initialState.currentOrg.tier,
          }
        }
      }
    case GET_ORGANIZATION_STRIPE_SUBSCRIPTION:
      return {
        ...state,
        currentOrg: {
          ...state.currentOrg,
          stripeSubscription: {
            ...state.currentOrg.currentBillingCycle.stripeSubscription,
            loading: true,
          }
        }
      }
    case GET_ORGANIZATION_STRIPE_SUBSCRIPTION_FAIL:
      return {
        ...state,
        currentOrg: {
          ...state.currentOrg,
          stripeSubscription: {
            ...state.currentOrg.stripeSubscription,
            loading: false,
            error: action.payload,
          }
        }
      }
    case GET_ORGANIZATION_STRIPE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        currentOrg: {
          ...state.currentOrg,
          stripeSubscription: {
            ...state.currentOrg.stripeSubscription,
            loading: false,
            data: action.payload,
          }
        }
      }


    case GET_ORGANIZATION_STRIPE_INFO:
      return {
        ...state,
        currentOrg: {
          ...state.currentOrg,
          stripeInfo: {
            ...state.currentOrg.stripeInfo,
            loading: true,
          }
        }
      }
    case GET_ORGANIZATION_STRIPE_INFO_FAIL:
      return {
        ...state,
        currentOrg: {
          ...state.currentOrg,
          stripeInfo: {
            ...state.currentOrg.stripeInfo,
            loading: false,
            error: action.payload,
          }
        }
      }
    case GET_ORGANIZATION_STRIPE_INFO_SUCCESS:
      return {
        ...state,
        currentOrg: {
          ...state.currentOrg,
          stripeInfo: {
            ...state.currentOrg.stripeInfo,
            loading: false,
            data: action.payload,
          }
        }
      }
      default:
        return state;
  }
};

export default organization;
