import {EventTypes} from 'redux-segment';

export const createSegmentEvent = (type, eventPayload) => {
    if(!EventTypes[type]){
        console.error(`INVALID EVENT TYPE SUPPLIED: ${type}`, eventPayload)
    }
    return {
        eventType: EventTypes[type],
        eventPayload,
    }
};