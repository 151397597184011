import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import MailRuIcon from 'mdi-react/MailRuIcon';
import { Button, Input } from 'reactstrap';
import {connect} from 'react-redux';


const renderField = ({
                       input, placeholder, type, meta: { touched, error },
                     }) => (
  <div className="form__form-group-input-wrap">
    <Input {...input} placeholder={placeholder} type={type} />
  </div>
);

class LogInForm extends PureComponent {

  constructor() {
    super();
    this.state = {
      showPassword: false,
    };
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  signInClicked = () => {
    if (this.props.formState.syncErrors){

    } else {
      this.props.loginUser()
    }
  };

  render() {
    const { handleSubmit } = this.props;

    return (
      <form className="form">
        <div className="form__form-group">
          {/*<span className="form__form-group-label">What could we have done better?</span>*/}
          <div className="form__form-group-field">
            <Field
              name="cancelationReason"
              component={renderField}
              type="textarea"
              placeholder="Please tell us what we could have done better"
            />
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  formState: state.form.cancel_form
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'cancel_form',
})(LogInForm));
