import React from 'react';
import { Form, FormGroup, Input, Label, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { setImageQuality, setViewportWidth, toggleFullPage } from '../../../../redux/actions/debuggerForm';

const ScreenshotForm = ( props ) => {

  const { toggleFullPage, setImageQuality, setViewportWidth, screenshotOptions } = props;
  const { imageQuality, viewportWidth, fullPage } = screenshotOptions

  return (
    <Form>
      <FormGroup md={12} xl={12} className="debugger-from--wrapper">
        <h3 className="debugger-h3 ogTextColor largeFont">Screenshot Settings</h3>
        <p className="form__form-group-label">Getting the screenshot for an element comes with many different options.</p>
        <p className="form__form-group-label">If you do not provide any to the API, all options do have defaults.</p>
        <br />
        <FormGroup row>
          <Label
            for="full-page-checkbox"
            sm={2}
          >
            Page Size
          </Label>
          <Col
            sm={{
              size: 10
            }}
          >
            <FormGroup check>
              <Input
                id="full-page-checkbox"
                type="checkbox"
                onChange={toggleFullPage}
                checked={fullPage}
              />
              {' '}
              <Label check>
                Full Page
              </Label>
            </FormGroup>
          </Col>
        </FormGroup>
        {/*<Container>*/}
        <Form>
          <FormGroup className="align-items-center">
            <Row>
              <Col md="2">
                <Label for="screenshot-quality-range">
                  Screenshot Quality
                </Label>
              </Col>
              <Col md="4">
                <Input
                  id="screenshot-quality-range"
                  name="range"
                  type="range"
                  min="10"
                  max="80"
                  step="10"
                  defaultValue={imageQuality}
                  onChange={(e) => setImageQuality(e.target.value)}
                />
              </Col>
              <Col md="1">
                <p>{imageQuality}</p>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup row className="align-items-center">
            <Label md="2" for="viewport-width-select">
              Viewport Width
            </Label>
            <Col md="4">
              <Input
                id="viewport-width-select"
                name="select"
                type="select"
                onChange={(e) => setViewportWidth(e.target.value)}
                defaultValue={viewportWidth}
              >
                <option value='lg'>
                  Large
                </option>
                <option value='md'>
                  Medium
                </option>
                <option value='sm'>
                  Small
                </option>
                <option value='xs'>
                  Extra Small
                </option>
              </Input>
            </Col>
          </FormGroup>
        </Form>
        <br/>
      </FormGroup>
    </Form>
  )
};

const mapStateToProps = (state) => ({
  screenshotOptions: state.debuggerForm.screenshot.options,
})

const mapDispatchToProps = (dispatch) => ({
  setViewportWidth: (viewportWidth) => dispatch(setViewportWidth(viewportWidth)),
  setImageQuality: (imageQuality) => dispatch(setImageQuality(imageQuality)),
  toggleFullPage: () => dispatch(toggleFullPage()),
})


export default  connect(mapStateToProps, mapDispatchToProps)(ScreenshotForm)

