import React, {Component} from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import ReactJson  from 'react-json-view';

//takes props
// visible
// toggle
// title
// message
// action

export default class FreeTierModal extends Component {

  render(){
    return (
      <Modal isOpen={this.props.visible} toggle={this.props.toggle} className={'modal-dialog--primary width900'}>
        <div className={'modal__header centerText bottomBorder'}>
          <button className="lnr lnr-cross modal__close-btn" onClick={this.props.toggle} />
          <h4 className={'bold-text modal__title'}>
            {this.props.title}
          </h4>
          <br />
        </div>
        <ModalBody>
          <div className={'modal__body leftText borderRadius5'}>
            <ReactJson src={this.props.message} theme={'summerfruit:inverted'} classname={'breakWord'} style={{wordBreak: 'break-word'}}/>
          </div>
        </ModalBody>
      </Modal>
    )
  }
}