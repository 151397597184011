import React, {useState, useRef, useEffect} from 'react'
import './navbar.scss'
import {connect} from 'react-redux'
import {Link, NavLink} from 'react-router-dom'
import {logoutUser} from '../../../redux/actions/users'
import SupportModal from '../sidebar/SupportModal'
import {CgProfile} from 'react-icons/cg'
import {RiLogoutBoxLine} from 'react-icons/ri';
import {BiSupport} from 'react-icons/bi';
import {VscOrganization} from 'react-icons/vsc';

const Navigation = (props) => {
    const dropdownRef = useRef(null);

    const [supportModalOpen, setSupportModalOpen] = useState(false)
    const [ showDropdown, setShowDropdown ] = useState(false)
    const [ scroll, setScroll ] = useState(false)
    const user = props.user;

    const getInitials = (user) => {
        if(!user) {
            return <CgProfile size={24} />
        }

        const {firstName, lastName} = user

        if(!user.firstName || !user.lastName) {
            return <CgProfile size={24} />
        }
        const firstInitial = firstName.charAt(0)
        const lastInitial = lastName.charAt(0)

        const initials = firstInitial + lastInitial
        return initials.toUpperCase()
    }

    const toggleSupportModal = () => {
        setSupportModalOpen(!supportModalOpen)
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false);
        }
    };

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown)
    }

    useEffect(() => {
        window.addEventListener('scroll', () => {
            setScroll(window.scrollY > 0)
        })
    })

    return (
      <>
          <div className={scroll ? 'navigation-placeholder scroll' : 'navigation-placeholder'}></div>
          <nav className={ scroll ? 'navigation navigation__top' : 'navigation'}>
              <div className={'navigation--container container'}>
                  <SupportModal visible={supportModalOpen}  toggle={()  => toggleSupportModal()} apiId={props.plan.apiId}/>
                  <Link className="navigation--logo" to="/">OpenGraph<span style={{color: '#2CBD6B'}}>.</span>io</Link>
                  <div className="">
                      {user && (
                        <ul className="navigation--item_container">
                            <li className={'navigation-item'}>
                                <NavLink exact activeClassName={'active'} to="/apis/Opengraphio">Dashboard</NavLink>
                            </li>
                            <li className="navigation-item">
                                <a target="_blank" href="https://www.opengraph.io/documentation">Documentation</a>
                            </li>
                            <li className="navigation-item">
                                <NavLink to="/apis/billing/Opengraphio">Billing</NavLink>
                            </li>
                            <li className="navigation-item">
                                <NavLink to="/organization">Manage Organization</NavLink>
                            </li>
                            <li className="navigation-item">
                                <NavLink className="" to="/api-playground">API Playground</NavLink>
                            </li>
                        </ul>
                      )}
                  </div>
                  <div className={'navigation--initials__dropdown'}>
                      <p onClick={toggleDropdown} className={'navigation--initials-btn'}>{getInitials(user)}</p>
                      {user ? (
                        <div ref={dropdownRef} className={`dropdown--content ${showDropdown ? 'show' : ''}`}>
                            <ul>
                                <li onClick={toggleDropdown} className="sidebar--navigation-item">
                                    <Link className="" to="/profile">
                                  <span>
                                      <p>Profile</p>
                                      <CgProfile size={24} />
                                  </span>
                                    </Link>
                                </li>
                                <li onClick={toggleDropdown} className="sidebar--navigation-item">
                                    <Link onClick={props.logout}  to='/'>
                                  <span>
                                    <p>Logout</p>
                                    <RiLogoutBoxLine size={24} />
                                  </span>
                                    </Link>
                                </li>
                                <li onClick={toggleDropdown} className="sidebar--navigation-item">
                              <span onClick={() => toggleSupportModal()}>
                                  <p>Support</p>
                                  <BiSupport size={24} />
                              </span>
                                </li>
                                <li onClick={toggleDropdown} className="sidebar--navigation-item">
                                    <Link to="/organization">
                                  <span>
                                      <p>Organization</p>
                                      <VscOrganization size={24} />
                                  </span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                      ): (
                        <div className={'dropdown--content'}>
                            <ul>
                                <li onClick={toggleDropdown} className="navigation-item">
                                    <Link className="" to="/login">Login</Link>
                                </li>
                                <li onClick={toggleDropdown} className="navigation-item">
                                    <Link to="/register">Register</Link>
                                </li>
                            </ul>
                        </div>
                      )}
                  </div>
              </div>
          </nav>
      </>
    )
}

const mapStateToProps = (state) => ({
    plan: state.plans.detailPlan,
    user: state.users.user,
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logoutUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation)
