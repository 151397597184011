import React, { useState, useEffect } from 'react'

import OrganizationTableHeader from './OrganizationTableHeader';
import OrganizationEditUserSelect from './OrganizationEditUserSelect';

import {Card, CardBody, Table} from 'reactstrap';
import OrganizationUserActions from './OrganizationUserActions';

const OrganizationUserTable = (props) => {

  const { users, handleRemoveUserFromOrganization, userId, handleUpdateUserRoleInOrganization,
    currentOrganizationRole, setSelectedNewRole, selectedNewRole,
    showSelectMenuIndex, handleEditClick } = props;


  const [editTooltipOpenIndex, setEditTooltipOpenIndex] = useState(null);
  const [saveEditTooltipOpenIndex, setSaveEditTooltipOpenIndex] = useState(null);
  const [cancelEditTooltipOpenIndex, setCancelEditTooltipOpenIndex] = useState(null);
  const [removeTooltipOpenIndex, setRemoveTooltipOpenIndex] = useState(null);


  const headers = ['Name', 'Email', 'Role', ' ']

  const toggleEdit = (index) => setEditTooltipOpenIndex(editTooltipOpenIndex !== index ? index : null);
  const toggleRemove = (index) => setRemoveTooltipOpenIndex(removeTooltipOpenIndex !== index ? index : null)
  const toggleSaveEdit = (index) => setSaveEditTooltipOpenIndex(removeTooltipOpenIndex !== index ? index : null)
  const toggleCancelEdit = (index) => setCancelEditTooltipOpenIndex(removeTooltipOpenIndex !== index ? index : null)

  const renderRows = (users) => {

    return (
      <tbody>
      {users.map((user, index) => (
          <tr key={index}>
            <td>{user.user.firstName} {user.user.lastName}</td>
            <td>
                {user.user.email}
            </td>
            <OrganizationEditUserSelect setSelectedNewRole={setSelectedNewRole} showSelectMenu={showSelectMenuIndex === index} usersCurrentRole={user.role} role={currentOrganizationRole} />
            {user.user.id !== userId && currentOrganizationRole === 'OWNER' && <OrganizationUserActions
              index={index}
              showSelectMenu={showSelectMenuIndex === index}
              handleEditClick={() => handleEditClick(index)}
              toggleEdit={toggleEdit}
              toggleRemove={toggleRemove}
              editTooltipOpenIndex={editTooltipOpenIndex}
              removeTooltipOpenIndex={removeTooltipOpenIndex}
              saveEditTooltipOpenIndex={saveEditTooltipOpenIndex}
              cancelEditTooltipOpenIndex={cancelEditTooltipOpenIndex}
              toggleSaveEdit={toggleSaveEdit}
              toggleCancelEdit={toggleCancelEdit}
              handleRemoveUserFromOrganization={() => handleRemoveUserFromOrganization(user.userId)}
              handleUpdateUserRoleInOrganization={() => handleUpdateUserRoleInOrganization(user.userId, selectedNewRole)}
            />}
          </tr>
        )
      )}
      </tbody>
    )
  }


  return (
     <Card>
       <CardBody>
         <h3 className="subhead">Team Members</h3>
         <Table
           responsive
         >
           <OrganizationTableHeader headers={headers} />
           {!users.loading ? renderRows(users.users) : (
             <td colSpan={4} style={{ textAlign: 'center'}}>
                <div className="spinner-border text-success" role="status">
                   <span className="sr-only">Loading...</span>
               </div>
             </td>
           )}
         </Table>
       </CardBody>
     </Card>
  )
}

export default OrganizationUserTable