import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux'
import {Col, Card, CardBody, Table, ButtonGroup, Button} from 'reactstrap';
import {getUsageInformationForOrganization} from '../../../redux/actions/users';
import {AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer} from 'recharts';
import moment from 'moment';

const DayByDayUsageChart = ( { getUsageInformationForOrganization, usageInfo }) => {
  const [ dateRange, setDateRange ] = useState(30);


  useEffect(() => {
    const fetchUsageInfo = async () => {
      await getUsageInformationForOrganization(dateRange)
    }
    fetchUsageInfo()
  }, [dateRange]);

  const { group_by_day:groupByDay , group_by_Type:groupByType } = usageInfo;

  if(!groupByDay || !groupByType) return (<div/>);

  const dateBuckets = groupByDay.buckets || [];
  const typeBuckets = groupByType.buckets || [];

  const mapKeysToDates = (buckets) => {
    return buckets.map(b => ({
      key: moment(new Date(b.key)).format('l'),
      requests: b['doc_count']
    }))
  };

  const renderRows = (topSites) => {
    return topSites.map(ts => {
      return (
        <tr key={`${ts.key}-table-row-yo`}>
          <td>{ts.key}</td>
          <td>{ts['doc_count']}</td>
        </tr>
      )
    })
  };
  const renderChart = (dateBuckets) => {
    return (
      <AreaChart
        data={mapKeysToDates(dateBuckets)}
      >
        <XAxis dataKey="key"/>
        <YAxis dataKey="requests"/>
        <Area  dataKey="requests" />
        <Tooltip />
      </AreaChart>
    )
  }



  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title flexSpaceBetween">
            <h4 style={{padding: 5}} className="subhead"> Day by Day Usage </h4>
            <ButtonGroup size="sm">
              <Button color={ dateRange === 7  ? 'primary' : 'secondary' } onClick={() => setDateRange(7)}>7 day</Button>
              <Button color={ dateRange === 30 ? 'primary' : 'secondary' } onClick={() => setDateRange(30)}>30 day</Button>
              <Button color={ dateRange === 90 ? 'primary' : 'secondary' } onClick={() => setDateRange(90)}>90 day</Button>
            </ButtonGroup>
          </div>
          { usageInfo.loading ? (
            <div style={{ display: 'flex', height: '200px', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
              <div className="spinner-border text-success centerText" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <ResponsiveContainer height={200} width={'100%'}>
              {renderChart(dateBuckets)}
            </ResponsiveContainer>
          )}
          <h4 style={{padding: 5}} className="subhead"> Most Requested Sites </h4>
          <Table className="table--bordered" responsive striped>
            <thead>
            <tr>
              <th>Site</th>
              <th>Count</th>
            </tr>
            </thead>
            <tbody>
            {renderRows(typeBuckets)}
            </tbody>
          </Table>

        </CardBody>
      </Card>
    </Col>
  )
}

const mapStateToProps = (state) => ({
  usageInfo: state.users.usageInfo
});

const mapDispatchToProps = (dispatch) => ({
  getUsageInformationForOrganization: (dateRange) => dispatch(getUsageInformationForOrganization(dateRange))
});

export default connect(mapStateToProps, mapDispatchToProps)(DayByDayUsageChart)
