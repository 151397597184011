import React, {Component} from 'react';
import {Col, Card, CardBody, Table} from 'reactstrap'
import {connect} from 'react-redux';
import {getApiLogsForPlan} from '../../../redux/actions/apiLogs';
import TablePagination from '../../../shared/TablePagination';
import ReqResModal from './ReqResModal';
import SupportModal from '../../Layout/sidebar/SupportModal';

import moment from 'moment';

class ApiLogsTable extends Component {

  state = {
    currentPage: 0,
    modalVisible: false,
    modalMessage: '',
    modalTitle: '',
    supportModalVisible: false,
    supportPayload: null
  };

  changePage = (page) => {
    this.setState({currentPage: page}, () => {
      this.props.getApiLogsForPlan({planId: this.props.plan.id, page: this.state.currentPage})
    })
  };

  componentDidMount(){
    if (this.props.plan && this.props.plan.id) {
      this.props.getApiLogsForPlan({planId: this.props.plan.id, page: 0})
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.plan && this.props.plan){
      if(nextProps.plan.id !== this.props.plan.id){
        this.props.getApiLogsForPlan({planId: nextProps.plan.id, page: 0})
      }
    }
  }

  toggleModal = () => {
    this.setState({modalVisible: !this.state.modalVisible})
  };

  openReqResModal = (modalTitle, modalMessage ) => {
    this.setState({modalTitle, modalMessage}, () => {
      this.toggleModal();
    })
  };

  calculateApiDuration = (reqTime, resTime) => {

    if(!reqTime || !resTime){
      return 0;
    }
    const requestTime = moment(reqTime);
    const responseTime = moment(resTime);

    const durationInSeconds = responseTime.diff(requestTime) / 1000;
    return durationInSeconds.toFixed(2);
  }

  renderRows(apiLogs){
    if(apiLogs.loading){
      return (
        <tr>
          <td colSpan={100}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <div className="spinner-border text-success centerText" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </td>
        </tr>
      )
    }
    if(apiLogs.apiLogs.length === 0){
      return (
        <tr>
          <td>No Logs Yet...</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      )
    }
    return apiLogs.apiLogs.map((apiLog) => {
      let fields = apiLog._source.fields;
      let date = apiLog._source['@timestamp'];
      let billing = apiLog._source.fields.billing;

      if(fields.request.path.length > 100){
        fields.request.path = fields.request.path.substring(0, 100) + '…';
      }

      return (
        <tr key={apiLog._id}>
          <td>{fields.request.method}</td>
          <td>{fields.request.path}</td>
          <td><span className={'clickable blueText'} onClick={() =>this.openSupportModal({apiLog})}>Support</span></td>
          <td><span className={'clickable blueText'} onClick={() =>this.openReqResModal('Request', fields.request)}>Request</span></td>
          <td><span className={'clickable blueText'} onClick={() =>this.openReqResModal('Response', fields.response)}>Response</span></td>
          <td>{moment(date).format('MMM Do YYYY, h:mm:ss')}</td>
          <td>{this.calculateApiDuration(fields.metadata.forwardRequestTime, fields.metadata.forwardResponseTime)}sec</td>
          <td>{billing.lineItems.requests.value}</td>
        </tr>
      )
    });
  }

  closeSupportModal = () => {
    this.setState({supportModalVisible: false, supportPayload: null})
  };

  openSupportModal = (supportPayload) => {
    this.setState({supportModalVisible: true, supportPayload })
  };

  render(){
    const apiLogs = this.props.apiLogs;
    const count = this.props.count < 100 ? this.props.count : 100;
    const plan = this.props.plan || {};
    return (
      <Col md={12} lg={12} >
        <Card>
          <SupportModal visible={this.state.supportModalVisible} toggle={this.closeSupportModal} payload={this.state.supportPayload} apiId={plan.apiId}/>
          <ReqResModal visible={this.state.modalVisible} toggle={this.toggleModal} message={this.state.modalMessage} title={this.state.modalTitle}/>
          <CardBody>
            <div className="card__title">
              <h5 className="subhead"> Api Logs </h5>
            </div>
            <Table className="table--bordered" responsive striped>
              <thead>
              <tr>
                <th>Method</th>
                <th>Path</th>
                <th>Support</th>
                <th>Request</th>
                <th>Response</th>
                <th>Date</th>
                <th>Duration</th>
                <th>Cost</th>
              </tr>
              </thead>
              <tbody style={{ height: '626px'}}>
                {this.renderRows(apiLogs)}
              </tbody>
            </Table>
            { count === 0 ? null: <TablePagination changePage={this.changePage} count={count} itemsPerPage={10} currentPage={this.state.currentPage}/>}
          </CardBody>
        </Card>
      </Col>
    )
  }
}

const mapStateToProps = (state) => ({
  plan: state.plans.detailPlan,
  apiLogs: state.apiLogs,
  count: state.apiLogs.count
});

const mapDispatchToProps = (dispatch) => ({
  getApiLogsForPlan: (payload) => dispatch(getApiLogsForPlan(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiLogsTable)
