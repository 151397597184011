import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import UserApisTable from './components/UserApisTable';
import ApiCards from './components/ApiCards';
import {setWhiteLabel} from '../../redux/actions/whiteLabel';

class Dashboard extends React.Component{
  state = {

  };

    redirectIfNotLoggedIn = () => {
    if(!this.props.user && !this.props.userLoading){
      this.props.history.push('/login')
    } else if(this.props.whiteLabelMode){
        this.props.history.push(`/apis/${this.props.whiteLabelApi.uriFriendlyName}`)
    }
  };

  render(){
    this.redirectIfNotLoggedIn();

    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <h3 className="page-title">Dashboard</h3>
          </Col>
        </Row>
        <Row>
          <UserApisTable />
        </Row>
        <Row>
          <ApiCards />
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.users.user,
  userLoading: state.users.loading,
  plan: state.plans.detailPlan,
  planAlert: state.plans.alert,
  whiteLabelMode: state.whiteLabel.whiteLabelMode,
  whiteLabelApi: state.whiteLabel.whiteLabelApi
});

const mapDispatchToProps = (dispatch) => ({
  setWhiteLabel: (payload) => dispatch(setWhiteLabel(payload))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
