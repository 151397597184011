import React from 'react';
import {FormGroup, Input} from 'reactstrap';

import GetRoleOptions from '../Constants/GetRoleOptions';

const OrganizationEditUserSelect = ({ role, showSelectMenu, setSelectedNewRole, usersCurrentRole }) => {

  return (
    <td>
      {showSelectMenu ? (
        <FormGroup row style={{ maxWidth: '175px', marginBottom: 0}}>
          <Input
            id="roleEditSelect"
            name="select"
            type="select"
            bsSize="sm"
            defaultValue={usersCurrentRole}
            onChange={(e) => {
              setSelectedNewRole(e.target.value)
            }}
          >
          <GetRoleOptions userRole={role} />
          </Input>
        </FormGroup>
      ) : usersCurrentRole}
    </td>
  )
}

export default OrganizationEditUserSelect