import React, {Component} from 'react';
import {connect} from 'react-redux';
import {setWhiteLabel} from '../../../../redux/actions/whiteLabel';


class Logo extends Component {

  renderLogo = () => {
    if(this.props.whiteLabelMode){
      return (
          <div className="logoWrapper">
            <a href={'https://opengraph.io'} className="logoWrapper">
              <img src="/LogoThing.png" className="topbar__avatar-img" />
            </a>
            <div className={'logoTextWrapper'}>
              <span className={'logoTitle'}>Open Graph</span>
              <span className={'logoSubtitle'}>A Very Simple Open Graph API</span>
            </div>
          </div>
      )
    } else {
      return (
        <div className="logoWrapper">
          <img src="/furLogo.jpg" className="topbar__avatar-img" />
          <div className={'logoTextWrapper'}>
            <span className={'logoTitle'}>ApiFUR</span>
            <span className={'logoSubtitle'}>An API fur everything.</span>
          </div>
        </div>
      )
    }
  };

  render(){
    if(this.props.whiteLabelLoading) {
      this.props.setWhiteLabel(window.location.hostname);
      return null
    } else {
      return this.renderLogo()
    }

  }
}

const mapStateToProps = (state) => ({
  whiteLabelMode: state.whiteLabel.whiteLabelMode,
  whiteLabelApi: state.whiteLabel.whiteLabelApi,
  whiteLabelLoading: state.whiteLabel.loading
});

const mapDispatchToProps = (dispatch) => ({
  setWhiteLabel: (payload) => dispatch(setWhiteLabel(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Logo)