import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select';
import { Button, CardBody, Col, Form, FormGroup, Input, Label, Row, Tooltip } from 'reactstrap';
import {
  calculateApiCreditCost,
  setLanguageValue,
  handleUrlChange,
  makeDebuggerApiCall,
  toggleAutoProxy,
  toggleForceCacheUpdate,
  toggleFullRender,
  toggleProxy, toggleHasAutoProxy
} from '../../redux/actions/debuggerForm';
import {connect} from 'react-redux';

import './DebugToolWidgetStyle.css'
import { Link } from 'react-router-dom';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

const DebugToolWidget = (props ) => {

  const {
    buttonTitle,
    defaultOptions,
    debuggerForm,
    disabled,
    featuresAllowed,
    handleUrlChange,
    hideAutoProxy,
    hideCacheUpdate,
    hideFullRender,
    hideProxy,
    makeDebuggerApiCall,
    setLanguageValue,
    showUpgradeTooltip,
    tab,
    toggleAutoProxy,
    toggleForceCacheUpdate,
    toggleFullRender,
    toggleHasAutoProxy,
    toggleProxy
  } = props;

  const [ apiCreditCount, setApiCreditCount ] = useState(1);
  const [ buttonDisabled, setButtonDisabled ] = useState(true);
  const [ hasAutoProxy, setHasAutoProxy ] = useState(false);

  const [ showOptions, setShowOptions ] = useState(false);

  const [ autoProxyTooltipTab, setAutoProxyTooltipTab ] = useState(false);

  useEffect(() => {
    if(tab === 'screenshot') {
      setApiCreditCount(20)
    } else {
      const cost = props.calculateApiCreditCost()
      setApiCreditCount(cost)
    }
  }, [tab, defaultOptions]);

  useEffect(() => {
    if(Array.isArray(featuresAllowed) && featuresAllowed.includes('autoProxy')) {
      setHasAutoProxy(true)
    }

    if(Array.isArray(featuresAllowed) && !featuresAllowed.includes('increaseRequest')) {
      toggleHasAutoProxy(false)
    }
  }, [featuresAllowed]);


  const disableOption = (option) => {
    if( Array.isArray(featuresAllowed) && !featuresAllowed.includes('increaseRequest') && (option === 'use_superior' || option === 'use_premium')) {
      return true
    } else {
      return false
    }
  }

  const toggleOptions = () => {
    setShowOptions(!showOptions)
  }

  const setAutoProxyTooltipOpen = () => {
    setAutoProxyTooltipTab(!autoProxyTooltipTab)

  }

  useEffect(() => {
    if(disabled || !defaultOptions.url || debuggerForm.loading) {
      setButtonDisabled(true)
    } else {
      setButtonDisabled(false)
    }
  }, [defaultOptions.url, debuggerForm.loading]);

  const renderAutoProxyOption = () => {
    if(hideAutoProxy) return null
    if(Array.isArray(featuresAllowed) && featuresAllowed.includes('increaseRequest') && !featuresAllowed.includes('autoProxy')) {
      return (
        <div className="form__form-group-field checkbox--label_container">
          <input
            id={`auto_proxy_${tab}`}
            className={'checkbox-btn checkbox-btn__checkbox-custom'}
            type="checkbox"
            onChange={toggleAutoProxy}
            checked={defaultOptions.autoProxy}
          />
          <p className={'checkbox-btn__label'}>Auto Proxy - Turn on automatic proxy handling for manual configuration.</p>
        </div>
      )
    } else if (hasAutoProxy) {
      return (
        <div className="form__form-group-field checkbox--label_container">
          <input
            id={`auto_proxy_${tab}`}
            className={'checkbox-btn checkbox-btn__checkbox-custom'}
            type="checkbox"
            onChange={toggleAutoProxy}
            checked={!defaultOptions.autoProxy}
          />
          <p className={'checkbox-btn__label'}> Disable Auto Proxy - Turn off automatic proxy handling for manual configuration. </p>
        </div>
      )
    } else {
      return (
        <div className="form__form-group-field checkbox--label_container">
          <input
            id={`auto_proxy_${tab}`}
            className={'checkbox-btn checkbox-btn__checkbox-custom'}
            disabled={true}
            type="checkbox"
            onChange={toggleAutoProxy}
            checked={defaultOptions.autoProxy}
          />
          <p className={'checkbox-btn__label'}>Auto Proxy - Turn on automatic proxy handling for manual configuration.</p>
        </div>
      )
    }
  }



  return (
    <CardBody style={{ borderRadius: '20px', margin: '10px 0', height: 'min-content'}}>
      <Row>
        <Col md={6}>
          <h3 style={{ fontWeight: 700 }}>OpenGraph Debug Widget</h3>
        </Col>
      </Row>
      <Form onSubmit={(e) => makeDebuggerApiCall(e)}>
        <FormGroup className="form__form-group">
          <div className="form__debugger-group">
            <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
              <input disabled={disabled}  value={defaultOptions.url} onChange={(e) => handleUrlChange(e.target.value)} placeholder={'http://somesite.com'} type={'text'} style={{ border: '2px solid #F4F4F4', borderRadius: '8px', padding: '10px' }}  className={`ogDebugWidgetUriInput ${disabled ? 'lightGreyBackground' : ''}`}/>
            </div>
            <Button disabled={buttonDisabled} type="submit" className={`btn btn-success form__debugger-group-btn ${!buttonDisabled ? 'whiteText' : 'darkGreyText'}`} onClick={(e) => makeDebuggerApiCall(e)}> Submit |  <span style={{ color: !buttonDisabled ? '#D1F1DF' : '#1b1e21'}}> {apiCreditCount} Requests</span></Button>
          </div>
        </FormGroup>
      </Form>
      <Col md={2}>
        <Row className={'align-items-center'}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px', cursor: 'pointer'}} onClick={toggleOptions}>
            <p>{showOptions ? 'Hide Options' : 'Show Options'}</p>
            { showOptions ? <IoIosArrowUp style={{ marginLeft: '5px' }} size={14} /> : <IoIosArrowDown size={14} style={{ marginLeft: '5px' }} /> }
          </div>
        </Row>
      </Col>
      <Row>
        <Col md={8}>
          <div className={`debug--tool_widget_options ${showOptions ? 'show' : ''}`}>
            <div className="form__form-group-input-wrap width25">
              <span className="form__form-group-label">Language</span>
              <Select
                name="language"
                value={defaultOptions.language}
                onChange={setLanguageValue}
                options={[
                  { value: 'auto', label: 'Auto - use my browser setting' },
                  { value: 'en', label: 'en' },
                  { value: 'fr', label: 'fr' },
                  { value: 'de', label: 'de' },
                ]}
                clearable={false}
                className="form__form-group-select"
              />
            </div>
            <br/>
            {hideFullRender ? null : <div className="form__form-group-field checkbox--label_container">
              <input className={'checkbox-btn checkbox-btn__checkbox-custom'} type="checkbox" checked={defaultOptions.fullRender.active} onChange={toggleFullRender}/>
              <p className={'checkbox-btn__label'}> Full Render - Execute JS on page before reading tags (Slower: ~5-10s). <span className={'debugger-credit--amount'}>10 Requests</span> </p>
            </div>}
            {hideCacheUpdate ? null : <div className="form__form-group-field checkbox--label_container">
              <input className={'checkbox-btn checkbox-btn__checkbox-custom'} type="checkbox"
                     onChange={toggleForceCacheUpdate} checked={defaultOptions.forceCacheUpdate}/>
              <p className={'checkbox-btn__label'}> Force Cache Update - Always pull fresh content from target. </p>
            </div>}
            { renderAutoProxyOption() }
            {Array.isArray(featuresAllowed) && !featuresAllowed.includes('increaseRequest') && !hideAutoProxy ? <Tooltip
              target={`auto_proxy_${tab}`}
              isOpen={autoProxyTooltipTab === tab}
              toggle={() => setAutoProxyTooltipOpen(tab)}>
              The Auto Proxy is only available in newer plans. Upgrade now to unlock!
            </Tooltip> : null }
            <br/>
            { hideProxy ? null :
              <>
                <p>Proxy Settings</p>
                <FormGroup
                  row
                  tag="fieldset"
                  onChange={(e) => toggleProxy(e.target.value)}
                  defaultValue={defaultOptions.proxyChoice}
                >
                  <Col>
                    {defaultOptions.proxyOptions.map((choice, i) => {
                      return (
                        <FormGroup check key={i + 'choice.value'}>
                          <Input
                            name="proxy"
                            id={choice.value + '_' + tab}
                            type="radio"
                            value={choice.value}
                            checked={defaultOptions.proxyChoice === choice.value}
                            disabled={() => disableOption(choice.value)}
                          />
                          {' '}
                          <Label check>
                            <p>{choice.name} <span className={'debugger-credit--amount'}>{choice.cost} Requests</span></p>
                          </Label>
                        </FormGroup>
                      )
                    })}
                  </Col>
                </FormGroup>
              </>
            }
            <p>Visit the <Link style={{ color: '#2CBD6B'}} to={'/api-playground'}>API Playground</Link> for more!</p>
          </div>
        </Col>
        <Col md={4}>
          {debuggerForm.loading ? (
            <div className={'loading centerText width100'}>
              <br/>
              <div className="load__icon-wrap">
                <svg className="load__icon">
                  <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"/>
                </svg>
              </div>
              <br/>
            </div>) : ''}
        </Col>
      </Row>
    </CardBody>
  )
}

const mapStateToProps = (state) => ({
  defaultOptions: state.debuggerForm.defaultOptions,
  debuggerForm: state.debuggerForm,
  featuresAllowed: state.organization.currentOrg.tier.data.currentTier.featuresAllowed,
});

const mapDispatchToProps = (dispatch) => ({
  setLanguageValue: (payload) => dispatch(setLanguageValue(payload)),
  toggleProxy: (payload) => dispatch(toggleProxy(payload)),
  toggleFullRender: (payload) => dispatch(toggleFullRender(payload)),
  toggleForceCacheUpdate: () => dispatch(toggleForceCacheUpdate()),
  toggleAutoProxy: () => dispatch(toggleAutoProxy()),
  toggleHasAutoProxy: (payload) => dispatch(toggleHasAutoProxy(payload)),
  handleUrlChange: (payload) => dispatch(handleUrlChange(payload)),
  makeDebuggerApiCall: (payload) => dispatch(makeDebuggerApiCall(payload)),
  calculateApiCreditCost: () => dispatch(calculateApiCreditCost()),

})

export default connect(mapStateToProps, mapDispatchToProps)(DebugToolWidget)
