import React, {Component} from 'react';
import {Col, Container, Row} from 'reactstrap';
import ApisTable from './components/ApisTable';

export default class Index extends Component {
  render(){
    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <ApisTable/>
          </Col>
        </Row>
      </Container>
    )
  }
}