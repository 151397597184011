import ReactGA4 from 'react-ga4';
const InitializeGoogleAnalytics = () => {
  ReactGA4.initialize('G-QVGQCGBC59');
};

const SetGoogleAnalyticsUserId = (userId) => {
    ReactGA4.set({ userId });
}
const TrackGoogleAnalyticsEvent = (
  eventName,
  category,
  action,
  label,
  value,
) => {

  let eventParams = {
    category,
    action,
    label,
  }

  if(value) {
    eventParams.value = value
  }

  // Send GA4 Event
  ReactGA4.event({
    ...eventParams
  });
};

export default InitializeGoogleAnalytics;
export { InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent, SetGoogleAnalyticsUserId };
