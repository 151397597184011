import React from 'react';
import {CardBody, Container} from 'reactstrap';

const ScreenshotPreview = ({ imgSrc }) => {
    return (
      <div>
        <h1>Screenshot Preview</h1>
        <hr />
        <Container>
          <CardBody className={'mx-auto'} style={{ width: 'fit-content'}}>
            <img src={imgSrc} alt={'screenshot-preview'}/>
          </CardBody>
        </Container>
      </div>
    )
}

export default ScreenshotPreview;
