// export const apiBase = process.env.NODE_ENV === 'production' ?
//     window.REACT_APP_API_URL ? window.REACT_APP_API_URL :
//         process.env.REACT_APP_API_URL : 'http://localhost:3777';
export const adminEmails = ['cwooley89@gmail.com', 'charles@securecoders.com', 'justin@securecoders.com'];
// export const opengraphApiBase = process.env.NODE_ENV === 'production' ?
//     window.REACT_APP_OPENGRAPH_API_BASE ? window.REACT_APP_OPENGRAPH_API_BASE :
//         process.env.REACT_APP_OPENGRAPH_API_BASE : 'http://localhost:3778/opengraph/api/1.1/';

export const stripeEnv = process.env.REACT_APP_STRIPE_ENV || window.REACT_APP_STRIPE_ENV || 'fallback';
export const apiBase = process.env.REACT_APP_API_URL || window.REACT_APP_API_URL || 'https://dashboard-api-test.opengraph.io';
export const opengraphApiBase = process.env.REACT_APP_OPENGRAPH_API_BASE || window.REACT_APP_OPENGRAPH_API_BASE || 'http://localhost:3778/opengraph/api/1.1/';

console.log('Configured API Constants');
console.table({apiBase, opengraphApiBase, stripeEnv});