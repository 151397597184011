import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button } from 'reactstrap';
import validateLogin from './validateLogin';
import {connect} from 'react-redux';
import {loginUser, requestPasswordResetEmail } from '../../../redux/actions/users';
// import ReactGA from 'react-ga';

const renderField = ({
                       input, placeholder, type, meta: { touched, error },
                     }) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

class LogInForm extends PureComponent {

  constructor() {
    super();
    this.state = {
      showPassword: false,
    };
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  signInClicked = (e) => {
    e.preventDefault();
    if (this.props.formState.syncErrors){
    } else {
      // ReactGA.event({
      //   category: 'usage',
      //   action: 'login',
      //   label: 'email'
      // });
      this.props.loginUser()
    }
  };

  passwordResetClicked = () => {
    this.props.switchToPasswordResetForm();
    this.props.history.push('resetpassword')
  };

  render() {
    const { handleSubmit } = this.props;

    return (
      <form className="form">
        <div className="form__form-group">
          <span className="form__form-group-label">Email</span>
          <div className="form__form-group-field">
            <Field
              name="email"
              component={renderField}
              type="text"
              placeholder="Email"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Password <span className="account__forgot-password">
            { this.props.modal ? <span className="resendEmailLink clickable" onClick={this.props.switchToPasswordResetFormModal}>Forgot Password</span> : <span className="resendEmailLink clickable" onClick={this.passwordResetClicked}>Forgot Password</span>}
          </span>
          </span>
          <div className="form__form-group-field">
            <Field
              name="password"
              component={renderField}
              type={this.state.showPassword ? 'text' : 'password'}
              placeholder="Password"
            />
          </div>

        </div>
        <Button type="submit" className="btn btn-primary account__btn account__btn--small whiteText" onClick={this.signInClicked}>Sign In</Button>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  formState: state.form.log_in_form
});

const mapDispatchToProps = (dispatch) => ({
  loginUser: (payload) => dispatch(loginUser(payload)),
  requestPasswordResetEmail: ()=> dispatch(requestPasswordResetEmail())
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'log_in_form',
  validate: validateLogin
})(LogInForm));
