import React, { PureComponent } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import {connect} from 'react-redux';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';
import {logoutUser} from '../../../redux/actions/users';
import {withRouter} from 'react-router-dom';

class TopbarProfile extends PureComponent {
  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };


  render() {
    const user = this.props.user || {};
    const nameString = user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : `${user.email}`;

    if(user.id){
      return (
        <div className="topbar__profile">
          <button className="topbar__avatar" onClick={this.toggle}>
          {user.profilePhotoUri &&
          <img className="topbar__avatar-img" src={user.profilePhotoUri} alt="avatar" />
          }
          <p className="topbar__avatar-name">{nameString}</p>
          <DownIcon className="topbar__icon" />
          </button>
          {this.state.collapse && <button className="topbar__back" onClick={this.toggle} />}
          <Collapse isOpen={this.state.collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
          <div className="topbar__menu-divider" />
          <span className={'topbar__link'} onClick={this.props.logout}>Logout</span>
          </div>
          </Collapse>
        </div>
      )
    } else {
      return (
        <div className="topbar__profile cursorAuto">
          <button className="topbar__avatar cursorAuto" onClick={this.toggle}>
            <p className="topbar__avatar-name ogTextColor clickable" onClick={()=>this.props.history.push('/login')}>Sign in</p>
            <p className="topbar__avatar-name">|</p>
            <p className="topbar__avatar-name ogTextColor clickable" onClick={()=>this.props.history.push('/register')}>Sign Up</p>
          </button>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.users.user
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logoutUser())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopbarProfile))