import {
  API_GET_SUCCESS,
  CLEAR_DETAIL_API,
  GET_API_BY_FRIENDLY_NAME_FAIL,
  GET_API_BY_FRIENDLY_NAME_SUCCESS,
  GET_APIS_FAIL,
  GET_APIS_SUCCESS,
  SET_DETAIL_ADMIN_API
} from '../actionTypes';


const initialState = {apis: [], detailApi: {}, adminDetailApi: {}, alert: null, error: null};

const apis = (state = initialState, action) => {
  switch (action.type) {
    case GET_APIS_SUCCESS:
      return {...state, apis: action.payload};
    case GET_API_BY_FRIENDLY_NAME_SUCCESS:
      return {...state, detailApi: action.payload, error: null};
    case GET_API_BY_FRIENDLY_NAME_FAIL:
      return {...state, error: action.payload};
    case CLEAR_DETAIL_API:
      return {...state, detailApi: {}, error: null};
    case SET_DETAIL_ADMIN_API:
      return {...state, adminDetailApi: (action.payload || {})};
    case API_GET_SUCCESS:
      return {...state, adminDetailApi: (action.payload || {})};
    default:
      return state;
  }
};


export default apis;
