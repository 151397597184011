import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getApis} from '../../../redux/actions/apis';
import ApiCard from './ApiCard';
import { Col, Container, Row } from 'reactstrap';

class ApiCards extends Component {
  componentDidMount(){
    this.props.getApis();
  };

  renderApiCards(apis){
    return apis.map((api)=>{
      return <ApiCard api={api} key={api.id}/>
    })
  }

  render(){
    const apis = this.props.apis || [];
    return (
      <Col>
        <Row>
          {this.renderApiCards(apis)}
        </Row>
      </Col>
    )
  }
}

const mapStateToProps = (state) => ({
  apis: state.apis.apis
});

const mapDispatchToProps = (dispatch) => ({
  getApis: () => dispatch(getApis()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiCards)