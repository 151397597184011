import React, {useState, useEffect} from 'react';
import {Button, Card, CardBody, Col} from 'reactstrap';
import StripeChangeCardModal from './StripeChangeCardModal';
import {connect} from 'react-redux';
import {getOrganizationStripeInfo} from '../../../redux/actions/organization';

const BillingInformation = (props) => {
  const {clearStripeError, plan, planAlert, currentOrg, stripeInfo} = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState();

  useEffect(() => {
    getOrganizationStripeInfo(currentOrg.id)
  }, [currentOrg]);

  useEffect(() => {
    if(stripeInfo.data && stripeInfo.data.invoice_settings && stripeInfo.data.invoice_settings.default_payment_method) {
      setPaymentMethod(stripeInfo.data.invoice_settings.default_payment_method)
    }
  }, [stripeInfo]);

  const toggleModal = () => {
    setModalOpen(!modalOpen)
    clearStripeError()
  };

  const clearModalIfPlanAlert = () => {
    if(planAlert && modalOpen){
      setModalOpen(false);
    }
  }

  const renderLastFourForCard = () => {
    if(paymentMethod){
      return `**** **** **** ${paymentMethod.card.last4}`
    } else if (props.source && props.source.source && props.source.source.last4) {
      return `**** **** **** ${props.source.source.last4}`
    } else {
      return '**** **** **** ****'
    }
  }

  const renderExpForCard = () => {
    if(paymentMethod){
      return `${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}`
    } else if (props.source && props.source.source && props.source.source.exp_month && props.source.source.exp_year) {
      return `${props.source.source.exp_month}/${props.source.source.exp_year}`
    } else {
      return '00/00'
    }
  }

  // not sure what the plan alert is for / or if this is still used / needed
  clearModalIfPlanAlert()
  return (
    <Col md={12} xl={6} sm={12}>
      <StripeChangeCardModal toggle={toggleModal} visible={modalOpen} />
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="subhead"> Payment Information </h5>
          </div>
          <form className="form form--vertical payment--form">
            <div className="form__form-group">
              <span className="form__form-group-label">Card Ending</span>
              <div className="form__form-group-field">
                <input
                  name="defaultInput"
                  type="text"
                  placeholder={renderLastFourForCard()}
                  disabled
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Expires</span>
              <div className="form__form-group-field">
                <input
                  name="disableInput"
                  type="text"
                  placeholder={renderExpForCard()}
                  disabled
                />
              </div>
            </div>
          </form>
          <div className={'flexSpaceEvenly'}>
            <Button color={'primary'} onClick={toggleModal}>Change Card</Button>
          </div>
        </CardBody>
      </Card>
    </Col>
  )
}

const mapStateToProps = (state) => ({
  stripeInfo: state.organization.currentOrg.stripeInfo,
  currentOrg: state.organization.currentOrg.information
});

const mapDispatchToProps = (dispatch) => ({
  getOrganizationStripeInfo: (id) => dispatch(getOrganizationStripeInfo(id))
});


export default connect(mapStateToProps, mapDispatchToProps)(BillingInformation)

