import { apiClient } from '../../constants/clients';
import {
  ADMIN_GET_ALL_ORGS,
  ADMIN_GET_ALL_ORGS_FAIL,
  ADMIN_GET_ALL_ORGS_SUCCESS,
  ADMIN_SET_SPY_ORG
} from '../actionTypes';

export function getAllOrganizations(payload){
  payload = payload || {};
  const itemsPerPage = 15;
  let client = apiClient.getOrganizations();

  //ServerSidePaging
  if (typeof(payload.page) === 'number' && itemsPerPage){
    const offset = payload.page * itemsPerPage;
    client = client.offset(offset).limit(itemsPerPage)
  }

  // client = client.setSortFields(['createdAt']);
  // client = client.setOrderByDesc();

  let queryParams = {};
  if(payload.orgName && payload.orgName.length > 0){
    queryParams['orgName'] = `%${payload.orgName}%`
  }

  if(payload.apiKey && payload.apiKey.length > 0){
    queryParams['apiKey'] =  `%${payload.apiKey}%`
  };

  if(payload.onlyPaidUsers) {
    queryParams['onlyPaid'] = payload.onlyPaidUsers
  }

client.setQueryParams(queryParams)

  return (dispatch) => {
    dispatch({type: ADMIN_GET_ALL_ORGS});
      client.exec((err, result, fullResp) => {
        if(err){
          dispatch({type: ADMIN_GET_ALL_ORGS_FAIL, payload: err})
        } else {
          dispatch({type: ADMIN_GET_ALL_ORGS_SUCCESS, payload: {organizations: result, total: fullResp.body.total}})
        }
      })
  }
}

export function setSpyOrg(organization){
  return {type: ADMIN_SET_SPY_ORG, payload: organization}
}