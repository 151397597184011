import React, {Component} from 'react';
import {Col, Card, CardBody, Table} from 'reactstrap';
import {connect} from 'react-redux';
import {getPlanPeriodsForPlan} from '../../../redux/actions/planPeriods';
import TablePagination from '../../../shared/TablePagination';
import moment from 'moment';

class PlanPeriodsTable extends Component {

  state = {
    currentPage: 0,
  };

  changePage = (page) => {
    this.setState({currentPage: page}, () => {
      this.props.getPlanPeriodsForPlan({planId: this.props.plan.id, page: this.state.currentPage})
    })
  };

  componentDidMount(){
    this.props.getPlanPeriodsForPlan({planId: this.props.plan.id, page: 0})
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.plan && this.props.plan){
      if(nextProps.plan.id !== this.props.plan.id){
        this.props.getPlanPeriodsForPlan({planId: nextProps.plan.id, page: 0})
      }
    }
  }

  renderRows(planPeriods){
    if(planPeriods.length === 0){
      return (
        <tr>
          <td>No Invoices Yet...</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      )
    }
    return planPeriods.map((planPeriod) => {
      return (
        <tr key={planPeriod.id}>
          <td>{moment(planPeriod.startDate).format('MMM Do YYYY')}</td>
          <td>{moment(planPeriod.endDate).format('MMM Do YYYY')}</td>
          <td>{planPeriod.invoicePdfUrl ? <a href={planPeriod.invoicePdfUrl}>Invoice</a> : ''}</td>
        </tr>
      )
    });
  }

  render(){
    const planPeriods = this.props.planPeriods || [];
    return (
      <Col md={12} lg={12} >
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="subhead"> Invoices</h5>
            </div>
            <Table className="table--bordered" responsive striped>
              <thead>
              <tr>
                <th>Beginning</th>
                <th>Ending</th>
                <th>Invoice</th>
              </tr>
              </thead>
              <tbody>
              {this.renderRows(planPeriods)}
              </tbody>
            </Table>
            <TablePagination changePage={this.changePage} count={this.props.count} itemsPerPage={5} currentPage={this.state.currentPage}/>
          </CardBody>
        </Card>
      </Col>
    )
  }
}

const mapStateToProps = (state) => ({
  planPeriods: state.planPeriods.planPeriods,
  count: state.planPeriods.count,
  loading: state.planPeriods.loading,
  plan: state.plans.detailPlan,
});

const mapDispatchToProps = (dispatch) => ({
  getPlanPeriodsForPlan: (payload) => dispatch(getPlanPeriodsForPlan(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanPeriodsTable)