import { apiClient } from '../../constants/clients';
import {
  GET_API_LOGS_FOR_PLAN,
  GET_API_LOGS_FOR_PLAN_FAIL,
  GET_API_LOGS_FOR_PLAN_SUCCESS,
  GET_LATEST_STRIPE_SUBSCRIPTION_DETAILS,
  GET_LATEST_STRIPE_SUBSCRIPTION_DETAILS_FAIL,
  GET_LATEST_STRIPE_SUBSCRIPTION_DETAILS_SUCCESS,
  GET_TOTAL_API_CREDITS_USED_FOR_ORGANIZATION,
  GET_TOTAL_API_CREDITS_USED_FOR_ORGANIZATION_FAIL, GET_TOTAL_API_CREDITS_USED_FOR_ORGANIZATION_SUCCESS
} from '../actionTypes';
import moment from 'moment';

export function getApiLogsForPlan(payload){
  payload = payload || {};
  const itemsPerPage = 10;

  let client = apiClient.getApiLogsForPlan(payload.planId);

  //ServerSidePaging
  if (typeof(payload.page) === 'number' && itemsPerPage){
    const offset = payload.page * itemsPerPage;
    client = client.offset(offset).limit(itemsPerPage)
  }

  // client = client.setSortFields(['createdAt']);
  // client = client.setOrderByDesc();

  // //Search
  // const searchTerm = payload.searchTerm || '';
  // if(searchTerm.length > 0){
  //   client = client.setFilter('name=\'%' + payload.searchTerm + '%\'')
  // }

  return dispatch => {
    dispatch({type: GET_API_LOGS_FOR_PLAN});
    client.exec((err, result, fullResp)=> {
      if(err){
        dispatch({type:GET_API_LOGS_FOR_PLAN_FAIL, payload: err})
      } else {
        let payload = {
          apiLogs: result.hits.hits,
          count: result.hits.total.value,
        };
        dispatch({type:GET_API_LOGS_FOR_PLAN_SUCCESS, payload})
      }
    })
  }
}


export function getTotalApiCallsForUser() {

  return (dispatch, getState) => {

    const startDate = moment.unix(getState().users.currentBillingCycle.start).format('YYYY-MM-DD')
    const endDate = moment.unix(getState().users.currentBillingCycle.end).format('YYYY-MM-DD')
    const userOrg = getState().users.user.defaultOrgId;

    dispatch({type: GET_TOTAL_API_CREDITS_USED_FOR_ORGANIZATION});
    apiClient.getTotalApiCreditsUsedForOrganization(userOrg, startDate, endDate)
      .exec((err, result) => {
        if(err){
          dispatch({type: GET_TOTAL_API_CREDITS_USED_FOR_ORGANIZATION_FAIL, payload: err})
        } else {
          dispatch({type: GET_TOTAL_API_CREDITS_USED_FOR_ORGANIZATION_SUCCESS, payload: result})
        }
      })
  }
}
