import {
  GET_PLAN_PERIODS_FOR_PLAN_SUCCESS
} from '../actionTypes';

const initialState = {planPeriods: [], count: 0, alert: null, error: null, loading: true};

const planPeriods = (state = initialState, action) => {
  switch (action.type) {
    case GET_PLAN_PERIODS_FOR_PLAN_SUCCESS:
      return {...state, planPeriods: action.payload.planPeriods, count: action.payload.count, loading: false, error: null};
    default:
      return state;
  }
};


export default planPeriods;
