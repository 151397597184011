import React, {Component} from 'react';

export default class error extends Component {
  componentDidMount() {
    // make error
    const i = 0;
    i = 'Bouviers Rock';
  }

  render(){
    return (
          <div>Error Page</div>
      )
  }
}