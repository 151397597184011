import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';

import './TabsStyles.css';

const Tabs = ({active, onTabChange}) => {

  return (
    <Nav
      className={'dashboard--tabs_container'}
      pills
    >
      <NavItem>
        <NavLink
          className={`dashboard--tab ${ active === 'dashboard' ? 'active-tab' : '' }`}
          onClick={() => onTabChange('dashboard')}
        >
          Dashboard
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={`dashboard--tab ${ active === 'api-keys' ? 'active-tab' : '' }`}
          onClick={() => onTabChange('api-keys')}
        >
          API Keys
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={`dashboard--tab ${ active === 'api-logs' ? 'active-tab' : '' }`}
          onClick={() => onTabChange('api-logs')}
        >
          API Logs
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={`dashboard--tab ${ active === 'api-usage' ? 'active-tab' : ''}`}
          onClick={() => onTabChange('api-usage')}
        >
          API Usage
        </NavLink>
      </NavItem>
    </Nav>
  )
}

export default Tabs;
