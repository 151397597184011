import React, {useState, useEffect} from 'react';
import {Button, InputGroup, Input, Modal} from 'reactstrap';

const DeleteUserForm = ({deleteUser}) => {
    const [isOpen, setIsOpen] = useState(false)
    const [statement] = useState('DELETE MY ACCOUNT')
    const [userDeleteStatement, setUserDeleteStatement] = useState('')
    const [disableDeleteButton, setDeleteButton] = useState(true)

    const handleOpen = () => {
        setIsOpen(true)
    }
    const handleClose = () => {
        setIsOpen(false)
    }

    const onChange = (e) => {
        setUserDeleteStatement(e.target.value)
    }

    useEffect(() => {
        //Check to see if the user input is equal to the delete statement.
        userDeleteStatement === statement ? setDeleteButton(false) : setDeleteButton(true)
    }, [userDeleteStatement])

    return (

        <React.Fragment>
            <Modal isOpen={isOpen}>
                <Button style={{alignSelf: 'flex-end'}} close={'true'} onClick={() => handleClose()}/>
                <p>Typing <span style={{color: 'red'}}>"{statement}"</span> in the box below will
                    permanently delete your account and will remove you from all organizations you are a part of.
                    Any organization owned SOLELY by you will also be deleted. This CANNOT be undone.</p>
                <Input style={{margin: '5px 0'}} onChange={(e) => onChange(e)}/>
                <span><Button color={'primary'} onClick={() => handleClose()}>Cancel</Button>
                <Button onClick={() => deleteUser()} disabled={disableDeleteButton}
                        color={'danger'}>DELETE</Button></span>
            </Modal>
            <Button color={'danger'} onClick={() => handleOpen()}>Delete
                Account< /Button>
        </React.Fragment>

    )
}

export default DeleteUserForm


