import React, { useState } from 'react'

import OrganizationTableHeader from './OrganizationTableHeader';

import {Table, Tooltip} from 'reactstrap';
import { FaRegCircleCheck, FaRegCircleXmark } from 'react-icons/fa6';
import moment from 'moment';

const OrganizationUserInvitationsTable = (props) => {
  const { invitations, handleUpdateInvite } = props;

  const [ acceptTooltipOpenIndex, setAcceptTooltipOpenIndex ] = useState(null);
  const [ declineTooltipOpenIndex, setDeclineTooltipOpenIndex ] = useState(null);

  const headers = ['Organization', 'Role', 'Invitation Sent']

  const acceptButtonStyle = {
    color: 'green',
    marginRight: '5px'
  }

  const declineButtonStyle = {
    color: 'red',
  }

  const toggleAccept = (index) => setAcceptTooltipOpenIndex(acceptTooltipOpenIndex !== index ? index : null);
  const toggleDecline = (index) => setDeclineTooltipOpenIndex(declineTooltipOpenIndex !== index ? index : null)

  const renderRows = (invitations) => {
    return (
      <tbody>
      {invitations.map((invitation, index) => (
          <tr key={`invitation-${index}`}>
            <td>{invitation.organization.name}</td>
            <td>
              {invitation.role}
            </td>
            <td>
              {moment(invitation.createdAt).format('MMMM Do YYYY')}
            </td>
            <td>
              <Tooltip
                autohide={true}
                target={`accept-${index}`}
                isOpen={acceptTooltipOpenIndex === index}
                toggle={() => toggleAccept(index)}
              >
                Accept
              </Tooltip>
              <Tooltip
                autohide={true}
                target={`decline-${index}`}
                isOpen={declineTooltipOpenIndex === index}
                toggle={() => toggleDecline(index)}
              >
                Decline
              </Tooltip>
              <FaRegCircleCheck id={`accept-${index}`} style={acceptButtonStyle} size={20} onClick={() => handleUpdateInvite(invitation.id, 'accepted')} />
              <FaRegCircleXmark id={`decline-${index}`} style={declineButtonStyle} size={20} onClick={() => handleUpdateInvite(invitation.id, 'declined')} />
            </td>
          </tr>
        )
      )}
      </tbody>
    )
  }


  return (
    <Table
      borderless
      hover
      responsive
      size="sm"
      striped
    >

      <OrganizationTableHeader headers={headers} />
      {renderRows(invitations)}
    </Table>
  )
}

export default OrganizationUserInvitationsTable