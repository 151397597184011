import React from 'react';
import { Form, FormGroup, Input, Label, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { setEmbedOrientation, setAlwaysOgFrame } from '../../../../redux/actions/debuggerForm';

const EmbedForm = ( props ) => {

    const {  embedOptions, setEmbedOrientation, setAlwaysOgFrame } = props;

    console.log('embedOptions', embedOptions);

    return (
        <Form>
            <FormGroup md={12} xl={12} className="debugger-from--wrapper">
                <div className="form__form-group-field checkbox--label_container">
                    <input className={'checkbox-btn checkbox-btn__checkbox-custom'} type="checkbox"
                           onChange={setEmbedOrientation} checked={embedOptions.orientation === 'horizontal'}/>
                    <p className={'checkbox-btn__label'}> Make it a Horizontal card? </p>
                </div>
                <div className="form__form-group-field checkbox--label_container">
                    <input className={'checkbox-btn checkbox-btn__checkbox-custom'} type="checkbox"
                           onChange={setAlwaysOgFrame} checked={embedOptions.alwaysOgFrame}/>
                    <p className={'checkbox-btn__label'}> Always use OG Frame? </p>
                </div>
            </FormGroup>
        </Form>
    )
};

const mapStateToProps = (state) => ({
    embedOptions: state.debuggerForm.oembed.options,
})

const mapDispatchToProps = (dispatch) => ({
    setEmbedOrientation: (size) => dispatch(setEmbedOrientation(size)),
    setAlwaysOgFrame: () => dispatch(setAlwaysOgFrame()),
})


export default  connect(mapStateToProps, mapDispatchToProps)(EmbedForm)

