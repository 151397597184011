import React from 'react';
import {Col, Button} from 'reactstrap';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';

export const OembedResults = ({results, playgroundRef}) => {

    const copyToClipboard = () => {
        navigator.clipboard.writeText(results.html)
            .catch((err) => {
                console.error('Failed to copy: ', err);
            });
    };


    return (
        <Col md={12} xl={12} >
            <h3 style={{ fontSize: '40px', fontWeight: '700'}} ref={playgroundRef}>Results</h3>
            <div style={{display: 'flex', padding: 10, width: '100%'}}>
                <div style={{width: '50%', padding: 5}}>
                    <SyntaxHighlighter wrapLongLines={true} language={'html'} >
                        {results.html}
                    </SyntaxHighlighter>
                    <Button color="primary" onClick={copyToClipboard} style={{ marginTop: '10px' }}>
                        Copy Code
                    </Button>
                </div>

                <div style={{width:'50%'}} dangerouslySetInnerHTML={{__html: results.html}}></div>
            </div>
        </Col>
    )
}