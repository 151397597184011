import React, {Component} from 'react';

import {connect} from 'react-redux';
import {Col, Container, Row} from 'reactstrap';
import AllOrganizationsTable from './components/AllOrganizationsTable';

class AdminUsersDashboard extends Component {

  // componentDidMount() {
  //   if(this.props.)
  //     }

  // redirectIfNotAdmin = () => {
  //   if(!this.props.user && !this.props.userLoading){
  //     this.props.history.push('/login')
  //   } else {
  //     if(!adminEmails.includes(this.props.user.email)){
  //       this.props.history.push('/dashboard')
  //     }
  //   }
  // };



  render(){
    return(
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <AllOrganizationsTable/>
          </Col>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.users.user,
  userLoading: state.users.loading,
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsersDashboard)
