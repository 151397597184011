import React, {Component} from 'react';
import {Col, Card, CardBody, CardHeader, CardTitle, Collapse, Table, Row} from 'reactstrap';


export default class ProductCard extends Component {

  renderRows(product){
    let tableKeys = ['brand', 'name', 'description', 'gtin13', 'sku', 'type'];
    return Object.keys(product).map((key, index) => {
      if(tableKeys.includes(key)){
        return (
          <tr key={`${key}-${index}`}>
            <td><strong>{key}</strong></td>
            <td>{product[key]}</td>
          </tr>
        )
      }
    })
  }

  renderImages = (imagesArr) => {
    return imagesArr.map((imgSrc, index) => {
      if(Array.isArray(imgSrc)){
        return imgSrc.map((img, idx) => {
          return (
            <Col xl="2" md="2" sm="12" key={`product-img-${idx}`}>
              <a href={img} className="irc_mi" target={'_blank'}>
                <img alt={'product'} src={img} />
              </a>
            </Col>
          )
        })
      } else {
        return (
          <Col xl="4" md="4" key={`product-img-${index}`}>
            <a href={imgSrc} className="irc_mi" target={'_blank'} key={`${imgSrc}-${index}`}>
              <img alt={'product'} src={imgSrc} />
            </a>
          </Col>
        )
      }
    })
  };

  renderOffers(offers){
    // console.log(offers);
    return offers.map((offer, index) => {
      return (
        <Col xl="6" lg="8" md="10" sm="12" key={`product-offer-${index}`} >
          <Card >
            <CardBody className={'offerCard'}>
              {
                Object.keys(offer).map((key, idx) => {
                  if(Array.isArray(offer[key]) ) {
                    Object.keys(offer[key]).map((subKey, subIdx) => {
                      return (
                        <div style={{display: 'flex', justifyContent: 'space-between'}} key={`${subIdx}-offer-key`}>
                          <strong>{subKey}:</strong>
                          <span>{offer[key][subKey]}</span>
                        </div>
                      )
                    })
                  } else {
                    return (
                      <div style={{display: 'flex', justifyContent: 'space-between'}} key={`${idx}-offer-key`}>
                        <strong>{key}:</strong>
                        <span>{offer[key]}</span>
                      </div>
                    )
                  }
                })
              }
            </CardBody>
          </Card>
        </Col>
      )
    })
  }

  render(){
    const {product} = this.props;
    const images = product.images || [];
    const offers = product.offers || [];

    return (
      <Card >
        <CardBody >
          <h3>{this.props.title}</h3>
          <br />

          <Table borderless striped={false} >
            <tbody>
              {this.renderRows(product)}
            </tbody>
          </Table>
          <br />
          {
            images.length > 0 &&
              <div>
                <h4>Images</h4>
                <br />
                <Row>
                  {this.renderImages(product.images)}
                </Row>
              </div>
          }
          <br />
          {
            offers.length > 0 &&
              <div>
                <h4>Offers</h4>
                <br />
                <Row>
                  {this.renderOffers(product.offers)}
                </Row>
              </div>
          }
        </CardBody>
      </Card>
    )
  }
}
