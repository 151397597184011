import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Col, Card, CardBody, Table, Button} from 'reactstrap'
import {getApis, setDetailAdminApi} from '../../../../redux/actions/apis';
import ReqResModal from '../../../Apis/components/ReqResModal';

class ApisTable extends Component {

  state = {
    currentPage: 0,
    modalVisible: false,
    modalMessage: '',
    modalTitle: '',
    supportModalVisible: false,
    supportPayload: null
  };

  componentDidMount() {
    this.props.getApis();
  }

  openEditorForApi = (api) => {
    this.props.setDetailAdminApi(api);
    this.props.history.push('/admin/apis/' + api.id);
  };

  renderRows = (apiIndexs) => {
    return apiIndexs.map((apiIndex) => {
      return (
        <tr key={apiIndex.id}>
          <td>
            <div style={{height: '64px', width: '64px', overflow: 'hidden'}}>
              <img style={{minWidth: '100%'}} src={apiIndex.imageUrl} alt={apiIndex.name}/>
            </div>
          </td>
          <td>{apiIndex.name}</td>
          <td>
            <span style={{color: 'black'}}>{apiIndex.description}</span><br />
            <span style={{paddingLeft: '3px', fontStyle: 'italic'}}>{apiIndex.subtitle}</span>
          </td>
          <td><span className={'clickable blueText'} onClick={() => this.openEditorForApi(apiIndex)}>Edit</span></td>
        </tr>
      )
    });
  };

  render(){
    return (
      <Col md={12} lg={12} >
        <Card>
          <ReqResModal visible={this.state.modalVisible} toggle={this.toggleModal} message={this.state.modalMessage} title={this.state.modalTitle}/>
          <CardBody>
            <div className="card__title flexSpaceBetween">
              <h5 className="subhead"> Registered APIs </h5>
              <span onClick={()=> this.openEditorForApi({})}><Button color={'primary'} size={'sm'}>Create new API</Button></span>
            </div>
            <Table className="table--bordered" responsive striped>
              <thead>
              <tr>
                <th>Icon</th>
                <th>Name</th>
                <th>Description</th>
                <th>Options</th>
              </tr>
              </thead>
              <tbody>
                {this.renderRows(this.props.apis)}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col>
    )
  }
}

const mapStateToProps = (state) => ({
  apis: state.apis.apis
});

const mapDispatchToProps = (dispatch) => ({
  getApis: () => dispatch(getApis()),
  setDetailAdminApi: (api) => dispatch(setDetailAdminApi(api))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApisTable))

