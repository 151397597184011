import React, {Component} from 'react';
import {Col, Card, CardBody, CardHeader, CardTitle, Collapse, Table} from 'reactstrap';
import ProductCard from './ProductCard';

export default class ProductsCards extends Component {

  renderImages = (imagesArr) => {
    return imagesArr.map((imgSrc, index) => {
      return (
        <a href={imgSrc} className="irc_mi" target={'_blank'} key={`${imgSrc}-${index}`}>
          <img src={imgSrc} className={'debugTableImage'}/>
        </a>
      )
    })
  };

  renderCards(products){
    let cardsServed = 0;
    return products.map((p, index) => {
      if(p.name){
        cardsServed++;
        return <ProductCard product={p} key={`${index}-CARD-YO`} title={`Product ${cardsServed}`}/>
      }
    })
  }

  render(){
    console.log('PRODUCTS PROPS', this.props.products);
    return (
      <div>
        {this.renderCards(this.props.products)}
      </div>
    )
  }
}