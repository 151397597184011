import React, { useEffect, useRef } from 'react';
import { Card, CardBody, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import DebugResults from '../../containers/Debug/components/DebugResults';
import DebugApiResults from '../../containers/Debug/components/DebugApiResults';
import ReactPreview from '../../containers/Debug/components/ReactPreview';

import './DebugToolWidgetStyle.css';

import { connect } from 'react-redux';

const DebugToolWidgetResults = (props) => {

  const { debuggerForm } = props;

  const [ activeTab, setActiveTab ] = React.useState('results');
  const [ showResults, setShowResults ] = React.useState(false);

  const debugWidgetResults = useRef(null);

  const scrollToResults = () => {
    if(debugWidgetResults.current){
      debugWidgetResults.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  useEffect(() => {
    if(debuggerForm.defaultOptions.scrollToResults) {
      scrollToResults()
    }
  }, [debuggerForm.defaultOptions.scrollToResults]);


  useEffect(() => {
    if(debuggerForm.site.results){
      setShowResults(true);
    }

  }, [debuggerForm.site.results]);

  const toggleActiveTab = (tab) => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  return (
    <>
      <div id={'go-to-top'} ref={debugWidgetResults} />
      <Col md={12} xl={12} className={`debug--tool_widget_results_container ${showResults ? 'show' : ''}`} >
        <Nav className={'tab--container'} tabs>
          <NavItem className={'clickable'}>
            <NavLink
              className={activeTab === 'results' ? 'tabActive nav-link' : 'nav-link'}
              onClick={() => toggleActiveTab('results') }
            >
              OpenGraph Results
            </NavLink>
          </NavItem>
          <NavItem className={'clickable'}>
            <NavLink
              className={activeTab === 'details' ? 'tabActive nav-link' : 'nav-link'}
              onClick={() => toggleActiveTab('details') }
            >
              Api Details
            </NavLink>
          </NavItem>
          <NavItem className={'clickable'}>
            <NavLink
              className={activeTab === 'react' ? 'tabActive nav-link' : 'nav-link'}
              onClick={() => toggleActiveTab('react') }
            >
              React Component Preview
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="results">
            <Card>
              <CardBody>
                {debuggerForm.site.results ? <DebugResults results={debuggerForm.site.results}/> : '' }
              </CardBody>
            </Card>
          </TabPane>
          <TabPane tabId="details">
            <Card>
              <CardBody>
                {debuggerForm.site.results ? <DebugApiResults results={debuggerForm.site.results} lastCalledUrl={debuggerForm.site.lastCalledUrl}/> : '' }
              </CardBody>
            </Card>
          </TabPane>
          <TabPane tabId="react">
            <Card>
              <CardBody>
                {debuggerForm.site.results ? <ReactPreview results={debuggerForm.site.results}/> : '' }
              </CardBody>
            </Card>
          </TabPane>
        </TabContent>
      </Col>
    </>
  )
}

const mapStateToProps = (state) => ({
  debuggerForm: state.debuggerForm
});

export default connect(mapStateToProps)(DebugToolWidgetResults)

