import { apiClient } from '../../constants/clients';
import {
  CREATE_API_KEY,
  CREATE_API_KEY_FAIL,
  CREATE_API_KEY_SUCCESS,
  DELETE_API_KEY, DELETE_API_KEY_FAIL, DELETE_API_KEY_SUCCESS,
  EDIT_API_KEY,
  EDIT_API_KEY_FAIL,
  EDIT_API_KEY_SUCCESS,
  GET_API_KEYS_FOR_PLAN,
  GET_API_KEYS_FOR_PLAN_FAIL,
  GET_API_KEYS_FOR_PLAN_SUCCESS, RESET_API_KEY_STATE
} from '../actionTypes';
import {createSegmentEvent} from '../segment';


export function getApiKeysForPlan(payload){
  payload = payload || {};
  const itemsPerPage = 5;
  let client = apiClient.getApiKeysForPlan(payload.planId);

  //ServerSidePaging
  if (typeof(payload.page) === 'number' && itemsPerPage){
    const offset = payload.page * itemsPerPage;
    client = client.offset(offset).limit(itemsPerPage)
  }

  client = client.setSortFields(['createdAt']);
  client = client.setOrderByDesc();

  // //Search
  // const searchTerm = payload.searchTerm || '';
  // if(searchTerm.length > 0){
  //   client = client.setFilter('name=\'%' + payload.searchTerm + '%\'')
  // }

  return dispatch => {
    dispatch({type: GET_API_KEYS_FOR_PLAN});
    client.exec((err, result, fullResp)=> {
      if(err){
        dispatch({type:GET_API_KEYS_FOR_PLAN_FAIL, payload: err})
      } else {
        let payload = {
          apiKeys: result,
          count: fullResp.body.total,
        };
        dispatch({type:GET_API_KEYS_FOR_PLAN_SUCCESS, payload})
      }
    })
  }
}

export function createApiKeyForPlan(payload){
  return (dispatch) => {
    dispatch({type: CREATE_API_KEY});
    apiClient.createApiKeyForPlan(payload)
      .exec((err, result) => {
        if(err){
          dispatch({type: CREATE_API_KEY_FAIL, payload: err})
        } else {
          let meta = {
            analytics: createSegmentEvent('track',
                {
                  event: 'Api Key Created',
                  properties : {},
                  options : { integrations : { Slack: false,} }
                })
          }
          dispatch({type: CREATE_API_KEY_SUCCESS, payload: result, meta});
          debugger
          dispatch(getApiKeysForPlan({planId: payload.planId, page: payload.page}))
        }
      })
  }
}

export function editApiKey(payload){
  return (dispatch) => {
    dispatch({type: EDIT_API_KEY});
    apiClient.updateApiKey(payload)
      .exec((err, result) => {
        if(err){
          dispatch({type: EDIT_API_KEY_FAIL, payload: err})
        } else {
          dispatch({type: EDIT_API_KEY_SUCCESS, payload: result})
        }
      })
  }
}

export function deleteApiKey(apiKEyId){
  return (dispatch, getState) => {
    dispatch({type: DELETE_API_KEY});
    apiClient.deleteApiKey(apiKEyId)
      .exec((err, result) => {
        if(err){
          dispatch({type: DELETE_API_KEY_FAIL, payload: err})
        } else {
          dispatch({type: DELETE_API_KEY_SUCCESS, payload: result});
          dispatch(getApiKeysForPlan({planId: getState().plans.detailPlan.id, page: 0}))
        }
      })
  }
}

export function resetApiKeyState(){
  return {type: RESET_API_KEY_STATE}
}
