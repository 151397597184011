import React, {Component} from 'react';
import {Col, Row, Container, Alert } from 'reactstrap';
import {connect} from 'react-redux';
import {getApiByFriendlyName, clearDetailApi} from '../../../redux/actions/apis';
import {getPlanByApiAndOrganization, clearDetailPlan} from '../../../redux/actions/plans';
import ApiTiers from '../../Apis/components/ApiTiers';
import ApiKeysTable from '../../Apis/components/ApiKeysTable';
import {clearPlanAlert} from '../../../redux/actions/plans';
import BillingInformation from '../../Apis/components/BillingInformation';
import CurrentBillingCycle from '../../Apis/components/CurrentBilling/CurrentBillingCycle';
import ApiLogsTable from '../../Apis/components/ApiLogsTable';
import PlanPeriodsTable from '../../Apis/components/PlanPeriodsTable';
import InvoicesTable from '../../Apis/components/InvoicesTable';

class ApiDetails extends Component {
  componentDidMount(){
    this.props.getApiByFriendlyName('Opengraphio');
  };

  getPlan(){
    if(this.props.spyOrg.id && this.props.api.id && !this.props.plan.id && !this.props.planError ){
      this.props.getPlanByApiAndOrganization({organizationId: this.props.spyOrg.id});
    }
  }

  redirectIfNotLoggedIn = () => {
    if(!this.props.user && !this.props.userLoading){
      this.props.history.push('/login')
    }
  };

  render(){
    const api = this.props.api || {};
    const planAlert = this.props.planAlert || {};
    const spyOrg = this.props.spyOrg || {}
    this.redirectIfNotLoggedIn();
    this.getPlan();
    return (
      <Container className="dashboard">
        <Alert color={planAlert.color} className="alert--bordered" isOpen={true}>
          <div className={'padding5'}>
            <span>Showing Dashboard for {this.props.spyOrg.name}</span>
          </div>
        </Alert>
        <CurrentBillingCycle/>
        <Row>
          <Col md={12}>
            <Alert color={planAlert.color} className="alert--bordered" isOpen={!!planAlert.message} toggle={this.props.clearPlanAlert}>
              <div className={'padding5'}>
                <span>{planAlert.message}</span>
              </div>
            </Alert>
          </Col>
        </Row>
        <Row>
          <ApiTiers tiers={api.tiers} currentTierId={this.props.plan.tierId} plan={this.props.plan}/>
        </Row>
        <Row>
          <ApiKeysTable plan={this.props.plan}/>
        </Row>
        {this.props.plan.id &&
        <div>
          <Row>
            <BillingInformation plan={this.props.plan} currentTierId={this.props.plan.tierId} source={this.props.plan.source} planAlert={this.props.planAlert}/>
          </Row>
          <Row>
            <ApiLogsTable apiLogs={this.props.plan.apiLogs} plan={this.props.plan}/>
          </Row>
          <Row>
            <InvoicesTable />
          </Row>
        </div>
        }

      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  api: state.apis.detailApi,
  plan: state.plans.detailPlan,
  userLoading: state.users.loading,
  planAlert: state.plans.alert,
  planError: state.plans.error,
  apiError: state.apis.error,
  spyOrg: state.admin.spyOrganization,
});

const mapDispatchToProps = (dispatch) => ({
  getApiByFriendlyName: (payload) => dispatch(getApiByFriendlyName(payload)),
  getPlanByApiAndOrganization: ((payload) => dispatch(getPlanByApiAndOrganization(payload))),
  clearPlanAlert: () => dispatch(clearPlanAlert()),
  clearDetailPlan: () => dispatch(clearDetailPlan()),
  clearDetailApi: () => dispatch(clearDetailApi()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiDetails)