import React, {Component} from 'react';
import OpengraphReactComponent from 'opengraph-react';

export default class ReactPreview extends Component {
  render(){
    return (
      <div>
        <h1>React Component Preview</h1>
        <p> Easy to use site previews for react. Get them <a href={'https://github.com/securecoders/opengraph-react'}>here</a></p>
        <hr />
        <div className="reactComponentsContainer">
          <div>
            <h2>Large</h2>
            <div className={'width400 reactComponentWrapper'}>
              <OpengraphReactComponent dontMakeCall={true} result={this.props.results} size={'large'}/>
            </div>
          </div>
          <div>
            <h2>Small</h2>
            <div className={'width600 reactComponentWrapper'}>
              <OpengraphReactComponent dontMakeCall={true} result={this.props.results} size={'small'}/>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
