import React from 'react'

import GetRoleOptions from '../Constants/GetRoleOptions';

import { Button, Form, FormGroup, Input, Modal, Label, FormFeedback, Alert } from 'reactstrap';
import { RiInformationLine } from 'react-icons/ri';
import { HiMiniXMark } from 'react-icons/hi2';

const OrganizationInviteUserModal = (props) => {
  const {
    isOpen, toggle, setEmail, setRole,
    sendInvite, userRole, emailError, roleError,
    enableInviteButton, loading, inviteError, clearInviteError } = props

  const [ showInfo, setShowInfo ] = React.useState(false)

  const handleUserInviteSubmit = (e) => {
    e.preventDefault()
    setEmail(e.target.email.value)
    setRole(e.target.select.value)
    sendInvite()
  }

  const handleShowInfo = () => {
    setShowInfo(!showInfo)
  }

  return (
    <>
      <Modal isOpen={isOpen}>
        <div style={{ minHeight: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} >
          {inviteError.message && <Alert color={'danger'} className="alert--bordered" toggle={clearInviteError}>
            <div className={'padding5'}>
              <p>{inviteError.message}</p>
            </div>
          </Alert>}
        <span style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between', marginBottom: '20px'}}>
          <h3>Invite User</h3>
          <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly'}}>
            <p style={{ textAlign: 'left' }}>Invite new users by email to join your organization.</p>
          <RiInformationLine style={{ cursor: 'pointer'}} size={32} onClick={() => handleShowInfo()} />
          </span>
          <div className={`floating-card ${showInfo ? 'show-info' : ''}`}>
            <div className={'floating-card__header'}>
              <h3>User Roles</h3>
              <HiMiniXMark style={{ cursor: 'pointer'}} size={24} onClick={() => handleShowInfo()} />
            </div>
            <p>There are two types of user roles:</p>
            <ul>
              <li><strong>Owner</strong> - Can manage all aspects of the organization, including billing and user management.</li>
              <li><strong>Developer</strong> - Can manage the organization's API keys and billing.</li>
            </ul>
          </div>
        </span>
        { !loading ? (
          <Form onSubmit={handleUserInviteSubmit}>
            <FormGroup row>
              <Label
                for="exampleEmail"
                hidden
              >
                Email
              </Label>
              <Input
                id="exampleEmail"
                name="email"
                placeholder="Email"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                invalid={emailError}
              />
              <FormFeedback>
                Please enter a valid email address
              </FormFeedback>
            </FormGroup>
            <FormGroup row>
              <Input
                id="exampleSelect"
                name="select"
                type="select"
                onChange={(e) => setRole(e.target.value)}
                invalid={roleError}
              >
                <option value="">Select New User Role</option>
                <GetRoleOptions userRole={userRole}/>
              </Input>
              <FormFeedback>
                Please choose a role
              </FormFeedback>
            </FormGroup>
          </Form>
        ) : (
          <div style={{width: '100%', margin: 'auto' }}>
            <div className="spinner-border text-success" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        <div>
          <Button color={'danger'} onClick={toggle}>Cancel</Button>
          <Button disabled={enableInviteButton} color={'primary'} onClick={sendInvite}>Send Invite</Button>
        </div>
        </div>
      </Modal>
    </>
  )
}

export default OrganizationInviteUserModal