import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Row, Modal} from 'reactstrap';
import SupportEmailForm from './SupportEmailForm';

class SupportModal extends Component {

  render(){
    return (
      <Modal isOpen={this.props.visible} toggle={this.props.toggle} className={'supportModal modal-dialog--primary paymentModal '}>
        <div className={'modal__header centerText'}>
            <button className="cross-btn" onClick={this.props.toggle}>X</button>
          <h4 className={'bold-text modal__title'}>
            Contact Us
          </h4>
        </div>
            <Row>
              <SupportEmailForm toggle={this.props.toggle} payload={this.props.payload} apiId={this.props.apiId}/>
            </Row>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.users.user,
  userLoading: state.users.loading,
  alert: state.users.alert,
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps,mapDispatchToProps)(SupportModal);

