import {
  GET_API_LOGS_FOR_PLAN, GET_API_LOGS_FOR_PLAN_FAIL,
  GET_API_LOGS_FOR_PLAN_SUCCESS
} from '../actionTypes';

const initialState = {
  apiLogs: [],
  alert: null,
  count: 0,
  error: null,
  loading: true
};

const apiLogs = (state = initialState, action) => {
  switch (action.type) {
    case GET_API_LOGS_FOR_PLAN:
      return {...state, loading: true};
    case GET_API_LOGS_FOR_PLAN_SUCCESS:
      return {...state, apiLogs: action.payload.apiLogs, count: action.payload.count, loading: false, error: null};
    case GET_API_LOGS_FOR_PLAN_FAIL:
      return {...state, loading: false, error: action.payload};
    default:
      return state;
  }
};


export default apiLogs;
