import React from 'react';
import { render } from 'react-dom';
import App from './containers/App/App';
import * as Sentry from '@sentry/react';

if(process.env.NODE_ENV === 'production') {
  Sentry.init({dsn: 'https://2504a6eaa042cc2c6a736738492d78cc@o554263.ingest.sentry.io/4506139669168128'});
}

render(
  <App />,
  document.getElementById('root'),
);
