import React from 'react';

const OrganizationTableHeader = ({ headers }) => {
  return (
    <thead>
      <tr>
        {headers.map((heading, index) => (
          <th key={index}>
            {heading}
          </th>
        ))}
      </tr>
    </thead>
  )
}

export default OrganizationTableHeader