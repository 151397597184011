import {ADMIN_GET_ALL_ORGS_SUCCESS, ADMIN_SET_SPY_ORG} from '../actionTypes';


const initialState = {organizations: [], totalOrganizations: null, spyOrganization: {}, alert: null, loading: true};

const users = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_GET_ALL_ORGS_SUCCESS:
      return {...state, organizations: action.payload.organizations, totalOrganizations: action.payload.total};
    case ADMIN_SET_SPY_ORG:
      return {...state, spyOrganization: action.payload};
    default:
      return state;
  }
};

export default users;
